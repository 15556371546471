import { Flex } from 'antd';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useGetMyAccounts } from 'api/requests';
import Button from 'components/Button';
import { Info, Lightning } from 'components/Icons';
import { Text, Link } from 'components/Typography';
import { TrackingEvents, TrackingVariables } from 'interfaces/enums';
import { IUpgradeSubscription } from 'lib/contexts/UpgradePlan';
import { getPlanName } from 'lib/helpers';
import { SHOW_UPGRADE_SCREEN, getFeatureFlags } from 'lib/helpers/featureFlags';
import { getAverageConsumption, shouldShowForceUpgrade } from 'lib/helpers/overusage';
import { trackAmplitudeEvent } from 'lib/helpers/trackAmplitudeEvent';
import { useUsageStatus, useUpgradePlan, useSelectedCompany } from 'lib/hooks';

import { Container } from './styled';

const UsageStatusBar = () => {
  const { shouldRedirect, consumption } = useUsageStatus();
  const navigate = useNavigate();
  const { billingCustomerId } = useSelectedCompany();
  const { data: accounts } = useGetMyAccounts();
  const { subscription: subscriptionToUpgrade, setPlanToUpgrade } = useUpgradePlan();
  const ffs = getFeatureFlags();

  const onNavigate = () => {
    trackAmplitudeEvent(TrackingEvents.USAGE_OVERVIEW_VIEWED, {
      [TrackingVariables.BILLING_ACCOUNT_ID]: billingCustomerId,
      [TrackingVariables.PLAN_NAME]: getPlanName(subscriptionToUpgrade?.pricePlan, subscriptionToUpgrade?.type),
    });
    navigate(`/billing/account/${billingCustomerId}#subscription-row`);
  };

  const showUpgradePopup = (forcedUpgrade?: boolean) => {
    const subId =
      Object.keys(consumption).find(
        (sub) =>
          consumption[sub].overuse.shouldNotifyAboutOveruse && (forcedUpgrade ? shouldShowForceUpgrade(sub) : true),
      ) || null;
    if (subId) {
      if (forcedUpgrade && (!shouldShowForceUpgrade(subId) || !ffs.includes(SHOW_UPGRADE_SCREEN))) {
        return;
      }
      const subscription = accounts?.body
        ?.find((acc) => acc.id === billingCustomerId)
        ?.subscriptions.find((s) => s.id === subId);
      const cons = consumption[subId];
      // Only in the last exceeded period
      const averageUsage = getAverageConsumption(cons);
      setPlanToUpgrade(subscription as unknown as IUpgradeSubscription, {
        recommendedTier: cons.recommendedTier?.maxValue || cons.recommendedTier?.minValue || 0,
        amountOfMonths: cons.overuse.details.metrics.currentSeries?.length || 0,
        averageUsage,
        forcedUpgrade,
      });
    }
  };

  const onUpgrade = () => {
    if (shouldRedirect) {
      onNavigate();
    } else {
      showUpgradePopup();
    }
  };

  useEffect(() => {
    showUpgradePopup(true);
  }, [consumption, subscriptionToUpgrade]);

  return (
    <Container type="warning">
      <Flex align="center">
        <Text strong style={{ color: 'white' }}>
          <Flex align="center">
            <Info />
            &nbsp; Upgrade required!
          </Flex>
        </Text>
        &nbsp; One of your subscription has exceeded the session limit for three consecutive months.
        <Link style={{ color: 'white' }} onClick={onNavigate}>
          See usage overview.
        </Link>
      </Flex>
      <Button ghost onClick={onUpgrade}>
        <Lightning />
        Upgrade
      </Button>
    </Container>
  );
};

export default UsageStatusBar;
