import Cookies from 'js-cookie';

import { TRACK_COOKIES } from '../consts';

export const saveUcOrigin = (value: string): void => {
  Cookies.set(TRACK_COOKIES.UC_ORIGIN, value, {
    expires: new Date(Date.now() + 3600 * 1000),
  });
};

export const removeUcOrigin = () => {
  Cookies.remove(TRACK_COOKIES.UC_ORIGIN);
};
