import { Row, Flex, Col } from 'antd';

import ColorPicker from 'components/ColorPicker';
import { Text } from 'components/Typography';
import useOnboarding from 'lib/hooks/useOnboarding';

const PrivacyColors = () => {
  const { customizationDetails, setCustomizationDetails } = useOnboarding();

  const onSetColorValue = (name: string, value: string) => {
    setCustomizationDetails((prev) => ({
      ...prev,
      privacyTrigger: { ...prev.privacyTrigger, [name]: value },
    }));
  };

  return (
    <Row justify="space-between" gutter={[20, 20]}>
      <Col span={12}>
        <Flex vertical gap={8}>
          <Text weight={500}>Background</Text>
          <ColorPicker
            colorValue={customizationDetails.privacyTrigger.privacyButtonBackgroundColor as string}
            setColorValue={(value) => {
              onSetColorValue('privacyButtonBackgroundColor', value);
            }}
          />
        </Flex>
      </Col>
      <Col span={12}>
        <Flex vertical gap={8}>
          <Text weight={500}>Icon</Text>
          <ColorPicker
            colorValue={customizationDetails.privacyTrigger.privacyButtonIconColor as string}
            setColorValue={(value) => {
              onSetColorValue('privacyButtonIconColor', value);
            }}
          />
        </Flex>
      </Col>
    </Row>
  );
};

export default PrivacyColors;
