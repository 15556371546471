import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Flex } from 'antd';

import { LegalFramework, SecondLayerDTOVariant } from 'api/requests/generated/generated.schemas';
import { Text } from 'components/Typography';
import useOnboarding from 'lib/hooks/useOnboarding';

import Content from './Content';
import { Container, SecondLayerFooter } from './styled';
import TcfContent from './TcfContent';
import { getInfoBySelectedFramework } from './utils';
import { StyledButton, StyledText, StyledSwitch, CloseIcon, CloseButton } from '../styled';

const SecondLayer = () => {
  const {
    customizationDetails: { layout, styling, setIsSecondLayerOpen, setIsFirstLayerOpen },
    selectedFramework,
  } = useOnboarding();

  const { description, buttons } = getInfoBySelectedFramework(selectedFramework);

  const isGDPR = selectedFramework === LegalFramework.GDPR;
  const isTCF = selectedFramework === LegalFramework.TCF2;

  return (
    <Container
      $background={styling.layerBackground}
      $position={
        layout.secondLayerVariant?.variant === SecondLayerDTOVariant.CENTER
          ? layout.secondLayerVariant?.variant
          : layout.secondLayerVariant?.side
      }
    >
      <div>
        <div style={{ padding: 15 }}>
          <Flex gap={10} justify="space-between" align="center">
            <Text size="sm" weight={500} color={styling.text}>
              Privacy Settings
            </Text>
            <CloseButton
              ghost
              onClick={() => {
                setIsSecondLayerOpen(false);
                setIsFirstLayerOpen(true);
              }}
            >
              <CloseIcon color={styling.text} />
            </CloseButton>
          </Flex>
          <Flex>
            <StyledText color={styling.text}>{description}</StyledText>
          </Flex>
        </div>
        {isTCF ? <TcfContent /> : <Content />}
      </div>
      <SecondLayerFooter $accentColor={styling.tabsBorderColor}>
        {isGDPR || isTCF ? (
          <Flex vertical gap={5}>
            <Flex gap={7}>
              {buttons.map((el) => (
                <StyledButton key={el} $background={styling.buttonBackgroundColor} $color={styling.buttonTextColor}>
                  {el}
                </StyledButton>
              ))}
            </Flex>
            <Flex justify="center">
              <StyledText size="sm" color={styling.text}>
                Powered by Usercentrics Consent Management
              </StyledText>
            </Flex>
          </Flex>
        ) : (
          <Flex vertical gap={7}>
            <Flex align="center" gap={10}>
              <Flex align="center">
                <StyledSwitch
                  size="small"
                  unCheckedChildren={<CloseOutlined />}
                  checkedChildren={<CheckOutlined />}
                  checked={false}
                  $background={styling.toggleInactiveBackground}
                  $iconColor={styling.toggleInactiveIcon}
                />
              </Flex>
              <Text size="sm" weight={600} color={styling.text} ellipsis>
                Do not process my personal information
              </Text>
              {buttons.map((el) => (
                <StyledButton key={el} $background={styling.buttonBackgroundColor} $color={styling.buttonTextColor}>
                  {el}
                </StyledButton>
              ))}
            </Flex>
            <Flex justify="center">
              <StyledText color={styling.text}>Powered by Usercentrics Consent Management</StyledText>
            </Flex>
          </Flex>
        )}
      </SecondLayerFooter>
    </Container>
  );
};

export default SecondLayer;
