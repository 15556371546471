import { Alert, Form, Space, Typography } from 'antd';
import { FormInstance } from 'antd/lib';
import { useCallback, useState } from 'react';

import { getCompanies } from 'api/requests/companies';
import { Permission, PrivilegesOutDtoSuperAdminPermission } from 'api/requests/generated/generated.schemas';
import Search from 'components/Search';
import { Text } from 'components/Typography';
import ICompany from 'interfaces/ICompany';
import { usePrivileges, useSelectedCompany } from 'lib/hooks';

import { CompanyItem, RadioGroup, SpaceWrap } from './styled';
const { Paragraph } = Typography;

interface ISelectCompanyProps {
  form: FormInstance;
}

const initialValues = {
  company: '',
};

const SelectCompany = ({ form }: ISelectCompanyProps) => {
  const { isCSA } = usePrivileges();
  const currentCompany = useSelectedCompany();
  const [searchTerm, setSearchTerm] = useState('');
  const { companies: fetchedCompanies = [], isMutating, trigger } = getCompanies(!isCSA && !searchTerm);

  const isSourceCompanyDefined = Boolean(currentCompany.id);

  const canBeTargetCompany = (targetCompany: ICompany) => {
    const currentUserIsTargetCompanyAdmin =
      targetCompany.permission === Permission.ADMIN ||
      [Permission.SUPERADMIN_READWRITE, Permission.SUPERADMIN_READ].includes(
        targetCompany.permission as PrivilegesOutDtoSuperAdminPermission,
      );
    const isCompaniesInSameAccount =
      !currentCompany.billingCustomerId || targetCompany.billingCustomerId === currentCompany.billingCustomerId;
    const isSameCompany = targetCompany.id === currentCompany.id;

    return currentUserIsTargetCompanyAdmin && (!isSourceCompanyDefined || (isCompaniesInSameAccount && !isSameCompany));
  };

  const companies = fetchedCompanies.filter(canBeTargetCompany);

  const onSearch = useCallback(
    async (query: string) => {
      setSearchTerm(query);
      form.setFieldValue('company', null);

      if (query) {
        await trigger(query);
      }
    },
    [setSearchTerm, trigger],
  );

  return (
    <>
      {isCSA && (
        <Alert
          message="You are viewing this as a support role user"
          style={{ margin: '-25px -23px 40px -25px' }}
          type="info"
          showIcon
        />
      )}
      <Form form={form} name="select-company" initialValues={initialValues} style={{ width: 750 }}>
        <Paragraph>Select to which company this configuration should be moved.</Paragraph>

        <Search
          placeholder="Search for Company"
          onSearch={onSearch}
          isLoading={isMutating}
          data-testid="search:select-company"
        />

        {companies?.length > 0 && (
          <Text size="sm">
            {companies.length} compan{companies.length === 1 ? 'y' : 'ies'} found
          </Text>
        )}
        <Form.Item name="company">
          <RadioGroup size="large">
            <SpaceWrap direction="vertical">
              {companies?.map((c) => (
                <CompanyItem value={JSON.stringify({ id: c.id, name: c.name })} key={c.id}>
                  <Space direction="vertical" size={1}>
                    <Text data-testid={`div[${c.id}]:select-company`} strong>
                      {c.name}
                    </Text>
                    {c.permission !== Permission.NONE && (
                      <Text>{[c.address, c.postcode, c.city, c.country].filter(Boolean).join(', ')}</Text>
                    )}
                  </Space>
                </CompanyItem>
              ))}
            </SpaceWrap>
          </RadioGroup>
        </Form.Item>
      </Form>
    </>
  );
};

export default SelectCompany;
