import { Col, Flex, Row } from 'antd';

import { StyledSelect as Select } from 'components/Select/Select';
import { Text } from 'components/Typography';
import useOnboarding from 'lib/hooks/useOnboarding';

const options = [
  { value: 44, label: 'Small - 44px' },
  { value: 56, label: 'Medium - 56px' },
  { value: 64, label: 'Large - 64px' },
];

const Size = () => {
  const { setCustomizationDetails, customizationDetails } = useOnboarding();

  const onChange = (name: string, value: number) => {
    setCustomizationDetails((prev) => ({
      ...prev,
      privacyTrigger: { ...prev.privacyTrigger, [name]: value },
    }));
  };

  return (
    <Row justify="space-between" gutter={[20, 20]}>
      <Col span={12}>
        <Flex vertical gap={8}>
          <Text weight={500}>Desktop</Text>
          <Select
            value={customizationDetails.privacyTrigger.privacyButtonSizeDesktop}
            size="large"
            options={options}
            onChange={(value) => onChange('privacyButtonSizeDesktop', value as number)}
          />
        </Flex>
      </Col>
      <Col span={12}>
        <Flex vertical gap={8}>
          <Text weight={500}>Mobile</Text>
          <Select
            value={customizationDetails.privacyTrigger.privacyButtonSizeMobile}
            size="large"
            options={options}
            onChange={(value) => onChange('privacyButtonSizeMobile', value as number)}
          />
        </Flex>
      </Col>
    </Row>
  );
};

export default Size;
