import { Col, Flex, Row } from 'antd';

import Button from 'components/Button';
import DotStepper from 'components/DotStepper';
import Tag from 'components/Tag';
import { Title, Text } from 'components/Typography';
import withMobileView from 'hoc/withMobileView';
import useOnboarding from 'lib/hooks/useOnboarding';

import Preview from './Preview';
import { HeaderContainer, StyledModal, StyledTabs, Footer, FooterContainer, PreviewContainer } from './styled';
import LayoutTab from './Tabs/Layout';
import PrivacyTriggerTab from './Tabs/PrivacyTrigger';
import StylingTab from './Tabs/Styling';
import { Breadcrumbs } from '../../types';

interface ICustomizationModalProps {
  breadcrumbs: string[];
  handleBack: () => void;
  handleNext: () => void;
}

enum Tabs {
  Layout = 'layout',
  Styling = 'styling',
  PrivacyTrigger = 'privacyTrigger',
}

const CustomizationModal = (props: ICustomizationModalProps) => {
  return (
    <StyledModal
      open
      footer={null}
      width="95%"
      closable={false}
      centered
      styles={{ mask: { backdropFilter: 'blur(4px)' } }}
    >
      <ModalContentWirhMobile {...props} />
    </StyledModal>
  );
};

const ModalContent = ({ handleBack, handleNext, breadcrumbs }: ICustomizationModalProps) => {
  const {
    selectedFramework,
    resetCustomizationDetails,
    customizationDetails: { setIsFirstLayerOpen, setIsSecondLayerOpen, isFirstLayerOpen, isSecondLayerOpen },
  } = useOnboarding();

  const onTabsChange = (tab: string | Tabs) => {
    if (tab === Tabs.PrivacyTrigger) {
      setIsFirstLayerOpen(false);
      setIsSecondLayerOpen(false);
      return;
    }

    if (!isFirstLayerOpen && !isSecondLayerOpen) {
      setIsFirstLayerOpen(true);
    }
  };

  const onBackClick = () => {
    handleBack();
    resetCustomizationDetails();
  };

  return (
    <>
      <Flex vertical gap={24}>
        <HeaderContainer justify="space-between" gap={8} align="start">
          <Flex vertical gap={16}>
            <DotStepper
              name="modal-breadcrumbs"
              steps={breadcrumbs}
              activeStep={breadcrumbs.indexOf(Breadcrumbs.CONSENT_CUSTOMIZATION)}
            />
            <Title $noMargin weight={700} fontSize={28}>
              Customize Consent Banner
            </Title>
          </Flex>
          <Flex gap={8} align="center">
            <Text>Legal framework:</Text>
            <Tag color="blue-inverse" $bolder size="medium" bordered>
              {selectedFramework}
            </Tag>
          </Flex>
        </HeaderContainer>
        <Row>
          <Col span={8}>
            <StyledTabs
              defaultActiveKey="1"
              type="card"
              tabBarStyle={{ paddingLeft: 32, margin: 0 }}
              onChange={onTabsChange}
              items={[
                {
                  label: 'Layout',
                  key: Tabs.Layout,
                  children: <LayoutTab />,
                },
                {
                  label: 'Styling',
                  key: Tabs.Styling,
                  children: <StylingTab />,
                },
                {
                  label: 'Privacy Trigger',
                  key: Tabs.PrivacyTrigger,
                  children: <PrivacyTriggerTab />,
                },
              ]}
            />
          </Col>
          <PreviewContainer span={16}>
            <Preview />
          </PreviewContainer>
        </Row>
      </Flex>
      <FooterContainer>
        <Footer align="middle" justify="space-between">
          <Col span={18} md={{ span: 17 }}>
            <Flex gap={16} align="center">
              <Button
                size="large"
                onClick={() => {
                  resetCustomizationDetails();
                  handleNext();
                }}
              >
                I’ll do it later
              </Button>
              <Text size="sm" type="secondary">
                If you don’t want to customize right now you can skip this step and continue with the implementation.
                Our default settings are designed to always ensure compliance for your selected framework. You can edit
                your consent banner at any time.
              </Text>
            </Flex>
          </Col>
          <Col offset={1} md={{ offset: 0 }}>
            <Flex gap={16} align="center">
              <Button size="large" onClick={onBackClick}>
                Back
              </Button>
              <Button size="large" type="primary" onClick={handleNext}>
                Save and Next
              </Button>
            </Flex>
          </Col>
        </Footer>
      </FooterContainer>
    </>
  );
};

const ModalContentWirhMobile = withMobileView(ModalContent);

export default CustomizationModal;
