import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Flex } from 'antd';

import { LegalFramework } from 'api/requests/generated/generated.schemas';
import { Text } from 'components/Typography';
import useOnboarding from 'lib/hooks/useOnboarding';

import { StyledCollapse, StyledTabs } from './styled';
import { StyledText, StyledSwitch } from '../styled';

const Content = () => {
  const {
    customizationDetails: { styling },
    selectedFramework,
  } = useOnboarding();

  const isGDPR = selectedFramework === LegalFramework.GDPR;

  return (
    <StyledTabs
      $textColor={styling.text}
      $accentColor={styling.tabsBorderColor}
      $backgroundColor={styling.layerBackground}
      $tabColor={styling.secondLayerTab}
      tabBarStyle={{ paddingLeft: 25, margin: 0, fontSize: 9 }}
      items={[
        {
          label: 'Categories',
          key: 'layout',
          children: (
            <Flex vertical gap={10}>
              <StyledCollapse
                $background={styling.layerBackground}
                $accentColor={styling.tabsBorderColor}
                collapsible="disabled"
                expandIconPosition="end"
                bordered={false}
                items={[
                  {
                    key: '1',
                    label: (
                      <Flex vertical>
                        <Text color={styling.text} size="sm" weight={700}>
                          Marketing
                        </Text>
                        <StyledText $opacity={0.7} color={styling.text}>
                          These technologies are used by advertisers to serve ads that are relevant to your interests.
                        </StyledText>
                      </Flex>
                    ),
                    extra: isGDPR ? (
                      <StyledSwitch
                        $background={styling.toggleInactiveBackground}
                        $iconColor={styling.toggleInactiveIcon}
                        checked={false}
                        size="small"
                        onClick={(_, e) => e.stopPropagation()}
                        unCheckedChildren={<CloseOutlined />}
                        checkedChildren={<CheckOutlined />}
                      />
                    ) : null,
                  },
                ]}
              />
              <StyledCollapse
                $background={styling.layerBackground}
                $accentColor={styling.tabsBorderColor}
                expandIconPosition="end"
                collapsible="disabled"
                bordered={false}
                items={[
                  {
                    key: '2',
                    label: (
                      <Flex vertical>
                        <Text color={styling.text} size="sm" weight={700}>
                          Functional
                        </Text>
                        <StyledText $opacity={0.7} color={styling.text}>
                          These technologies enable us to analyse the use of the website in order to measure and improve
                          performance.
                        </StyledText>
                      </Flex>
                    ),
                    extra: isGDPR ? (
                      <StyledSwitch
                        $background={styling.toggleActiveBackground}
                        $iconColor={styling.toggleActiveIcon}
                        checked
                        size="small"
                        onClick={(_, e) => e.stopPropagation()}
                        checkedChildren={<CheckOutlined />}
                      />
                    ) : null,
                  },
                ]}
              />
              <StyledCollapse
                $background={styling.layerBackground}
                $accentColor={styling.tabsBorderColor}
                expandIconPosition="end"
                collapsible="disabled"
                bordered={false}
                items={[
                  {
                    key: '3',
                    label: (
                      <Flex vertical>
                        <Text color={styling.text} size="sm" weight={700}>
                          Essential
                        </Text>
                        <StyledText $opacity={0.7} color={styling.text}>
                          These technologies are required to activate the core functionality of our service.
                        </StyledText>
                      </Flex>
                    ),
                    extra: isGDPR ? (
                      <StyledSwitch
                        $background={styling.toggleDisabledBackground}
                        $iconColor={styling.toggleDisabledIcon}
                        disabled
                        checked
                        size="small"
                        onClick={(_, e) => e.stopPropagation()}
                        unCheckedChildren={<CloseOutlined />}
                        checkedChildren={<CheckOutlined />}
                      />
                    ) : null,
                  },
                ]}
              />
            </Flex>
          ),
        },
        {
          label: isGDPR ? 'Services' : null,
          key: 'styling',
          children: (
            <>
              <StyledCollapse
                $background={styling.layerBackground}
                $accentColor={styling.tabsBorderColor}
                expandIconPosition="end"
                bordered={false}
                items={[
                  {
                    key: '1',
                    label: (
                      <Flex vertical>
                        <Text color={styling.text} size="sm" weight={700}>
                          Usercentrics Consent Management Platform
                        </Text>
                        <StyledText color={styling.text} $opacity={0.7}>
                          Essential
                        </StyledText>
                      </Flex>
                    ),
                    extra: (
                      <StyledSwitch
                        $background={styling.toggleActiveBackground}
                        $iconColor={styling.toggleActiveIcon}
                        checked
                        defaultChecked
                        size="small"
                        checkedChildren={<CheckOutlined />}
                        onClick={(_, e) => e.stopPropagation()}
                      />
                    ),
                    children: (
                      <Flex gap={3} vertical>
                        <StyledText color={styling.text} $opacity={0.7}>
                          Description of Service
                        </StyledText>
                        <StyledText color={styling.text} size="sm">
                          This is a consent management service.{' '}
                        </StyledText>
                      </Flex>
                    ),
                  },
                ]}
              />
            </>
          ),
        },
      ]}
    />
  );
};

export default Content;
