import { Collapse, Switch, Tabs } from 'antd';
import styled from 'styled-components';

import { SecondLayerDTOSide, SecondLayerDTOVariant } from 'api/requests/generated/generated.schemas';

export const Container = styled.div<{ $background?: string; $position?: SecondLayerDTOVariant | SecondLayerDTOSide }>`
  position: absolute;
  box-shadow: 0px 0px 8px 2px ${(props) => props.theme.colors.primaryText}0d;

  ${(props) => {
    switch (props.$position) {
      case SecondLayerDTOSide.LEFT:
        return 'left: 0; top: 25px; width: 40%; height: calc(100% - 25px);';

      case SecondLayerDTOSide.RIGHT:
        return 'right: 0; top: 25px; width: 40%; height: calc(100% - 25px);';

      case SecondLayerDTOVariant.CENTER:
        return 'width: 90%; top: calc(50% + 10px); left: 50%;  transform: translate(-50%, -50%) scale(0.865); border-radius: 4px;';

      default:
        return '';
    }
  }}

  background-color: ${(props) => props.$background || 'transparent'};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 730px;
`;

export const SecondLayerFooter = styled.div<{ $accentColor?: string }>`
  border-top: 1px solid ${(props) => props.$accentColor};
  padding: 15px;
`;

export const StyledCollapse = styled(Collapse)<{ $background?: string; $accentColor?: string }>`
  &&& .ant-collapse-header {
    border: 1px solid ${(props) => props.$accentColor};
    padding: 10px;
    align-items: start;
    background-color: ${(props) => props.$background};
  }

  &.ant-collapse {
    background-color: ${(props) => props.$background};
  }

  && .ant-collapse-content {
    border: 1px solid ${(props) => props.$accentColor};
    border-top: 0;
    padding: 10px;
    font-size: 10px;
  }
`;

export const StyledSwitch = styled(Switch)<{ $background: string; $iconColor: string }>`
  background-color: ${(props) => props.$background};
  color: ${(props) => props.$iconColor};

  &.ant-switch-checked {
    background-color: ${(props) => props.$background};
  }

  & .anticon {
    svg {
      fill: ${(props) => props.$iconColor};
    }
  }

  &&:hover {
    background-color: ${(props) => props.$background};
    color: ${(props) => props.$iconColor};
    opacity: 0.8;
  }
`;

export const StyledTabs = styled(Tabs)<{
  $accentColor?: string;
  $textColor?: string;
  $tabColor?: string;
  $backgroundColor?: string;
}>`
  &.ant-tabs {
    & .ant-tabs-nav::before {
      border-color: ${(props) => props.$accentColor};
    }

    & .ant-tabs-tab {
      font-size: 11px;
      color: ${(props) => props.$textColor};
    }

    & .ant-tabs-ink-bar {
      background-color: ${(props) => props.$tabColor};
    }

    & .ant-tabs-tab-active {
      & .ant-tabs-tab-btn {
        color: ${(props) => props.$tabColor};
      }
    }
  }

  & .ant-tabs-content {
    padding: 15px;
    background-color: ${(props) => props.$backgroundColor};
    overflow: scroll;
    max-height: 250px;
  }
`;
