import { Flex, Form, Input, Radio } from 'antd';

import { AppDtoPlatform } from 'api/requests/generated/generated.schemas';
import { Item } from 'components/Form';
import AppStore from 'components/Icons/AppStore';
import PlayStore from 'components/Icons/PlayStore';
import { Text } from 'components/Typography';
import { titleCase } from 'lib/helpers/titlecase';
import { colors } from 'lib/theme/colors';

import { StyledRadio } from '../../../Customization/BannerLayout/styled';

const IOS_BUNDLE_ID_REGEX = /^[a-zA-Z]{1}[a-zA-Z0-9\-_]+(\.[a-zA-Z0-9\-_]+)+$/;
const ANDROID_PACKAGE_NAME_REGEX = /^[a-zA-Z][a-zA-Z0-9_]*(\.[a-zA-Z][a-zA-Z0-9_]*)+$/;

export default function AppPlatform() {
  const form = Form.useFormInstance();
  const platform = Form.useWatch('platform', form); // Watch for changes in the platform field
  const label = platform === AppDtoPlatform.IOS ? 'Bundle ID' : 'Package Name';

  const platformOptions = [
    {
      title: 'App Store',
      key: AppDtoPlatform.IOS,
      icon: <AppStore />,
      platform: AppDtoPlatform.IOS,
    },
    {
      title: 'Google Play Store',
      key: AppDtoPlatform.ANDROID,
      icon: <PlayStore />,
      platform: titleCase(AppDtoPlatform.ANDROID),
    },
  ];
  return (
    <>
      <Form.Item name="platform">
        <Radio.Group style={{ display: 'flex' }}>
          {platformOptions.map((option) => (
            <StyledRadio key={option.key} value={option.key}>
              {option.icon}
              <Flex vertical>
                <div>{option.title}</div>
                <Text color={colors.secondary} size="sm">
                  {option.platform}
                </Text>
              </Flex>
            </StyledRadio>
          ))}
        </Radio.Group>
      </Form.Item>
      <Item
        label={label}
        name="bundleId"
        colon={false}
        weight="medium"
        dependencies={['platform']}
        rules={[
          { required: true, message: 'App ID is required' },
          ({ getFieldValue }) => ({
            validator(_, value) {
              const selection = getFieldValue('platform');
              const regex = selection === AppDtoPlatform.IOS ? IOS_BUNDLE_ID_REGEX : ANDROID_PACKAGE_NAME_REGEX;
              if (!value || regex.test(value)) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('Please enter a valid App ID for the selected platform'));
            },
          }),
        ]}
        requiredMark={false}
        validateTrigger="onBlur"
      >
        <Input placeholder="com.example.app" />
      </Item>
    </>
  );
}
