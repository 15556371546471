import { useAuth0 } from '@auth0/auth0-react';
import { Form, Flex, Col, Row, Typography, Divider } from 'antd';
import { useState, useEffect } from 'react';

import { createOrUpdateCompanyPermissions, useGetPermission } from 'api/requests';
import { Permission } from 'api/requests/generated/generated.schemas';
import Button from 'components/Button';
import { Input } from 'components/Form';
import Item from 'components/Form/Item';
import { Expand } from 'components/Icons';
import PermissionDetails from 'components/Permissions/Details';
import { StyledSelect as Select } from 'components/Select/Select';
import { Title } from 'components/Typography';
import { deduplicate } from 'lib/helpers';
import { emailRegexp } from 'lib/helpers/validation';
import { useMessage } from 'lib/hooks';
import { colors } from 'lib/theme/colors';

import { InvitedUserBox } from './styled';
import { Box } from '../../styled';
import { IInvitesForm } from '../../types';

interface IProps {
  companyId?: string;
}

const { Paragraph } = Typography;

const Invites = ({ companyId }: IProps) => {
  const { user } = useAuth0();
  const [form] = Form.useForm<IInvitesForm>();
  const [showDetails, setShowDetails] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const message = useMessage();
  const { data: fetchedPermissions, mutate } = useGetPermission(companyId || '');

  const onInvite = async () => {
    setLoading(true);

    try {
      const data = await form.validateFields();
      const notEmpty = data.rows.filter((row) => row.email);
      const unique = deduplicate(notEmpty, 'email');
      await Promise.all(
        unique.map((invite) =>
          createOrUpdateCompanyPermissions(companyId as string, {
            permission: { login: invite.email, permission: invite.permission, notify: true },
          }),
        ),
      );
      await mutate();
      form.resetFields();
      message.success('We sent invites successfully to your team members.');
    } catch (error) {
      if (error?.message) {
        message.error(error.response?.data.error?.msg);
      }
    } finally {
      setLoading(false);
    }
  };

  const values = Form.useWatch([], { form, preserve: true });

  useEffect(() => {
    const allEmpty = values?.rows.every((row) => !row.email);
    if (!values || allEmpty) return;

    form.validateFields({ validateOnly: true }).then(
      () => {
        setIsButtonDisabled(false);
      },
      () => {
        setIsButtonDisabled(true);
      },
    );
  }, [values]);

  if (!companyId) return null;

  const permissions = fetchedPermissions?.body?.filter((permission) => permission.login !== user?.email);

  return (
    <Form name="invites" form={form} layout="vertical">
      <Flex gap={40}>
        <Flex vertical flex="0 0 220px">
          <Title level={3} data-testid="empty-placeholder:title">
            User Management
          </Title>
          <Paragraph type="secondary">Invite developers or other team members and chose a user permission.</Paragraph>
        </Flex>
        <Flex flex={1}>
          <Box>
            <Paragraph type="secondary">
              We make it incredibly easy to collaborate with your team, From implementation to creating new banners with
              different compliance frameworks.
            </Paragraph>
            <Form.List
              name="rows"
              initialValue={[
                { email: '', permission: Permission.READ },
                { email: '', permission: Permission.READ },
                { email: '', permission: Permission.READ },
              ]}
            >
              {(fields) => (
                <>
                  {fields.map(({ key, name, ...restField }, index) => (
                    <Row key={key} gutter={[16, 16]}>
                      <Col span={15}>
                        <Item
                          {...restField}
                          name={[name, 'email']}
                          rules={[{ pattern: emailRegexp, message: 'This should be a valid email address' }]}
                          label={!index && 'Email'}
                          colon={false}
                          validateTrigger="onBlur"
                        >
                          <Input placeholder="Email" />
                        </Item>
                      </Col>
                      <Col span={9}>
                        <Item
                          {...restField}
                          name={[name, 'permission']}
                          label={!index && 'Permission'}
                          rules={[
                            {
                              validator: (_, value) => {
                                const emailValue = form.getFieldValue(['rows', name, 'email']);
                                if (emailValue && !value) {
                                  return Promise.reject(Error('Please select a permission'));
                                }
                                return Promise.resolve();
                              },
                            },
                          ]}
                          colon={false}
                        >
                          <Select size="large" data-testid="select:user-permission" placeholder="Select permission">
                            <Select.Option value={Permission.READWRITE}>Write</Select.Option>
                            <Select.Option value={Permission.READ}>Read</Select.Option>
                            <Select.Option value={Permission.ADMIN}>Admin</Select.Option>
                          </Select>
                        </Item>
                      </Col>
                    </Row>
                  ))}
                </>
              )}
            </Form.List>
            <Button
              type="primary"
              onClick={onInvite}
              size="large"
              style={{ marginTop: '16px', height: '40px', padding: '8px 14px' }}
              disabled={isButtonDisabled}
              loading={isLoading}
            >
              Send invites
            </Button>
            {Boolean(permissions?.length) && (
              <>
                <Divider />
                <Title level={5}>Invites sent</Title>
                <Flex gap={16} vertical>
                  {permissions?.map((permission) => (
                    <InvitedUserBox key={permission.login}>{permission.login}</InvitedUserBox>
                  ))}
                </Flex>
              </>
            )}
            <Divider />
            <Button
              type="link"
              style={{ color: colors.primaryText }}
              onClick={(e) => {
                e.preventDefault();
                setShowDetails(!showDetails);
              }}
            >
              Learn more about permissions
              <Expand style={{ transform: showDetails ? 'rotate(180deg)' : '' }} />
            </Button>
            {showDetails && (
              <PermissionDetails
                style={{ backgroundColor: colors.white }}
                adminRights={[
                  'Manage user permissions',
                  'Full access to this configuration',
                  'Full access to all settings belonging to the company',
                ]}
                writeRights={['Full access to all settings belonging to the company']}
                readRights={['Read-only access to all settings belonging to the company']}
              />
            )}
          </Box>
        </Flex>
      </Flex>
    </Form>
  );
};

export default Invites;
