import { Flex } from 'antd';
import { useCallback, useState } from 'react';
import { KeyedMutator } from 'swr';

import { OkResponseDto, SearchSettingOutDto, UnassignedSettingsOutDto } from 'api/requests/generated/generated.schemas';
import Button from 'components/Button';
import DotsLoader from 'components/DotsLoader';
import { Edit as EditIcon } from 'components/Icons';
import SettingMenu from 'components/Menu/SettingMenu';
import MoveTo from 'components/Modal/MoveTo';
import SkeletonTable, { SkeletonTableColumnsType } from 'components/SkeletonTable';
import Table, { ITableProps } from 'components/Table';
import openSettingInAI from 'lib/helpers/openSettingInAI';
import { useNewConfigurations, usePrivileges, useSelectedCompany } from 'lib/hooks';
import { DeleteSetting, DuplicateSetting, UnassignSetting } from 'pages/Companies/Configurations/Modals';
import EditSettingPermissions from 'pages/Companies/Configurations/Modals/Permissions';

import { useDuplicate } from './useDuplicate';

export type ISetting = SearchSettingOutDto | UnassignedSettingsOutDto;

interface IConfigurationTableProps<T extends ISetting> extends ITableProps<T> {
  settings: T[];
  isLoading: boolean;
  notFound?: React.ReactNode;
  canMove?: boolean;
  withoutPermissionsStep?: boolean;
  mutate: KeyedMutator<OkResponseDto & { body: T[] }>;
  onCloseForAction?: { [key in ConfigurationActions]?: () => void | Promise<void> };
  disableDuplicate?: boolean;
}

enum ConfigurationActions {
  Delete = 'DELETE',
  Duplicate = 'DUPLICATE',
  Unassign = 'UNASSIGN',
  Move = 'MOVE',
  ManagePermissions = 'MANAGE',
}

const ConfigurationTable = <T extends ISetting>({
  settings,
  isLoading,
  columns = [],
  notFound,
  canMove,
  mutate,
  withoutPermissionsStep,
  disableDuplicate,
  onCloseForAction,
  ...props
}: IConfigurationTableProps<T>) => {
  const { markConfigurationAsOld } = useNewConfigurations();
  const company = useSelectedCompany();
  const { isCSA } = usePrivileges();
  const { duplicate, isDuplicating } = useDuplicate();

  const [displayDotsLoader, toggleDotsLoader] = useState('');
  const [currentAction, setCurrentAction] = useState<{ action: ConfigurationActions; setting: ISetting } | null>(null);

  const onRow = useCallback(
    (record: ISetting) => ({
      onClick: (event: React.MouseEvent<HTMLTableRowElement>) => {
        markConfigurationAsOld(record.id);
        if (event.currentTarget.nodeName !== 'BUTTON') {
          openSettingInAI(record.id, record.selectedSettingsAppVersion);
        }
      },
    }),
    [markConfigurationAsOld],
  );

  const onClose = async () => {
    if (onCloseForAction && currentAction) {
      await onCloseForAction[currentAction.action]?.();
    }

    mutate();
    setCurrentAction(null);
  };

  const generatedColumns = [
    ...columns,
    {
      title: '',
      width: '110px',
      render: (setting: T) => (
        <Flex justify="end" gap={12}>
          <SettingMenu
            setting={setting}
            onEditPermissions={() => setCurrentAction({ action: ConfigurationActions.ManagePermissions, setting })}
            onUnassign={() => setCurrentAction({ action: ConfigurationActions.Unassign, setting })}
            onDelete={() => setCurrentAction({ action: ConfigurationActions.Delete, setting })}
            onMoveTo={
              canMove || ('parentId' in setting && !setting.parentId)
                ? () => setCurrentAction({ action: ConfigurationActions.Move, setting })
                : null
            }
            disableDuplicate={disableDuplicate && 'parentId' in setting && Boolean(setting.parentId)}
            onDuplicate={
              isCSA
                ? () => setCurrentAction({ action: ConfigurationActions.Duplicate, setting })
                : () => {
                    duplicate(setting.id);
                    mutate();
                  }
            }
          />
          <Button key="edit" data-testid="button:edit-details-open" size="small">
            <Flex gap={4}>
              <EditIcon />
              Edit
            </Flex>
          </Button>
        </Flex>
      ),
    },
  ];

  return (
    <>
      <SkeletonTable loading={isLoading} columns={generatedColumns as SkeletonTableColumnsType[]}>
        {settings?.length > 0 ? (
          <Table<T>
            columns={generatedColumns}
            dataSource={settings?.map((setting) => ({ key: setting.id, ...setting }))}
            onRow={onRow}
            {...props}
          />
        ) : (
          notFound
        )}
      </SkeletonTable>

      {currentAction && (
        <>
          <EditSettingPermissions
            open={currentAction.action === ConfigurationActions.ManagePermissions}
            setting={currentAction.setting}
            onClose={onClose}
            company={company}
          />
          <UnassignSetting
            open={currentAction.action === ConfigurationActions.Unassign}
            onClose={onClose}
            setting={currentAction.setting as SearchSettingOutDto}
            toggleLoader={toggleDotsLoader}
          />
          <DeleteSetting
            open={currentAction.action === ConfigurationActions.Delete}
            onClose={onClose}
            settingId={currentAction.setting.id}
          />
          <MoveTo
            setting={currentAction.setting}
            open={currentAction.action === ConfigurationActions.Move}
            onClose={onClose}
            withoutPermissionsStep={withoutPermissionsStep}
          />
          <DuplicateSetting
            open={currentAction.action === ConfigurationActions.Duplicate}
            onClose={onClose}
            setting={currentAction.setting}
            onDuplicate={duplicate}
          />
        </>
      )}

      <DotsLoader open={Boolean(displayDotsLoader || isDuplicating)} text={displayDotsLoader || isDuplicating} />
    </>
  );
};

export default ConfigurationTable;
