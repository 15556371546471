import { Flex, Button } from 'antd';

import { Clock } from 'components/Icons';
import { Text } from 'components/Typography';
import { TrackingEvents, TrackingVariables, SCENARIO } from 'interfaces/enums';
import { IUpgradeSubscription } from 'lib/contexts/UpgradePlan';
import { getPlanName } from 'lib/helpers';
import { getDaysLeft } from 'lib/helpers/date';
import { trackEventAll } from 'lib/helpers/trackEventAll';
import { useManageSubscription } from 'lib/hooks';
import { colors } from 'lib/theme/colors';

import StatusBar from '.';

interface IGetFullAccessBarProps {
  trialFinishedAt: string | null;
  subscription: IUpgradeSubscription;
}

const GetFullAccessBar = ({ trialFinishedAt, subscription }: IGetFullAccessBarProps) => {
  const daysLeft = getDaysLeft(trialFinishedAt);
  const { open } = useManageSubscription();

  const onUpgrade = () => {
    trackEventAll(TrackingEvents.UPGRADE_INITIATED, {
      [TrackingVariables.PLAN_NAME]: getPlanName(subscription.pricePlan, subscription.type),
      [TrackingVariables.SCENARIO]: SCENARIO.TRIAL_UPGRADE,
      [TrackingVariables.REFERRER_SECTION]: 'Header',
      [TrackingVariables.REFERRER_URL]: window.location.origin + window.location.pathname,
    });
    open(subscription.id);
  };

  return (
    <StatusBar type="black">
      <Flex gap={8}>
        <Clock />
        <Text color={colors.white}>Enjoy all the premium features:</Text>
        <Text color={colors.white}>
          Premium trial ends in{' '}
          <strong>
            {daysLeft} day{daysLeft > 1 ? 's' : ''} left
          </strong>
        </Text>
      </Flex>
      <Button ghost onClick={onUpgrade}>
        Get Full Access
      </Button>
    </StatusBar>
  );
};

export default GetFullAccessBar;
