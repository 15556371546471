import { Flex } from 'antd';
import styled from 'styled-components';

export const Partner = styled(Flex)`
  border: 1px solid ${(props) => props.theme.colors.gray[50]};
  border-radius: 4px;
  padding: 16px 20px;
  background: ${(props) => props.theme.colors.gray[16]};
`;

export const PartnerName = styled(Flex)`
  & svg {
    width: 24px;
    height: 24px;
  }
`;

export const PartnerActionsButtons = styled(Flex)`
  & svg {
    width: 24px;
    height: 24px;
  }
`;
