import { Row, Col, Flex, Space, Select, Form } from 'antd';
import { FormInstance } from 'antd/lib';
import { useEffect, useState } from 'react';

import { getSurveyById } from 'api/requests/survey';
import { Text, Title } from 'components/Typography';
import { ISurveyCategory, ISurveyQuestion } from 'interfaces/responses';
import { SkeletonSurvey } from 'pages/Onboarding/Survey/SkeletonSurvey';

import { StyledRadio } from './styled';
import { Box } from '../../styled';

const { Item } = Form;

const radioOptions = ['SIGNING_TYPE', 'COMPANY_SIZE', 'COMPANY_DOMAINS'];

enum Options {
  RADIO = 'RADIO',
  SELECT = 'SELECT',
}

interface ISurveyProps {
  surveyId: string;
  form: FormInstance<FormInstance<{ [key: string]: string }>>;
}

type TCategoriesWithQuestions = (ISurveyCategory & { questions: (ISurveyQuestion & { selectionType: Options })[] })[];

const Survey = ({ surveyId, form }: ISurveyProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [categories, setCategories] = useState<TCategoriesWithQuestions | null>(null);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);

        const result = await getSurveyById(surveyId);

        const questions = result.questions.reduce((acc: { [key: string]: ISurveyQuestion[] }, curr) => {
          acc[curr.categoryId] = acc[curr.categoryId] ? [...acc[curr.categoryId], curr] : [curr];
          return acc;
        }, {});

        const mappedCategories = result.categories.map((category) => {
          return {
            ...category,
            questions: questions[category.categoryId].map((question) => ({
              selectionType: radioOptions.includes(question.id) ? Options.RADIO : Options.SELECT,
              ...question,
            })),
          };
        });

        setCategories(mappedCategories);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  return isLoading ? (
    <SkeletonSurvey />
  ) : (
    <Form form={form}>
      <Space direction="vertical" size="middle" style={{ marginBottom: 70 }}>
        {categories?.map(({ questions, title, description, categoryId }) => (
          <Row gutter={40} key={categoryId}>
            <Col flex="30%">
              <Flex vertical gap={8}>
                <Title $noMargin level={3}>
                  {title}
                </Title>
                <Text type="secondary">{description}</Text>
              </Flex>
            </Col>
            <Col flex="70%" style={{ maxWidth: '70%' }}>
              <Box>
                <Flex vertical gap={16}>
                  {questions.map((question) => (
                    <Flex vertical gap={8} key={question.id}>
                      <Text weight={500}>{question.label}</Text>
                      {question.selectionType === Options.SELECT ? (
                        <Item name={question.id} colon={false} noStyle>
                          <Select allowClear options={question.answers} placeholder="Select" size="middle" />
                        </Item>
                      ) : (
                        <Item name={question.id} colon={false} noStyle>
                          <StyledRadio
                            buttonStyle="outline"
                            optionType="button"
                            size="middle"
                            options={question.answers}
                          />
                        </Item>
                      )}
                    </Flex>
                  ))}
                </Flex>
              </Box>
            </Col>
          </Row>
        ))}
      </Space>
    </Form>
  );
};

export default Survey;
