import { Col, Flex, Row, Switch } from 'antd';

import ColorPicker from 'components/ColorPicker';
import { Input } from 'components/Form';
import { Text } from 'components/Typography';
import useOnboarding from 'lib/hooks/useOnboarding';
import { colors } from 'lib/theme/colors';

import { ToggleContainer } from './styled';

const BackgroundOverlay = () => {
  const { customizationDetails, setCustomizationDetails } = useOnboarding();

  const onSwitchChange = (checked: boolean) => {
    const opacity = 0.7;
    const overlayBackground = colors.primaryText;

    setCustomizationDetails((prev) => ({
      ...prev,
      styling: {
        ...prev.styling,
        useOverlay: checked,
        ...(checked
          ? {}
          : {
              overlay: overlayBackground,
              overlayOpacity: opacity,
            }),
      },
    }));
  };

  const onSetColorValue = (name: string, value: string) => {
    setCustomizationDetails((prev) => ({
      ...prev,
      styling: { ...prev.styling, [name]: value },
    }));
  };

  const onOpacityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value as unknown as number;

    setCustomizationDetails((prev) => ({
      ...prev,
      styling: { ...prev.styling, overlayOpacity: Math.floor(value) / 100 },
    }));
  };

  const onOpacityBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const value = event.target.value as unknown as number;

    if (!value || value < 1) {
      setCustomizationDetails((prev) => ({
        ...prev,
        styling: { ...prev.styling, overlayOpacity: 0.01 },
      }));

      return;
    }

    if (value > 100) {
      setCustomizationDetails((prev) => ({
        ...prev,
        styling: { ...prev.styling, overlayOpacity: 1 },
      }));
    }
  };

  return (
    <>
      <ToggleContainer gap={16} align="center">
        <Switch onChange={onSwitchChange} value={customizationDetails.styling.useOverlay} />
        <Text weight={500}>Enable Overlay</Text>
      </ToggleContainer>
      {customizationDetails.styling.useOverlay && (
        <Row justify="space-between" gutter={[20, 20]}>
          <Col span={12}>
            <Flex vertical gap={8}>
              <Text weight={500}>Background</Text>
              <ColorPicker
                colorValue={customizationDetails.styling.overlay as string}
                setColorValue={(value) => onSetColorValue('overlay', value)}
              />
            </Flex>
          </Col>
          <Col span={12}>
            <Flex vertical gap={8}>
              <Text weight={500}>Opacity</Text>
              <Input
                value={Math.floor((customizationDetails?.styling.overlayOpacity as number) * 100)}
                onChange={onOpacityChange}
                onBlur={onOpacityBlur}
                addonAfter="%"
                type="number"
                max={100}
                min={1}
              />
            </Flex>
          </Col>
        </Row>
      )}
    </>
  );
};

export default BackgroundOverlay;
