export enum ConnectToChargebeeStep {
  Validate,
  Confirm,
}

export enum OnboardingSteps {
  Welcome,
  AccountDetails,
  Configuration,
  Invites,
  Implementation,
}

export enum UpgradePlanStep {
  Upgrade,
  AccountDetails,
  Payment,
  Success,
}

export enum UnlinkCompanyStep {
  Validate,
  Confirm,
}
