import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { Flex, Row, Col, Typography } from 'antd';

import { LegalFramework } from 'api/requests/generated/generated.schemas';
import { Text } from 'components/Typography';
import useOnboarding from 'lib/hooks/useOnboarding';

import { BannerContainer, StyledLink } from './styled';
import { getInfoBySelectedFramework } from './utils';
import { StyledText, StyledButton, StyledSwitch, CloseIcon, CloseButton } from '../styled';

const Banner = () => {
  const {
    customizationDetails: { styling, setIsSecondLayerOpen, setIsFirstLayerOpen },
    selectedFramework,
  } = useOnboarding();

  const { description, title, links, buttons } = getInfoBySelectedFramework(selectedFramework);

  const isUsa = selectedFramework !== LegalFramework.GDPR && selectedFramework !== LegalFramework.TCF2;

  return (
    <BannerContainer vertical $background={styling.layerBackground} justify="space-between">
      <Flex vertical gap={4}>
        <Flex gap={4} align="center" justify="space-between">
          <Text weight={500} size="md" color={styling.text}>
            {title}
          </Text>
          <CloseButton ghost onClick={() => setIsFirstLayerOpen(false)}>
            <CloseIcon color={styling.text} />
          </CloseButton>
        </Flex>
        <Typography.Paragraph style={{ fontSize: 11, margin: 0, color: styling.text }} ellipsis={{ rows: 9 }}>
          {description}
        </Typography.Paragraph>
      </Flex>

      <Row gutter={[0, 10]} align="middle" justify="space-between">
        <Col span={9}>
          <Flex vertical>
            {links.map((el) => (
              <StyledLink
                type="link"
                $color={styling.linkFont}
                key={el.title}
                onClick={() => {
                  if (!el?.clickable) {
                    return;
                  }
                  setIsSecondLayerOpen(true);
                  setIsFirstLayerOpen(false);
                }}
              >
                {el.title}
              </StyledLink>
            ))}
            <StyledText size="sm" color={styling.text}>
              Powered by Usercentrics Consent Management
            </StyledText>
          </Flex>
        </Col>

        {isUsa && (
          <>
            <Col>
              <Flex gap={5}>
                <StyledSwitch
                  size="small"
                  unCheckedChildren={<CloseOutlined />}
                  checkedChildren={<CheckOutlined />}
                  checked={false}
                  $background={styling.toggleInactiveBackground}
                  $iconColor={styling.toggleInactiveIcon}
                />
                <Text size="sm" weight={600} color={styling.text}>
                  Do not process my personal information
                </Text>
              </Flex>
            </Col>
          </>
        )}

        {buttons.map((button) => (
          <Col span={isUsa ? 5 : 7} key={`${selectedFramework}${[button]}`}>
            <StyledButton $background={styling.buttonBackgroundColor} $color={styling.buttonTextColor}>
              {button}
            </StyledButton>
          </Col>
        ))}
      </Row>
    </BannerContainer>
  );
};

export default Banner;
