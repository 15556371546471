import { Flex } from 'antd';
import { useMemo } from 'react';

import Button from 'components/Button';
import { Clock, Lightning } from 'components/Icons';
import { TrackingEvents, TrackingVariables, SCENARIO } from 'interfaces/enums';
import { IUpgradeSubscription } from 'lib/contexts/UpgradePlan';
import { isDmaCoupon, getPlanName } from 'lib/helpers';
import { getDaysLeft } from 'lib/helpers/date';
import { titleCase } from 'lib/helpers/titlecase';
import { trackAmplitudeEvent } from 'lib/helpers/trackAmplitudeEvent';
import { trackEventAll } from 'lib/helpers/trackEventAll';
import { useUpgradePlan } from 'lib/hooks';

import { Trial } from './styled';

const getAccountName = (accountName: string) => (accountName ? `${accountName} - ` : '');

interface Props {
  readonly?: boolean;
  trials: IUpgradeSubscription[];
}

const TrialStatusBar = ({ readonly, trials }: Props) => {
  const { setPlanToUpgrade } = useUpgradePlan();

  const mapped = useMemo(
    () =>
      trials.map((trial) => ({
        ...trial,
        daysLeft: getDaysLeft(trial.trialFinishedAt),
        name: `${trial.pricePlan} ${titleCase(trial.type)} Trial`,
        discount: trial?.coupon?.discountPercentage || '',
        isDma: isDmaCoupon(trial?.couponId),
      })),
    [trials],
  );

  const onUpgradeClick = (trial: IUpgradeSubscription) => {
    /**
     * Tracking events
     */
    trackAmplitudeEvent(TrackingEvents.CTA_TRIAL_BAR_UPGRADE, {
      [TrackingVariables.SOURCE_PAGE]: 'Company',
      [TrackingVariables.PLAN_NAME]: getPlanName(trial?.pricePlan, trial?.type),
    });
    trackEventAll(TrackingEvents.UPGRADE_INITIATED, {
      [TrackingVariables.PLAN_NAME]: getPlanName(trial.pricePlan, trial.type),
      [TrackingVariables.SCENARIO]: SCENARIO.TRIAL_UPGRADE,
      [TrackingVariables.REFERRER_SECTION]: 'Header',
      [TrackingVariables.REFERRER_URL]: window.location.origin + window.location.pathname,
    });
    /**
     * ====
     */

    setPlanToUpgrade(trial);
  };

  return (
    <>
      {mapped.map((trial) => (
        <Trial key={trial.id} data-testid="div:trial-status-bar" type="trial">
          <Flex align="center" data-testid="trial-status-bar_details" gap={12}>
            <Clock />
            {getAccountName(trial.accountName)}
            {getPlanName(trial?.pricePlan, trial?.type)} Trial: {trial.daysLeft} day{trial.daysLeft > 1 ? 's' : ''} left
          </Flex>
          {!readonly && (
            <Button ghost onClick={() => onUpgradeClick(trial)} icon={<Lightning />} data-testid="button:upgrade-trial">
              {trial.isDma
                ? 'Upgrade and get 1 month for free'
                : trial.discount
                ? `Upgrade with ${trial.discount}% discount`
                : 'Upgrade'}
            </Button>
          )}
        </Trial>
      ))}
    </>
  );
};

export default TrialStatusBar;
