import { reset } from '@amplitude/analytics-browser';
import { useAuth0 } from '@auth0/auth0-react';

import { TrackingEvents } from 'interfaces/enums';

import { trackEventAll } from '../helpers/trackEventAll';

export const useLogout = (redirectToLogout = false) => {
  const { logout } = useAuth0();

  return async () => {
    reset();

    trackEventAll(TrackingEvents.LOGOUT);

    await logout({
      logoutParams: {
        returnTo: `${window.location.origin}${redirectToLogout ? '/logout' : '/login'}`,
      },
    });
  };
};
