import { Form } from 'antd';
import { KeyedMutator } from 'swr';

import { createRuleset } from 'api/requests';
import { RuleSetOutDto, OkResponseDto } from 'api/requests/generated/generated.schemas';
import { StepModal } from 'components/Modal';
import PremiumFeatureBar from 'components/PremiumFeatureBar';
import { Entitlements, TrackingEvents, TrackingVariables, SCENARIO } from 'interfaces/enums';
import ISetting from 'interfaces/ISetting';
import { getPlanName } from 'lib/helpers';
import { trackEventAll } from 'lib/helpers/trackEventAll';
import { useEntitlement, useMessage } from 'lib/hooks';

import Details from './Content/Details';
import GlobalSetting from './Content/GlobalSetting';

const { useForm, useWatch } = Form;

interface IProps {
  onClose: () => Promise<void> | void;
  companyId: string;
  configurations: ISetting[];
  mutateRulesets: KeyedMutator<OkResponseDto & { body: RuleSetOutDto[] }>;
}

const CreateRulesetModal = ({ onClose, companyId, configurations, mutateRulesets }: IProps) => {
  const [globalSettingForm] = useForm();
  const [detailsForm] = useForm();
  const { getEntitlement, subscription } = useEntitlement();

  const { disable } = getEntitlement(Entitlements.GEOTARGETING);

  const settingsId = useWatch(['settingsId'], { form: globalSettingForm, preserve: true });
  const rulesetName = useWatch(['name'], detailsForm);
  const message = useMessage();

  const addRuleset = async () => {
    if (disable) return;

    const details = detailsForm.getFieldsValue(true);
    const globalSetting = globalSettingForm.getFieldsValue(true);

    const data = {
      ...details,
      rules: [],
      defaultRule: globalSetting,
    };
    await createRuleset(data, { customerId: companyId });

    message.success('Ruleset  was created successfully');
    await mutateRulesets();
  };

  return (
    <StepModal
      open
      title="Create Ruleset"
      okText="Create Ruleset"
      width={770}
      bodyHeight={500}
      onClose={onClose}
      banner={
        subscription &&
        disable && (
          <PremiumFeatureBar
            subscriptionId={subscription.id}
            title="This feature is available in higher plan"
            description="Upgrade your plan to unlock exclusive features and premium content."
            buttonText="upgrade"
            style={{ marginBottom: '24px' }}
            trackEvent={() => {
              trackEventAll(TrackingEvents.UPGRADE_INITIATED, {
                [TrackingVariables.PLAN_NAME]: getPlanName(subscription.plan, subscription.type),
                [TrackingVariables.SCENARIO]: subscription.inTrial ? SCENARIO.TRIAL_UPGRADE : SCENARIO.PLAN_UPGRADE,
                [TrackingVariables.REFERRER_SECTION]: 'Create Ruleset',
                [TrackingVariables.REFERRER_URL]: window.location.origin + window.location.pathname,
              });
            }}
          />
        )
      }
      steps={[
        {
          content: <Details form={detailsForm} disabled={disable} />,
          primaryButton: {
            disabled: disable || !rulesetName,
          },
        },
        {
          content: <GlobalSetting form={globalSettingForm} configurations={configurations} />,
          handleNext: addRuleset,
          primaryButton: {
            disabled: disable || !settingsId,
          },
        },
      ]}
    />
  );
};

export default CreateRulesetModal;
