import { Permission } from 'api/requests/generated/generated.schemas';
import { PARSING_PERMISSIONS } from 'lib/consts';

import { titleCase } from './titlecase';

export const parsePermissionLabel = (permission: Permission): string | Permission => {
  const parsedValue = PARSING_PERMISSIONS[permission];

  return parsedValue ? parsedValue : titleCase(permission);
};
