import { Badge, Col, Flex } from 'antd';

import { BankAccountDto, CardDto, PaymentSourceDto, PaymentSourceType } from 'api/requests/generated/generated.schemas';
import Card from 'components/Card';
import {
  Amex as AmexIcon,
  BankAccount as BankAccountIcon,
  Card as CardIcon,
  MasterCard as MasterCardIcon,
  Union as UnionIcon,
  Visa as VisaIcon,
} from 'components/Icons';
import { Text } from 'components/Typography';
import { titleCase } from 'lib/helpers/titlecase';

export const renderPaymentSource = (item: PaymentSourceDto) => {
  switch (item.type) {
    case PaymentSourceType.card:
      return Boolean(item.card) && <CardItem card={item.card} key={item.id} />;
    case PaymentSourceType.direct_debit:
      return Boolean(item.bankAccount) && <BankAccountItem bank={item.bankAccount} key={item.id} />;
    default:
      return null;
  }
};

export const getCardIcon = (item: string) => {
  switch (item) {
    case 'visa':
      return <VisaIcon />;
    case 'mastercard':
      return <MasterCardIcon />;
    case 'american_express':
      return <AmexIcon />;
    case 'union':
      return <UnionIcon />;
    default:
      return null;
  }
};

const CardItem = ({ card }: { card: CardDto }) => (
  <Col span={24}>
    <Card
      key={card.id}
      styles={{
        body: { padding: 16 },
      }}
    >
      <Flex gap={20}>
        <CardIcon />
        <Flex vertical>
          <Text strong>Credit Card</Text>
          <Flex align="center" gap={5}>
            {getCardIcon(card.brand)}
            <Text size="sm">
              {titleCase(card.brand)} ending {card.last4}
            </Text>
            <Badge status="default" />
            <Text size="sm">
              Expires on: {card.expiryMonth}/{card.expiryYear}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Card>
  </Col>
);

export const BankAccountItem = ({ bank }: { bank: BankAccountDto }) => (
  <Col span={24}>
    <Card
      key={bank.id}
      styles={{
        body: { padding: 16 },
      }}
    >
      <Flex gap={20}>
        <BankAccountIcon />
        <Flex vertical>
          <Text strong>Bank Account</Text>
          <Flex align="center" gap={5}>
            <Text size="sm">Account Number ending {bank.last4}</Text>
            <Badge status="default" />
            <Text size="sm">{bank.bankName}</Text>
          </Flex>
        </Flex>
      </Flex>
    </Card>
  </Col>
);
