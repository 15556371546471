import useOnboarding from 'lib/hooks/useOnboarding';

import BackgroundOverlay from './BackgroundOverlay';
import ButtonColors from './ButtonColors';
import { layoutColors, toggleColors } from './colorOptions';
import Colors from './Colors';
import TabTemplate from '../../TabTemplate';

const StylingTab = () => {
  const {
    customizationDetails: { setIsSecondLayerOpen, setIsFirstLayerOpen },
  } = useOnboarding();

  return (
    <TabTemplate
      items={[
        {
          title: 'Background Overlay',
          key: 'backgroundOverlay',
          content: <BackgroundOverlay />,
          collapsible: true,
        },
        {
          title: 'Button Colors',
          key: 'buttonColors',
          collapsible: true,
          content: <ButtonColors />,
        },
        {
          title: 'Layout Colors',
          key: 'layoutColors',
          collapsible: true,
          content: <Colors colors={layoutColors} />,
        },
        {
          title: 'Toggle Colors',
          key: 'toggleColors',
          collapsible: true,
          content: <Colors colors={toggleColors} />,
          onClick: () => {
            setIsFirstLayerOpen(false);
            setIsSecondLayerOpen(true);
          },
        },
      ]}
    />
  );
};

export default StylingTab;
