import { Flex } from 'antd';
import { useState } from 'react';

import { PremiumTagIfOnePricing } from 'components/Tag/PremiumTagIfOnePricing';

import { ItemsContainer, StyledCollapse } from './styled';

interface ITabLayoutProps {
  items: {
    title: string;
    content: React.ReactNode;
    key: string;
    collapsible?: boolean;
    onClick?: () => void;
  }[];
}

const TabTemplate = ({ items }: ITabLayoutProps) => {
  const [activeItemKey, setActiveItem] = useState<React.Key>('');

  const activeKeys = items
    .map((item, index) =>
      item.key === activeItemKey || !item.collapsible || (!activeItemKey && index === 0) ? item.key : undefined,
    )
    .filter(Boolean);

  return (
    <ItemsContainer>
      <StyledCollapse
        bordered={false}
        activeKey={activeKeys as string[]}
        items={items.map((item) => ({
          label: (
            <Flex justify="space-between" style={{ paddingLeft: 24 }}>
              {item.title} <PremiumTagIfOnePricing />
            </Flex>
          ),
          children: item.content,
          key: item.key,
          onItemClick: (key) => {
            setActiveItem(key === activeItemKey ? '' : key);

            item?.onClick?.();
          },
          showArrow: Boolean(item.collapsible),
          collapsible: item.collapsible ? 'header' : 'disabled',
        }))}
        expandIconPosition="end"
      />
    </ItemsContainer>
  );
};

export default TabTemplate;
