import { ButtonProps, Flex } from 'antd/lib';

import Button from 'components/Button';
import { Text } from 'components/Typography';

import { ButtonsWrapper, Footer } from './styled';

interface IFooterProps {
  buttons: Array<
    {
      isShown: boolean;
      key: string;
    } & ButtonProps
  >;
  leftSide?: { label?: string; buttonText?: string; onClick: () => void };
}

export default ({ buttons, leftSide }: IFooterProps) => (
  <Footer>
    {leftSide && (
      <Flex align="center" gap={16} style={{ marginRight: 'auto' }}>
        {leftSide.buttonText && (
          <Button size="large" onClick={leftSide.onClick}>
            {leftSide.buttonText}
          </Button>
        )}
        {leftSide.label && <Text size="xs">{leftSide.label}</Text>}
      </Flex>
    )}
    <ButtonsWrapper>
      {buttons.map(({ isShown, title, key, ...props }) =>
        isShown ? (
          <Button size="large" key={key} {...props} disabled={props.disabled}>
            {title}
          </Button>
        ) : null,
      )}
    </ButtonsWrapper>
  </Footer>
);
