import PrivacyButton from './PrivacyButton';
import PrivacyColor from './PrivacyColor';
import Size from './Size';
import TabTemplate from '../../TabTemplate';

const PrivacyTriggerTab = () => {
  return (
    <TabTemplate
      items={[
        { title: 'Privacy Button', content: <PrivacyButton />, key: 'privacyBanner' },
        {
          title: 'Color',
          key: 'color',
          content: <PrivacyColor />,
        },
        {
          title: 'Size',
          key: 'size',
          content: <Size />,
        },
      ]}
    />
  );
};

export default PrivacyTriggerTab;
