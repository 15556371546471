import { Form, Alert, Space, Typography } from 'antd';
import { useState } from 'react';

import { linkCustomer, validateCustomerLink } from 'api/requests';
import { InvalidLinkOutDto, ValidLinkOutDto } from 'api/requests/generated/generated.schemas';
import { Item, Input } from 'components/Form';
import Modal from 'components/Modal/Modal';
import { ConnectToChargebeeStep } from 'interfaces/steps';
import { titleCase } from 'lib/helpers/titlecase';
import useMessage from 'lib/hooks/useMessage';

const { Title, Paragraph } = Typography;

interface IConnectToChargebeeProps {
  open: boolean;
  customerId: string;
  companyName: string;
  onClose: () => void;
}

const ConnectToChargebee = ({ open, onClose, customerId, companyName }: IConnectToChargebeeProps) => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [validationStatus, setValidationStatus] = useState<Partial<ValidLinkOutDto & InvalidLinkOutDto> | null>(null);
  const [step, setStep] = useState(ConnectToChargebeeStep.Validate);
  const [loading, setLoading] = useState(false);
  const message = useMessage();
  const [form] = Form.useForm();

  const onFieldsChange = () => {
    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
    setIsButtonDisabled(hasErrors);
  };

  const setInitialState = () => {
    setStep(ConnectToChargebeeStep.Validate);
    setValidationStatus(null);
    setIsButtonDisabled(true);
  };

  const onModalClose = () => {
    onClose();
    setInitialState();
  };

  const onValidateClick = async () => {
    try {
      setLoading(true);
      const { billingEmail, chargebeeCustomerId } = await form.validateFields();
      const { body: data } = await validateCustomerLink({
        billingEmail,
        chargebeeCustomerId,
        permissionCustomerId: customerId,
      });
      setValidationStatus(data);

      if (data.isValid) {
        setStep(ConnectToChargebeeStep.Confirm);
      }
    } catch (error) {
      setValidationStatus({ isValid: false });
      setIsButtonDisabled(true);
      message.error(error.response?.data.error?.msg);
    } finally {
      setLoading(false);
    }
  };

  const onConfirmClick = async () => {
    try {
      setLoading(true);

      const { billingEmail, chargebeeCustomerId } = await form.validateFields();
      await linkCustomer({ billingEmail, chargebeeCustomerId, permissionCustomerId: customerId });

      onModalClose();

      message.success(`Successfully connected with Chargebee Customer ${chargebeeCustomerId}`);
    } catch (error) {
      setInitialState();
      message.error('Confirmation failed');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      open={open}
      title="Connect to Chargebee"
      primaryButton={{
        label: step === ConnectToChargebeeStep.Validate ? 'Validate' : 'Confirm',
        disabled: isButtonDisabled,
        loading,
        onClick: step === ConnectToChargebeeStep.Validate ? onValidateClick : onConfirmClick,
      }}
      secondaryButton={{
        label: 'Cancel',
        onClick: onModalClose,
      }}
      width={740}
      onClose={onModalClose}
      destroyOnClose
    >
      <Title level={3}>Account Validation</Title>
      <Paragraph>
        Enter valid information from Chargebee below to establish a connection with{' '}
        <strong>{titleCase(companyName)}</strong>.
      </Paragraph>
      <Space direction="vertical" size="middle" style={{ width: '100%' }}>
        {validationStatus?.isValid ? (
          <Space direction="vertical">
            <Alert
              type="success"
              showIcon
              data-testid="alert:success"
              message={
                <>
                  Chargebee Customer{' '}
                  <strong>
                    {titleCase(validationStatus?.customer?.name || '')} [{form.getFieldValue('chargebeeCustomerId')}]
                  </strong>{' '}
                  successfully validated.
                </>
              }
            />
            <Alert
              data-testid="alert:info"
              type="info"
              showIcon
              message="Please note that existing Company Information will be updated with the data from Chargebee."
            />
          </Space>
        ) : (
          validationStatus?.reason && (
            <Alert message={`Validation failed. ${validationStatus.reason}. Please try again.`} type="error" showIcon />
          )
        )}

        <Form preserve={false} name="connect-to-chargebee" form={form} onFieldsChange={onFieldsChange}>
          <Item
            label="Chargebee Customer ID"
            name="chargebeeCustomerId"
            tooltip="Identifier for the customer in Chargebee"
            rules={[{ required: true, message: 'Input required' }]}
            validateTrigger="onBlur"
          >
            <Input placeholder="Customer ID" data-testid="input:chargebee-customer-id" />
          </Item>
          <Item
            label="Chargebee Email"
            name="billingEmail"
            tooltip="Email Address of the customer in Chargebee"
            rules={[{ required: true, message: 'Input required' }]}
            validateTrigger="onBlur"
          >
            <Input placeholder="Email ID" data-testid="input:chargebee-email" />
          </Item>
        </Form>
      </Space>
    </Modal>
  );
};

export default ConnectToChargebee;
