import { Alert, Flex } from 'antd';
import { useMemo } from 'react';

import { LegalFramework } from 'api/requests/generated/generated.schemas';
import { Link, Text } from 'components/Typography';
import { IAB_GUIDELINE_URL } from 'lib/consts';
import getContrast from 'lib/helpers/getContrast';
import useOnboarding from 'lib/hooks/useOnboarding';

import { buttonColors } from '../colorOptions';
import Colors from '../Colors';

const ButtonColors = () => {
  const {
    selectedFramework,
    customizationDetails: { styling },
  } = useOnboarding();

  const hasWrongContrast = useMemo(() => {
    if (!styling.buttonBackgroundColor || !styling.buttonTextColor || selectedFramework !== LegalFramework.TCF2) {
      return false;
    }

    const [ratioItem] = getContrast(styling.buttonBackgroundColor, styling.buttonTextColor);

    return ratioItem < 5; // A minimum color contrast ratio of 5:1 (text color | background color)
  }, [styling.buttonBackgroundColor, styling.buttonTextColor, selectedFramework]);

  return (
    <Flex vertical gap={16}>
      {selectedFramework === LegalFramework.TCF2 && (
        <Alert
          message={
            <Flex>
              <Text>
                A minimum color contrast ratio of 5:1 (text color / background color) is required by the TCF Policy in
                order to avoid unreadable or invisible buttons in your CMP. More information is available{' '}
                <Link target="_blank" href={IAB_GUIDELINE_URL}>
                  here
                </Link>
                .
              </Text>
            </Flex>
          }
          type="info"
          showIcon
        />
      )}
      <div>
        <Colors colors={buttonColors} />
        {hasWrongContrast && (
          <Text weight={300} size="sm" color="red">
            Contrast ratio does not meet IAB requirements
          </Text>
        )}
      </div>
    </Flex>
  );
};

export default ButtonColors;
