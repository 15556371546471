import { CardProps } from 'antd/es/card';
import { useEffect, useRef } from 'react';

import { Card, NoResults, CardFooter } from './styled';

interface ICardProps extends CardProps {
  children: React.ReactNode;
  className?: string;
  footer?: React.ReactNode;
  focused?: boolean;
  borderedFooter?: boolean;
  noResults?: {
    description: string;
    icon: React.ReactNode;
    condition: boolean;
  };
}

export default ({ children, className, noResults, footer, borderedFooter, focused, ...props }: ICardProps) => {
  const ref = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    if (focused) {
      ref.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [focused]);

  return (
    <Card ref={ref} {...props} className={`${focused ? 'focused' : ''} ${className ?? ''}`}>
      {noResults?.condition ? (
        <NoResults gap={15}>
          {noResults.icon}
          {noResults.description}
        </NoResults>
      ) : (
        children
      )}
      {footer && <CardFooter borderedFooter={borderedFooter}>{footer}</CardFooter>}
    </Card>
  );
};
