const HEX_COLOR_CODE_REGEX = /^#(?:[0-9a-fA-F]{6}){1}$/;

function validateColor(color: string): boolean {
  return HEX_COLOR_CODE_REGEX.test(color);
}

function hexToRgb(hexColor: string): number[] {
  const rgbReg = /(?!#).{1,2}/g;
  const rgbInHex = hexColor.match(rgbReg) as string[];
  const [r = '00', g = '00', b = '00'] = rgbInHex;
  return [parseInt(r, 16), parseInt(g, 16), parseInt(b, 16)];
}

function calculateLuminance(rgb: number[]): number {
  const luminanceFormula = (colorComponent: number): number => {
    const initialValue = colorComponent / 255;
    return initialValue <= 0.03928 ? initialValue / 12.92 : Math.pow((initialValue + 0.055) / 1.055, 2.4);
  };
  const [yr, yg, yb] = rgb.map(luminanceFormula);
  // The numbers are coefficients for the Yrgb value
  return 0.2126 * yr + 0.7152 * yg + 0.0722 * yb;
}

function calculateContrastRatio(l1: number, l2: number): [number, number] {
  /**
   *   if a luminance of one of the colors greater, divide it by the lowest one
   *   in order to get the ratio of luminance (contrast)
   */
  if (l1 > l2) {
    // 0.05 is a value from the formula to calculate the contrast
    return [(l1 + 0.05) / (l2 + 0.05), 1];
  }
  return [(l2 + 0.05) / (l1 + 0.05), 1];
}

const getContrast = (color1: string, color2: string) => {
  const colors = [color1, color2];

  if (colors.every((color) => validateColor(color))) {
    const [luminance1, luminance2] = colors.map((color) => calculateLuminance(hexToRgb(color)));
    return calculateContrastRatio(luminance1, luminance2);
  }

  return [];
};

export default getContrast;
