import { useLocation } from 'react-router-dom';

/**
 * Extracts query parameters (IE11 compatible)
 * @return { [key: string]: string } Key-Value of mapped query parameters
 */
export function useQueryParameters(): Record<string, string> {
  const { search } = useLocation();

  return search
    .slice(1)
    .split('&')
    .map((el) => el.split('='))
    .reduce((prev: { [key: string]: string }, [key, value]) => {
      if (key && value) {
        return {
          ...prev,
          [key]: value,
        };
      }
      return prev;
    }, {});
}
