import { useState } from 'react';

import Button from 'components/Button';
import Card from 'components/Card';
import Collapse from 'components/Collapse';
import Tooltip from 'components/Form/Tooltip';
import { Person as PersonIcon } from 'components/Icons';
import * as Typography from 'components/Typography';

import { Partner, PartnerActionsButtons, PartnerName } from './styled';
import PartnerAccessModal from '../Modals/PartnerAccessModal';

export enum PartnerRequestType {
  APPROVAL = 'APPROVAL',
  REVOKE = 'REVOKE',
}

export interface PartnerAccessInformation {
  partnerName: string;
  modalTitle: string;
  typeOfRequest: PartnerRequestType;
}

const PartnerAccess = () => {
  const [isRequestAccessOpen, setIsRequestAccessOpen] = useState(false);
  const [isRevokeAccessOpen, setIsRevokeAccessOpen] = useState(false);
  const partnerAccessToolTipInfo = `Usercentrics partners who referred the CMP to you can request access to assist with your setup. 
  In this section, you can view these access requests and check if a referral partner currently has access to your CMP setup.`;

  const declinePartnerRequest = () => {
    console.error('Decline Partner Access Request');
  };

  const getPartnerAccessInfo = (): PartnerAccessInformation => {
    return {
      partnerName: 'ACME INC',
      modalTitle: isRequestAccessOpen ? 'Approve Partner Access' : 'Revoke Partner Access',
      typeOfRequest: isRequestAccessOpen ? PartnerRequestType.APPROVAL : PartnerRequestType.REVOKE,
    };
  };

  const onClosePartnerAccessModal = async () => {
    // mutate();
    setIsRequestAccessOpen(false);
    setIsRevokeAccessOpen(false);
  };

  return (
    <>
      <Collapse
        expandIconPosition="end"
        defaultActiveKey="partnerAccess"
        items={[
          {
            key: 'partnerAccess',
            label: (
              <Typography.Text strong size="md">
                Partner Access <Tooltip placement="right" title={partnerAccessToolTipInfo} />
              </Typography.Text>
            ),
            children: (
              <Card bordered={false}>
                <Partner justify="space-between">
                  <PartnerName align="center" gap={12}>
                    <PersonIcon />
                    {'ACME Enterprises'}
                  </PartnerName>
                  <PartnerActionsButtons align="right" gap={12}>
                    {/* NOTE: Decline Partner Request Directly - No Modal shown */}
                    <Button
                      onClick={declinePartnerRequest}
                      type="default"
                      size="middle"
                      data-testid="button:decline-access"
                    >
                      Decline
                    </Button>

                    {/* NOTE: Partner Request - Modal shown */}
                    <Button
                      onClick={() => setIsRequestAccessOpen(true)}
                      type="primary"
                      size="middle"
                      data-testid="button:approve-access-open"
                    >
                      Approve
                    </Button>

                    {/* NOTE: Revoke Partner Request - Modal Shown */}
                    <Button
                      onClick={() => setIsRevokeAccessOpen(true)}
                      type="default"
                      size="middle"
                      data-testid="button:revoke-access"
                    >
                      Revoke Access
                    </Button>
                  </PartnerActionsButtons>
                </Partner>
              </Card>
            ),
          },
        ]}
      />

      <PartnerAccessModal
        partnerAccessInfo={getPartnerAccessInfo()}
        open={isRequestAccessOpen || isRevokeAccessOpen}
        onClose={onClosePartnerAccessModal}
      />
    </>
  );
};
export default PartnerAccess;
