import { Space, Flex } from 'antd';
import Cookies from 'js-cookie';
import { useState, useEffect } from 'react';
import { CSVLink } from 'react-csv';

import { useGetAvailableConfigurationsTypes, useGetConfigurationsToExport } from 'api/requests';
import { getCompanies } from 'api/requests/companies';
import { GetAvailableTypesOutDto, Permission, SearchSettingOutDto } from 'api/requests/generated/generated.schemas';
import configurationsPlaceholder from 'assets/placeholder_configuration.svg';
import Button from 'components/Button';
import ConfigurationTable from 'components/ConfigurationTable';
import EmptyListPlaceholder from 'components/EmptyListPlaceholder';
import { Download, Import as ImportIcon } from 'components/Icons';
import ManageConfigurations from 'components/Modal/ManageConfigurations';
import NotFound from 'components/NotFound';
import PageInfo from 'components/PageInfo';
import Search from 'components/Search';
import { QuotaNames, TrackingEvents, TrackingVariables } from 'interfaces/enums';
import { searchFilters, TRACK_COOKIES } from 'lib/consts';
import { formatDate } from 'lib/helpers';
import { sortByDate } from 'lib/helpers/sort';
import { trackEventAll } from 'lib/helpers/trackEventAll';
import { useNewConfigurations, usePrivileges, useSelectedCompany, useEntitlement } from 'lib/hooks';
import { useSettingsSearch } from 'lib/hooks/useSettingsSearch';

import { generateConfigurationColumns } from './columns';
import { CreateConfiguration, ImportSetting } from './Modals';
import { Container, Header } from './styled';

enum ConfigurationActions {
  Manage = 'MANAGE',
  Create = 'CREATE',
  Import = 'IMPORT',
}

const Configurations = () => {
  const { subscription, getQuotaPerSubscription } = useEntitlement();
  const [allowedToCreateConfigByQuota, setAllowedToCreateConfigByQuota] = useState(false);
  const [maxConfigsAllowed, setMaxConfigsAllowed] = useState(0);
  const company = useSelectedCompany();
  const { isCSA } = usePrivileges();

  const [currentAction, setCurrentAction] = useState<ConfigurationActions | null>(null);

  const { settings, mutate, onSearch, isLoading, currentSearchFilter, onFiltersChange, query } = useSettingsSearch(
    company?.id,
  );
  const { companies } = getCompanies(!isCSA);

  const isHasAccess =
    isCSA ||
    (company?.permission !== Permission.READ &&
      company?.permission !== Permission.READWRITE &&
      company?.permission !== Permission.NONE);

  const isRulesetHidden = !isHasAccess || !Boolean(settings.flatMap(({ ruleSets }) => ruleSets).length);

  const { data: availableConfigurations } = useGetAvailableConfigurationsTypes(
    company.id,
    {},
    { swr: { enabled: Boolean(company.id && isHasAccess) } },
  );

  const availableConfigurationsTypes = availableConfigurations?.body as GetAvailableTypesOutDto;
  const hasSettingsOrInSearchMode = settings?.length > 0 || query;

  const {
    data: configurations,
    isLoading: isExporting,
    mutate: mutateConfigurationsToExport,
  } = useGetConfigurationsToExport(company.id, {}, { swr: { enabled: Boolean(isCSA && company.id) } });
  const { newConfigurations } = useNewConfigurations();

  const configurationsToExport =
    configurations?.body
      ?.sort((ca, cb) => sortByDate(cb.createdAt || 0, ca.createdAt || 0))
      ?.map((c) => ({ ...c, createdAt: formatDate(c.createdAt) })) || [];

  const isBillingCompany = Boolean(company.billingCustomerId);

  const possibleTargetCompanies =
    companies?.filter((targetCompany) => {
      const isCurrentUserTargetCompanyAdmin = targetCompany.permission === Permission.ADMIN;
      const isCompaniesInSameAccount =
        !company.billingCustomerId || targetCompany.billingCustomerId === company.billingCustomerId;
      const isSameCompany = targetCompany.id === company.id;

      return isCurrentUserTargetCompanyAdmin && !isSameCompany && (!isBillingCompany || isCompaniesInSameAccount);
    }) || [];

  const possibleConfigurationsToManage = settings?.filter((setting) => !setting?.ruleSets?.length);
  const isAllowedToManageConfigurations =
    possibleConfigurationsToManage?.length > 0 && possibleTargetCompanies.length > 0;

  const isAllowedToCreateConfigurations =
    availableConfigurationsTypes &&
    (availableConfigurationsTypes.isAppAvailable || availableConfigurationsTypes.isWebAvailable);

  const onModalClose = async () => {
    setCurrentAction(null);
    await mutate();
  };

  const onAddConfiguration = () => {
    trackEventAll(TrackingEvents.ADD_CONFIGURATION_ADD_CONFIGURATION_CLICK);
    setCurrentAction(ConfigurationActions.Create);
  };

  const getQuota = async () => {
    const quota = await getQuotaPerSubscription([QuotaNames.NUMBER_OF_CONFIGS]);

    if (!quota) {
      setAllowedToCreateConfigByQuota(true);
    } else {
      const qNumberOfConfigs = quota[QuotaNames.NUMBER_OF_CONFIGS];

      if (qNumberOfConfigs) {
        setAllowedToCreateConfigByQuota(qNumberOfConfigs?.inUse < qNumberOfConfigs?.limit);
        setMaxConfigsAllowed(qNumberOfConfigs.limit);
      }
    }
  };

  useEffect(() => {
    (async () => {
      if (!subscription) {
        setAllowedToCreateConfigByQuota(true);
        return;
      }

      await getQuota();
    })();
  }, [subscription]);

  useEffect(() => {
    const cookiesCompanyId = Cookies.get(TRACK_COOKIES.TRACK_COMPANY_CREATED);
    if (cookiesCompanyId === company.billingCustomerId) {
      trackEventAll(TrackingEvents.ADD_COMPANY_FORM_SUBMITTED, {
        [TrackingVariables.FORM_STEP]: 3,
        [TrackingVariables.FORM_STEP_NAME]: 'Add Company Success',
      });
      Cookies.remove(TRACK_COOKIES.TRACK_COMPANY_CREATED);
    }
  }, []);

  return (
    <Container>
      <Flex align="start" justify="space-between">
        <PageInfo
          title="Configurations"
          description="Below you will find an overview of all configurations which are assigned to this company."
        />
        {company && isHasAccess && (
          <Header justify="space-between">
            <Space>
              {isCSA && Boolean(configurationsToExport.length) && (
                <CSVLink
                  filename={`[${company.id}]_${company.name?.replace(/ /g, '_')}_settings_export.csv`}
                  data={configurationsToExport}
                  headers={[
                    { label: 'SettingsID', key: 'settingsId' },
                    { label: 'Created At', key: 'createdAt' },
                  ]}
                  target="_blank"
                >
                  <Button size="large" disabled={isLoading} loading={isExporting} onClick={() => null}>
                    <Download /> Export in .csv
                  </Button>
                </CSVLink>
              )}

              {isCSA && (
                <Button
                  size="large"
                  disabled={isLoading}
                  onClick={() => setCurrentAction(ConfigurationActions.Import)}
                  data-testid="button:import-unassigned"
                >
                  <ImportIcon /> Import Unassigned
                </Button>
              )}

              {isAllowedToManageConfigurations && (
                <Button
                  size="large"
                  disabled={isLoading}
                  data-testid="button:manage-configurations"
                  onClick={() => setCurrentAction(ConfigurationActions.Manage)}
                >
                  Manage Configurations
                </Button>
              )}

              {isAllowedToCreateConfigurations && hasSettingsOrInSearchMode && (
                <Button disabled={isLoading} type="primary" size="large" onClick={onAddConfiguration}>
                  Add Configuration
                </Button>
              )}
            </Space>
          </Header>
        )}
      </Flex>
      <Flex vertical gap={24}>
        {hasSettingsOrInSearchMode && (
          <Search
            filterItems={isCSA ? searchFilters : undefined}
            defaultFilterOption={searchFilters[1]}
            onFilterChange={onFiltersChange}
            onSearch={onSearch}
            isLoading={isLoading}
            placeholder={`Search for configuration by ${currentSearchFilter?.label} parameter`}
            data-testid="input:assigned-configurations-search"
          />
        )}
      </Flex>
      <ConfigurationTable<SearchSettingOutDto>
        columns={generateConfigurationColumns(newConfigurations, { isCSA, isRulesetHidden })}
        pagination
        onCloseForAction={{
          DELETE: async () => {
            await getQuota();
            mutateConfigurationsToExport();
          },
        }}
        settings={settings}
        isLoading={isLoading}
        canMove={isAllowedToManageConfigurations}
        mutate={mutate}
        notFound={
          !query ? (
            <EmptyListPlaceholder
              title="No configuration created"
              description={
                "It's time to create your first configuration.\n\nIn case you want to cover different legal frameworks in certain regions, later you can create more configurations and assign them to the Geolocation Ruleset for your domain."
              }
              image={configurationsPlaceholder}
              button={
                (isHasAccess &&
                  isAllowedToCreateConfigurations && {
                    text: 'Create Configuration',
                    onClick: () => setCurrentAction(ConfigurationActions.Create),
                  }) ||
                null
              }
            />
          ) : (
            <NotFound />
          )
        }
      />

      {currentAction === ConfigurationActions.Manage && (
        <ManageConfigurations
          configurations={possibleConfigurationsToManage}
          open
          company={company}
          onClose={onModalClose}
        />
      )}

      {currentAction === ConfigurationActions.Import && <ImportSetting open company={company} onClose={onModalClose} />}

      {currentAction === ConfigurationActions.Create && (
        <CreateConfiguration
          open
          onClose={async () => {
            onModalClose();

            await getQuota();
            mutateConfigurationsToExport();
          }}
          companyId={company.id}
          availableConfigurationsTypes={availableConfigurationsTypes}
          canSelectLegalFramework={company.canSelectLegalFramework}
          allowedToCreateConfigByQuota={allowedToCreateConfigByQuota}
          maxConfigsAllowed={maxConfigsAllowed}
          hasConfigurations={Boolean(configurations?.body?.length)}
        />
      )}
    </Container>
  );
};

export default Configurations;
