import { Auth0Provider } from '@auth0/auth0-react';
import Cookies from 'js-cookie';
import { createRoot } from 'react-dom/client';
import TagManager from 'react-gtm-module';
import { BrowserRouter as Router } from 'react-router-dom';

import AmplitudeProvider from 'lib/contexts/Amplitude';
import App from 'pages/App';

import { getAuthorizationParams } from './lib/helpers/getAuthorizationParams';
import ThemeProvider from './ThemeProvider';

import 'antd/dist/reset.css';
import './auth0.css';

console.log(
  `%cUsercentrics - Account Management - v${process.env.REACT_APP_VERSION}`,
  'color:#1d60b3;font-size:14px;font-weight:bold',
);

const gtmId = process.env.REACT_APP_GOOGLE_TAG_MANAGER || '';
const params: URLSearchParams = new URL(window.location.href).searchParams;

const convertAndSetCookie = (glValue: string, identifier: 'aw' | 'au') => {
  const regex = new RegExp('\\*_gcl_' + identifier + '\\*([^*]+)');
  const partMatch = glValue.match(regex);
  if (partMatch && partMatch[1]) {
    let encodedString = partMatch[1];
    while (encodedString.endsWith('.')) {
      encodedString = encodedString.slice(0, -1); // Remove trailing dot if present
    }
    const decodedString = decodeURIComponent(escape(atob(encodedString)));
    Cookies.set(`_gcl_${identifier}`, decodedString, {
      path: '/',
      secure: true,
      domain: `.${location.hostname.split('.').reverse()[1]}.${location.hostname.split('.').reverse()[0]}`,
    });

    return decodedString;
  }

  return undefined;
};

const glValue = params.get('_gl') || Cookies.get('_gl');

if (glValue) {
  Cookies.set('_gl', glValue, {
    domain: `.${location.hostname.split('.').reverse()[1]}.${location.hostname.split('.').reverse()[0]}`,
  });
  convertAndSetCookie(glValue, 'aw');
  convertAndSetCookie(glValue, 'au');
}

TagManager.initialize({ gtmId });

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

root.render(
  <Auth0Provider
    domain={`${process.env.REACT_APP_AUTH0_DOMAIN}`}
    clientId={`${process.env.REACT_APP_AUTH0_CLIENT_ID}`}
    authorizationParams={await getAuthorizationParams()}
  >
    <ThemeProvider>
      <AmplitudeProvider>
        <Router basename={process.env.PUBLIC_URL}>
          <App />
        </Router>
      </AmplitudeProvider>
    </ThemeProvider>
  </Auth0Provider>,
);
