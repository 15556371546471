import { useAuth0 } from '@auth0/auth0-react';
import { Flex } from 'antd';
import { useState } from 'react';

import { Permission } from 'api/requests/generated/generated.schemas';
import { Person as PersonIcon } from 'components/Icons';
import PermissionMenu from 'components/Menu/PermissionMenu';
import DeleteAdmin from 'components/Modal/DeleteAdmin';
import { PermissionTag } from 'components/Tag';
import { Text } from 'components/Typography';
import { parsePermissionLabel } from 'lib/helpers/parsePermissionLabel';

import { RowWrapper } from './styled';

interface IPermissionRowProps {
  login: string;
  permission: Permission;
  isSettingPermissions?: boolean;
  isCompanyLevel?: boolean;
  inherited?: boolean;
  disableMenu?: boolean;
  disableRow?: boolean;
  isCSA?: boolean;
  isReviewMode?: boolean;
  style?: object;
  hideMenu?: boolean;
  onRemove?: (login: string) => void;
  onUpdate?: (login: string, newPermission: Permission) => void;
}

const PermissionRow = ({
  login,
  permission,
  inherited,
  disableMenu,
  disableRow,
  isCSA = false,
  hideMenu = false,
  isReviewMode = false,
  onRemove = () => null,
  onUpdate = () => null,
  ...props
}: IPermissionRowProps) => {
  const { user } = useAuth0();
  const [isDeleteAdminOpen, setIsDeleteAdminOpen] = useState(false);
  const userIsAdmin = !isCSA && user?.email === login && permission === Permission.ADMIN;

  return (
    <RowWrapper disableRow={disableRow} {...props} data-testid={`permission-row[${login}]`}>
      <Flex gap={10}>
        <PersonIcon />
        <Text>{login}</Text>
      </Flex>
      <Flex align="center">
        <PermissionTag data-testid="label:permission" color={isReviewMode ? 'purple' : ''}>
          {parsePermissionLabel(permission)}
        </PermissionTag>
        {!inherited && !hideMenu && (
          <PermissionMenu
            permission={permission}
            isReviewMode={isReviewMode}
            onRemove={() => (isCSA && permission === 'ADMIN' ? setIsDeleteAdminOpen(true) : onRemove(login))}
            onUpdate={(newPermission: Permission) => onUpdate(login, newPermission)}
            // Admin users cannot delete or change their permissions
            reasonOfDisabled="You cannot revoke your own permissions"
            disabled={userIsAdmin || disableMenu}
          />
        )}
      </Flex>
      <DeleteAdmin
        onDelete={async () => onRemove(login)}
        open={isDeleteAdminOpen}
        onClose={() => setIsDeleteAdminOpen(false)}
      />
    </RowWrapper>
  );
};

export default PermissionRow;
