import { Typography } from 'antd';
import { useState } from 'react';

import Modal from 'components/Modal/Modal';
import useMessage from 'lib/hooks/useMessage';

import { PartnerAccessInformation, PartnerRequestType } from '../PartnerAccess';

const { Paragraph } = Typography;

interface IPartnerAccessRequestProps {
  partnerAccessInfo: PartnerAccessInformation;
  open: boolean;
  onClose: () => void;
}

const PartnerAccessModal = ({ open, onClose, partnerAccessInfo }: IPartnerAccessRequestProps) => {
  const isApprovalRequest = partnerAccessInfo.typeOfRequest === PartnerRequestType.APPROVAL;
  const modalTextDescription = isApprovalRequest
    ? `${partnerAccessInfo.partnerName} will be able to view and modify CMP configurations, create new companies, and configure geolocation rulesets.`
    : `${partnerAccessInfo.partnerName} will no longer view or change your settings, set up new companies, or adjust geolocation rulesets.`;

  const [loading, setLoading] = useState(false);
  const message = useMessage();

  const onApproveClick = async () => {
    try {
      setLoading(true);
      console.error('Approve Partner Access Request');
      // await attachSettingsToSubscription(subscription.id, { settingIds: selectedConfigurations });
    } catch (error) {
      message.error(error.response?.data.error?.msg);
    } finally {
      setLoading(false);
      onClose();
    }
  };

  const declinePartnerAccessRequest = () => {
    try {
      console.error('Decline Partner Access Request');
    } catch (error) {
      message.error(error.response?.data.error?.msg);
    } finally {
      setLoading(false);
      onClose();
    }
  };

  const revokePartnerAccessRequest = () => {
    try {
      console.error('Revoke Partner Access Request');
    } catch (error) {
      message.error(error.response?.data.error?.msg);
    } finally {
      setLoading(false);
      onClose();
    }
  };

  return (
    <Modal
      open={open}
      title={partnerAccessInfo.modalTitle}
      primaryButton={{
        label: isApprovalRequest ? 'Approve' : 'Revoke',
        'data-testid': 'button:assign-to-subscription',
        loading,
        onClick: isApprovalRequest ? onApproveClick : revokePartnerAccessRequest,
      }}
      secondaryButton={{
        label: isApprovalRequest ? 'Decline' : 'Cancel',
        onClick: isApprovalRequest ? declinePartnerAccessRequest : onClose,
      }}
      width={740}
      onClose={onClose}
    >
      <Paragraph>{modalTextDescription}</Paragraph>
    </Modal>
  );
};

export default PartnerAccessModal;
