import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Divider, Flex, Spin } from 'antd';
import { useEffect, useRef, useState } from 'react';

import { useRecommendedPricePlan } from 'api/requests';
import {
  RecommendedPricePlanBySubscriptionEnrichedOutDto,
  SubscriptionPricePlan,
  SubscriptionStatus,
} from 'api/requests/generated/generated.schemas';
import Button from 'components/Button';
import { Title, Text, Link } from 'components/Typography';
import { TrackingEvents, TrackingVariables } from 'interfaces/enums';
import { trackEventAll } from 'lib/helpers/trackEventAll';
import { useManageSubscription } from 'lib/hooks';

import PricePlanCard from './PricePlanCard';
import { Container, Content, SwiperContainer } from './styled';

const SCROLL_STEP = 550;

interface PricePlanSelectionProps {
  onUpgrade: (plan: RecommendedPricePlanBySubscriptionEnrichedOutDto) => Promise<void>;
}

const PricePlanSelection = ({ onUpgrade }: PricePlanSelectionProps) => {
  const swiperRef = useRef<HTMLElement>(null);
  const [disabledButton, setDisabledButton] = useState<'next' | 'prev' | null>('prev');
  const [showScrollButtons, setShowScrollButtons] = useState(false);
  const [isPageLandingTracked, setIsPageLandingTracked] = useState(false);
  const { subscription } = useManageSubscription();

  const { data, isLoading } = useRecommendedPricePlan(
    subscription?.id || '',
    {
      quantity: subscription?.package.includes('Trial') ? 1 : subscription?.maxValue || subscription?.quantity || 1,
    },
    { swr: { enabled: Boolean(subscription?.id) && Boolean(subscription?.quantity) } },
  );

  useEffect(() => {
    const checkScroll = () => {
      const container = swiperRef.current;

      if (container) {
        const hasHorizontalScroll = container.scrollWidth > container.clientWidth;

        setShowScrollButtons(hasHorizontalScroll);
      }
    };

    checkScroll();

    window.addEventListener('resize', checkScroll);

    return () => {
      window.removeEventListener('resize', checkScroll);
    };
  }, [swiperRef.current]);

  const handleScrollButtons = (direction: 'next' | 'prev') => {
    if (swiperRef && swiperRef.current) {
      if (direction === 'next') {
        trackEventAll(TrackingEvents.CAROUSEL_ARROW_CLICKED, { [TrackingVariables.MOVEMENT]: 'right' });

        swiperRef.current.scrollLeft += SCROLL_STEP;

        if (
          swiperRef.current.scrollWidth - swiperRef.current.scrollLeft - SCROLL_STEP <=
          swiperRef.current.clientWidth
        ) {
          setDisabledButton('next');
          return;
        }
      }

      if (direction === 'prev') {
        trackEventAll(TrackingEvents.CAROUSEL_ARROW_CLICKED, { [TrackingVariables.MOVEMENT]: 'left' });

        swiperRef.current.scrollLeft -= SCROLL_STEP;

        if (swiperRef.current.scrollLeft - SCROLL_STEP <= 0) {
          setDisabledButton('prev');
          return;
        }
      }

      setDisabledButton(null);
    }
  };

  const pricePlanRecommendation = data?.body;
  const recommendedPricePlans = pricePlanRecommendation?.recommendedPricePlans || [];

  const isAllowedToDowngrade =
    subscription?.pricePlan === SubscriptionPricePlan.FreeExtended
      ? subscription?.status !== SubscriptionStatus.active
      : recommendedPricePlans?.[0]?.pricePlan === SubscriptionPricePlan.FreeExtended;

  const isTrialOrExpiredTrial =
    subscription?.status === SubscriptionStatus.in_trial ||
    (subscription?.trialFinishedAt && subscription?.trialFinishedAt === subscription?.initialTrialFinishedAt);

  const pricePlans = isTrialOrExpiredTrial
    ? recommendedPricePlans.slice(isAllowedToDowngrade ? 1 : 0)
    : recommendedPricePlans.slice(recommendedPricePlans.findIndex((el) => el.isCurrent) + 1);

  useEffect(() => {
    if (recommendedPricePlans?.length && !isPageLandingTracked) {
      setIsPageLandingTracked(true);

      trackEventAll(TrackingEvents.SELECT_A_PLAN_PAGE_VISITED, {
        [TrackingVariables.NUMBER_PLANS]: pricePlans?.length,
        [TrackingVariables.FORM_STEP]: 1,
        [TrackingVariables.FORM_STEP_NAME]: 'Plan Page',
        [TrackingVariables.FORM_NAME]: 'Get Full Access',
      });
    }
  }, [pricePlans?.length]);

  const onDowngradeToFreePlanClick = async () => {
    trackEventAll(TrackingEvents.DOWNGRADE_TO_FREE_PLAN_CLICKED);

    await onUpgrade(recommendedPricePlans[0]);
  };

  if (!recommendedPricePlans.length) {
    return null;
  }

  return (
    <Container>
      <Content vertical gap={24} justify="center">
        {isLoading || !pricePlanRecommendation ? (
          <Spin />
        ) : (
          <>
            <Flex justify="space-between">
              <Title level={2}>Select a plan</Title>
              {showScrollButtons && (
                <Flex gap={8}>
                  <Button disabled={disabledButton === 'prev'} onClick={() => handleScrollButtons('prev')}>
                    <ArrowLeftOutlined />
                  </Button>
                  <Button disabled={disabledButton === 'next'} onClick={() => handleScrollButtons('next')}>
                    <ArrowRightOutlined />
                  </Button>
                </Flex>
              )}
            </Flex>
            {pricePlans.length > 0 ? (
              <>
                <SwiperContainer gap={16} ref={swiperRef}>
                  {pricePlans.map((plan) => (
                    <PricePlanCard currency={pricePlanRecommendation.currencyCode} key={plan.itemPriceId} plan={plan} />
                  ))}
                </SwiperContainer>
                {isAllowedToDowngrade && (
                  <>
                    <Divider>
                      <Text size="sm" weight={700} type="secondary">
                        OR
                      </Text>
                    </Divider>
                    <Flex justify="center">
                      <Text type="secondary">
                        Downgrade to our{' '}
                        <Link onClick={onDowngradeToFreePlanClick} weight={500}>
                          Free Plan
                        </Link>
                      </Text>
                    </Flex>
                  </>
                )}
              </>
            ) : (
              <Text>No price plans available.</Text>
            )}
          </>
        )}
      </Content>
    </Container>
  );
};

export default PricePlanSelection;
