import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import ContentFooter from 'components/Footer';
import Header from 'components/Header';
import UpgradePlan from 'components/Modal/UpgradePlan';
import withMobileView from 'hoc/withMobileView';
import { usePrivileges, useUpgradePlan, useTrial } from 'lib/hooks';

import { Layout, Content } from './styled';

const BillingLayout = () => {
  const { subscription, setPlanToUpgrade } = useUpgradePlan();
  const { hasAccounts, isLoading } = usePrivileges();
  const { expiredTrial } = useTrial();
  const navigate = useNavigate();

  useEffect(() => {
    if (expiredTrial || (!hasAccounts && !isLoading)) {
      navigate('/');
    }
  }, [expiredTrial]);

  return (
    <Layout>
      {subscription && <UpgradePlan open onClose={() => setPlanToUpgrade(null)} />}
      <Header isBillingHeader />
      <Layout>
        <Content style={{ margin: '30px auto' }}>
          <Outlet />
          <ContentFooter />
        </Content>
      </Layout>
    </Layout>
  );
};

export default withMobileView(BillingLayout);
