import { track } from '@amplitude/analytics-browser';

import { TrackingEvents, TrackingVariables } from 'interfaces/enums';

export const trackAmplitudeEvent = (
  eventName: TrackingEvents,
  properties?: Partial<Record<TrackingVariables, string | boolean | number>>,
  callback?: Function,
) => {
  track(eventName, { ...properties, version: process.env.REACT_APP_VERSION }).promise.then(() => {
    callback?.();
  });
};
