import { createContext, useState } from 'react';
import { Outlet } from 'react-router-dom';

import { useGetPrivileges } from 'api/requests';
import { Permission } from 'api/requests/generated/generated.schemas';

const LOCAL_STORAGE_KEY = 'ucCSA';

export const getCSA = (): boolean => {
  try {
    const csa = localStorage.getItem(LOCAL_STORAGE_KEY);
    return csa === '1';
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn("Couldn't get CSA from local storage", error);
  }

  return false;
};

export const updateCSA = (value: boolean) => {
  try {
    localStorage.setItem(LOCAL_STORAGE_KEY, value ? '1' : '0');
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn("Couldn't save CSA to local storage", error);
  }
};

interface IPrivilegesContext {
  isCSA: boolean;
  isSupport: boolean;
  superAdminPermission: Permission;
  hasAccounts: boolean;
  hasCompanies: { direct: number; indirect: number };
  hasSettings: {
    modern: {
      assigned: number;
      unassigned: number;
    };
    legacy: number;
  };
  isLoading: boolean;
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  toggleCSA: () => void;
}

export const PrivilegesContext = createContext<IPrivilegesContext>({
  isCSA: false,
  isSupport: false,
  superAdminPermission: Permission.NONE,
  hasAccounts: false,
  hasCompanies: { direct: 0, indirect: 0 },
  hasSettings: {
    modern: {
      assigned: 0,
      unassigned: 0,
    },
    legacy: 0,
  },
  isLoading: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  toggleCSA: () => {},
});

const PrivilegesProvider = () => {
  const [isCSA, setIsCSA] = useState(() => getCSA());
  const { data, isLoading } = useGetPrivileges();

  if (!data) {
    return null;
  }

  const { hasSettings, hasAccounts, isSupport, hasCompanies, superAdminPermission } = data?.body;

  const toggleCSA = (status?: boolean) => {
    const newValue = typeof status === 'boolean' ? status : !isCSA;
    updateCSA(newValue);
    setIsCSA(newValue);
  };

  return (
    <PrivilegesContext.Provider
      value={{ isCSA, isSupport, hasAccounts, hasSettings, toggleCSA, isLoading, hasCompanies, superAdminPermission }}
    >
      <Outlet />
    </PrivilegesContext.Provider>
  );
};

export default PrivilegesProvider;
