import { Flex } from 'antd';

import { Desktop } from 'components/Icons';
import Logo from 'components/Logo';
import { Text, Title } from 'components/Typography';
import { colors } from 'lib/theme/colors';

import { Content, Header, IconWrapper, StyledLayout } from './styled';

const MainMobileView = () => (
  <StyledLayout>
    <Header>
      <Flex vertical gap={40}>
        <Flex justify="center">
          <Logo />
        </Flex>

        <Flex vertical gap={10}>
          <Text style={{ color: colors.primary }} strong>
            Get started
          </Text>
          <Title $noMargin ellipsis>
            Welcome to Usercentrics
          </Title>
        </Flex>
      </Flex>
    </Header>
    <Content>
      <Flex vertical gap={20} align="center">
        <IconWrapper justify="center" align="center">
          <Desktop />
        </IconWrapper>
        <Flex vertical gap={8}>
          <Title align="center" $noMargin>
            Sorry, we currently don’t support mobile devices
          </Title>
          <Text align="center">
            Please login and continue your onboarding on a desktop device for a seamless experience.
          </Text>
        </Flex>
      </Flex>
    </Content>
  </StyledLayout>
);

export default MainMobileView;
