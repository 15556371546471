import React from 'react';
import { useMediaQuery } from 'react-responsive';

import MainMobileView from 'components/MobileView/Main';

const withMobileView =
  <P extends object>(WrappedComponent: React.ComponentType<P>) =>
  (props: P) => {
    const isBigScreen = useMediaQuery({
      query: '(min-width: 1024px)',
    });

    return (
      <>
        <div
          style={{
            display: isBigScreen ? 'block' : 'none',
          }}
        >
          <WrappedComponent {...props} />
        </div>
        {!isBigScreen && <MainMobileView />}
      </>
    );
  };

export default withMobileView;
