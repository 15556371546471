import { Collapse } from 'antd';
import styled from 'styled-components';

export const ItemsContainer = styled.div`
  border: 1px solid ${(props) => props.theme.colors.blue[16]};
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 4px;
  padding-bottom: 24px;
  border-right: 1px solid ${(props) => props.theme.colors.blue[16]};
  height: 500px;
  overflow-y: scroll;

  &::after {
    content: '';
    height: 100px;
    width: 29%;
    position: absolute;
    bottom: 15px;
    width: calc(100% - 65px);
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 72.36%);
    pointer-events: none;
  }
`;

export const StyledCollapse = styled(Collapse)`
  & .ant-collapse-content {
    padding: 0 24px 24px 24px;
  }

  &&&& .ant-collapse-expand-icon {
    padding-inline-start: 0px;
    padding-inline-end: 0px;
    padding: 12px 6px;
    border-radius: 4px;
    border: 1px solid ${(props) => props.theme.colors.gray[50]};
  }

  & .ant-collapse-item {
    border-bottom: 1px solid #0505050f;
  }

  & .ant-collapse-item-disabled {
    .ant-collapse-header {
      cursor: default;
      color: ${(props) => props.theme.colors.primaryText};
    }
  }

  && .ant-collapse-header {
    padding: 0;
    margin: 24px 24px 24px 0;
    font-size: 16px;
  }
`;
