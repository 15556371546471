import styled from 'styled-components';

export const InvitedUserBox = styled.p`
  border: 1px solid ${(props) => props.theme.colors.gray[50]};
  color: ${(props) => props.theme.colors.gray[80]};
  background: none;
  border-radius: 4px;
  padding: 10px 12px;
  margin: 0;
`;
