import FirstLayer from './FirstLayer';
import SecondLayer from './SecondLayer';
import TabTemplate from '../../TabTemplate';

const LayoutTab = () => {
  return (
    <TabTemplate
      items={[
        {
          title: 'First Layer',
          key: 'first-layer',
          content: <FirstLayer />,
        },
        {
          title: 'Second Layer',
          key: 'second-layer',
          content: <SecondLayer />,
        },
      ]}
    />
  );
};

export default LayoutTab;
