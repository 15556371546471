import {
  AppDtoPlatform,
  CreateSettingBodyButtonPrivacyVariant,
  CreateSettingBodyFirstLayerVariant,
  LegalFramework,
  Permission,
} from 'api/requests/generated/generated.schemas';

export interface ITrialDetailsForm {
  companyName: string;
  domain?: string;
  bundleId?: string;
  platform?: AppDtoPlatform;
  framework: LegalFramework;
  country: string;
}

export interface ITrialCustomisationForm {
  firstLayerVariant: CreateSettingBodyFirstLayerVariant;
  buttonPrivacyVariant: CreateSettingBodyButtonPrivacyVariant;
  buttonBackgroundColor: string;
  buttonTextColor: string;
}

export enum Breadcrumbs {
  WELCOME = 'Welcome',
  CREATE_CONSENT = 'Create Consent',
  CONSENT_CUSTOMIZATION = 'Consent Customization',
  IMPLEMENTATION = 'Implementation',
  INVITES = 'Invite team',
}

export interface IInvitesForm {
  rows: Array<{
    email: string;
    permission: Permission;
  }>;
}
