import { LegalFramework } from 'api/requests/generated/generated.schemas';

const gdprDescription =
  'This tool helps you manage consent to third party technologies collecting and processing personal data.';
const tcfDescription =
  'You can access more detailed information below regarding all purposes and third-party vendors. You can adjust your privacy settings based on specific purposes and/or at a vendor level at any time.';
const usaDescription =
  'Here you can find detailed information about the categories of personal information we collect and the purposes for which information may be used and which Data Processing Services may have access to this information.';

const gdprButtons = ['Save Settings', 'Deny', 'Accept All'];
const tcfButtons = ['Save Settings', 'Accept All'];
const usaButtons = ['OK'];

export const getInfoBySelectedFramework = (framework: LegalFramework) => {
  if (framework === LegalFramework.GDPR) {
    return { description: gdprDescription, buttons: gdprButtons };
  }

  if (framework === LegalFramework.TCF2) {
    return { description: tcfDescription, buttons: tcfButtons };
  }

  return { description: usaDescription, buttons: usaButtons };
};
