import { Flex, Row } from 'antd';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { useGetPermission } from 'api/requests';
import Button from 'components/Button';
import Card from 'components/Card';
import { Person } from 'components/Icons';
import PageInfo from 'components/PageInfo';
import Spin from 'components/Spin';
import Tag from 'components/Tag';
import { Text, Title } from 'components/Typography';
import { parsePermissionLabel } from 'lib/helpers/parsePermissionLabel';
import { useSelectedCompany } from 'lib/hooks';
import useAmplitude from 'lib/hooks/useAmplitude';
import { Container } from 'shared-components';

import { ManageAccess } from './Modals';
import { PermissionItem } from './styled';

const UserManagement = () => {
  const company = useSelectedCompany();
  const { companyId } = useParams() as { companyId: string };
  const [isManageAccessOpen, setIsManageAccessOpen] = useState(false);
  const { deviceId } = useAmplitude();
  const { data: fetchedPermissions, mutate, isLoading } = useGetPermission(companyId);

  const permissions = fetchedPermissions?.body || [];

  const onModalClose = async () => {
    setIsManageAccessOpen(false);
    mutate();
  };

  if (isLoading) {
    return <Spin />;
  }

  if (!company || !permissions) {
    return null;
  }

  return (
    <Container minWidth={750}>
      <Flex align="start" justify="space-between">
        <PageInfo
          title="User Management"
          description={
            <>
              Users added to the company can access all configurations. Learn more in our{' '}
              <a
                href={`https://docs.usercentrics.com/#/account-interface?id=manage-users-and-permissions&deviceId=${deviceId}`}
                target="_blank"
              >
                documentation.
              </a>
            </>
          }
        />
      </Flex>
      <Card
        title={
          <Title $noMargin level={3}>
            Company Access
          </Title>
        }
        extra={
          <>
            <Button size="large" onClick={() => setIsManageAccessOpen(true)} data-testid="button:manage-permissions">
              Manage Access
            </Button>
            <ManageAccess open={isManageAccessOpen} onClose={onModalClose} company={company} />
          </>
        }
        styles={{
          header: {
            padding: '16px 25px',
          },
        }}
      >
        <Row gutter={[0, 10]}>
          {permissions.map((permission) => (
            <PermissionItem span={24} key={permission.login}>
              <Flex justify="space-between" align="center">
                <Flex gap={8}>
                  <Person />
                  <Text>{permission.login}</Text>
                </Flex>
                <Tag color="default" $bolder bordered size="medium">
                  {parsePermissionLabel(permission.permission)}
                </Tag>
              </Flex>
            </PermissionItem>
          ))}
        </Row>
      </Card>
    </Container>
  );
};

export default UserManagement;
