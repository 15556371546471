import { createContext, Dispatch, SetStateAction, useState } from 'react';

import {
  LayoutDto,
  LayoutDtoFirstLayerVariant,
  LegalFramework,
  PrivacyTriggerDto,
  PrivacyTriggerDtoButtonPrivacyVariant,
  SecondLayerDTOVariant,
  StylingDto,
} from 'api/requests/generated/generated.schemas';
import { colors } from 'lib/theme/colors';

interface ICustomizationDetails {
  layout: LayoutDto;
  styling: StylingDto;
  privacyTrigger: PrivacyTriggerDto;

  isFirstLayerOpen: boolean;
  isSecondLayerOpen: boolean;
  setIsFirstLayerOpen: Dispatch<SetStateAction<boolean>>;
  setIsSecondLayerOpen: Dispatch<SetStateAction<boolean>>;
}

interface IOnboardingContext {
  customizationDetails: ICustomizationDetails;
  setCustomizationDetails: Dispatch<SetStateAction<ICustomizationDetails>>;

  selectedFramework: LegalFramework;
  setSelectedFramework: Dispatch<SetStateAction<LegalFramework>>;
  resetCustomizationDetails: () => void;
}

const initialLayoutValues: LayoutDto = {
  firstLayerVariant: LayoutDtoFirstLayerVariant.WALL,
  secondLayerVariant: {
    variant: SecondLayerDTOVariant.CENTER,
  },
};

const initialStylingValues: StylingDto = {
  useOverlay: true,
  overlay: colors.primaryText,
  overlayOpacity: 0.7,

  buttonBackgroundColor: colors.primary,
  buttonTextColor: colors.white,

  layerBackground: colors.white,
  linkFont: colors.primaryText,
  secondLayerTab: colors.primaryText,
  tabsBorderColor: colors.primary,
  text: colors.primaryText,

  toggleActiveBackground: colors.primary,
  toggleActiveIcon: colors.white,
  toggleDisabledBackground: colors.primary,
  toggleDisabledIcon: colors.white,
  toggleInactiveBackground: colors.primary,
  toggleInactiveIcon: colors.white,
};

const initialPrivacyTriggerValues: PrivacyTriggerDto = {
  buttonPrivacyVariant: PrivacyTriggerDtoButtonPrivacyVariant.fingerprint,
  privacyButtonBackgroundColor: colors.primary,
  privacyButtonIconColor: colors.white,
  privacyButtonSizeDesktop: 64,
  privacyButtonSizeMobile: 44,
};

const initialContextValues: IOnboardingContext = {
  customizationDetails: {
    layout: initialLayoutValues,
    privacyTrigger: initialPrivacyTriggerValues,
    styling: initialStylingValues,
    isFirstLayerOpen: true,
    isSecondLayerOpen: false,
    setIsFirstLayerOpen: () => null,
    setIsSecondLayerOpen: () => null,
  },
  setCustomizationDetails: () => null,
  selectedFramework: LegalFramework.GDPR,
  setSelectedFramework: () => null,
  resetCustomizationDetails: () => null,
};

export const OnboardingContext = createContext<IOnboardingContext>(initialContextValues);

export const OnboardingContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [customizationDetails, setCustomizationDetails] = useState(initialContextValues.customizationDetails);
  const [selectedFramework, setSelectedFramework] = useState<LegalFramework>(LegalFramework.GDPR);
  const [isFirstLayerOpen, setIsFirstLayerOpen] = useState(initialContextValues.customizationDetails.isFirstLayerOpen);
  const [isSecondLayerOpen, setIsSecondLayerOpen] = useState(
    initialContextValues.customizationDetails.isSecondLayerOpen,
  );

  const resetCustomizationDetails = () => {
    setCustomizationDetails(initialContextValues.customizationDetails);
  };

  return (
    <OnboardingContext.Provider
      value={{
        customizationDetails: {
          ...customizationDetails,
          isFirstLayerOpen,
          isSecondLayerOpen,
          setIsFirstLayerOpen,
          setIsSecondLayerOpen,
        },
        setCustomizationDetails,
        selectedFramework,
        setSelectedFramework,
        resetCustomizationDetails,
      }}
    >
      {children}
    </OnboardingContext.Provider>
  );
};
