import { Col, Flex, Modal, Row, Tabs } from 'antd';
import styled from 'styled-components';

export const StyledModal = styled(Modal)`
  overflow: auto;

  & .ant-modal-content {
    border-radius: 4px;
    padding: 0;
    min-height: 700px;
  }

  max-height: 95%;
`;

export const HeaderContainer = styled(Flex)`
  padding: 24px 32px 0 32px;
`;

export const StyledTabs = styled(Tabs)`
  & .ant-tabs-content {
    background-color: ${(props) => props.theme.colors.blue[4]};
    border-radius: 0 0 4px 4px;
    padding: 32px 32px 14px 32px;
  }
`;

export const Footer = styled(Row)`
  padding: 24px 32px;
  width: 100%;
  background-color: ${(props) => props.theme.colors.white};
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
`;

export const FooterContainer = styled.div`
  background-color: ${(props) => props.theme.colors.blue[4]};
  padding: 0 32px 32px;
`;

export const PreviewContainer = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: ${(props) => props.theme.colors.blue[4]};
  border-top: 1px solid ${(props) => props.theme.colors.gray[9]};
  margin-top: 39px;
  padding-right: 32px;
`;
