import TagManager from 'react-gtm-module';

import { TrackingEvents, TrackingVariables } from 'interfaces/enums';

import { trackAmplitudeEvent } from './trackAmplitudeEvent';

export const trackEventAll = (
  eventName: TrackingEvents,
  properties?: Partial<Record<TrackingVariables, string | boolean | number>>,
  callback?: Function,
) => {
  trackAmplitudeEvent(eventName, properties, callback);
  TagManager.dataLayer({
    dataLayer: {
      event: eventName,
      ...(properties || {}),
    },
  });
};
