import { Flex, Switch } from 'antd';
import styled from 'styled-components';

import backgroundImage from 'assets/gradient.svg';
import Button from 'components/Button';
import { Close } from 'components/Icons';
import { Text } from 'components/Typography';

export const Container = styled.div`
  height: calc(100% - 60px);
`;

export const BackgroundContainer = styled.div<{ $background?: string; $opacity?: number }>`
  width: 100%;
  height: calc(100% - 25px);
  background-color: ${(props) => props.$background || 'transparent'};
  opacity: ${(props) => props.$opacity};
  border-radius: 0px 0px 5px 5px;
`;

export const RelativeContainer = styled.div`
  position: relative;
  width: 100%;
  height: calc(100% + 15px);
  background: url(${backgroundImage});
  border-radius: 6px 6px 5px 5px;
`;

export const StyledButton = styled(Button)<{ $background?: string; $color?: string }>`
  width: 100%;
  font-size: 11px;
  background-color: ${(props) => props.$background};
  color: ${(props) => props.$color};
  padding: 12px 16px;
  border: none;

  &&&:hover {
    background-color: ${(props) => props.$background};
    color: ${(props) => props.$color};
  }
`;

export const StyledText = styled(Text)<{ $opacity?: number }>`
  font-size: 10.5px;
  opacity: ${(props) => props.$opacity ?? 1};
`;

export const StyledSwitch = styled(Switch)<{ $background?: string; $iconColor?: string }>`
  background-color: ${(props) => props.$background};
  color: ${(props) => props.$iconColor};

  & .ant-switch-handle::before {
    background-color: ${(props) => props.$iconColor};
  }

  &.ant-switch-checked {
    background-color: ${(props) => props.$background};
  }

  & .anticon {
    svg {
      fill: ${(props) => props.$iconColor};
    }
  }

  &&:hover {
    background-color: ${(props) => props.$background};
    color: ${(props) => props.$iconColor};
    opacity: 0.8;
  }
`;

export const CloseIcon = styled(Close)`
  & svg {
    width: 15px;
    height: 15px;

    & path {
      fill: ${(props) => props.color};
    }
  }
`;

export const PrivacyTriggerWrapper = styled.div<{ $background?: string }>`
  position: absolute;
  bottom: 20px;
  left: 20px;
  width: 44px;
  height: 44px;
  background: ${(props) => props.$background};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  cursor: pointer;
`;

export const BrowserHeader = styled(Flex)`
  padding: 7px;
  width: 100%;
  top: 0;
  background-color: ${(props) => props.theme.colors.gray[10]};
  border-radius: 5px 5px 0px 0px;
  height: 25px;
`;

export const BrowserUrl = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.colors.white};
  height: 14px;
  border-radius: 7px;
`;

export const CloseButton = styled(Button)`
  border: none;
  padding: 0;

  svg {
    width: 20px;
    height: 20px;
  }
`;
