import { Radio, Flex } from 'antd';
import { RadioChangeEvent } from 'antd/lib';

import { LayoutDtoFirstLayerVariant, LegalFramework } from 'api/requests/generated/generated.schemas';
import { Text } from 'components/Typography';
import useOnboarding from 'lib/hooks/useOnboarding';

import { BannerWrapper, Banner, StyledRadio } from './styled';

const firstLayerOptions = [
  {
    title: 'Privacy Wall',
    note: 'Center Placement',
    value: LayoutDtoFirstLayerVariant.WALL,
    key: LayoutDtoFirstLayerVariant.WALL,
    banner: (
      <BannerWrapper position="center">
        <Banner width="24px" height="16px" />
      </BannerWrapper>
    ),
  },
  {
    title: 'Privacy Banner',
    value: LayoutDtoFirstLayerVariant.BANNER,
    key: LayoutDtoFirstLayerVariant.BANNER,
    note: 'Bottom Placement',
    banner: (
      <BannerWrapper position="bottom">
        <Banner width="90%" height="7px" />
      </BannerWrapper>
    ),
  },
];

const FirstLayer = () => {
  const { selectedFramework, customizationDetails, setCustomizationDetails } = useOnboarding();

  const isTcf = selectedFramework === LegalFramework.TCF2;

  const options = isTcf
    ? firstLayerOptions.filter((option) => option.value !== LayoutDtoFirstLayerVariant.BANNER)
    : firstLayerOptions;

  const onChange = (e: RadioChangeEvent) => {
    setCustomizationDetails((prev) => ({
      ...prev,
      layout: { ...prev.layout, firstLayerVariant: e.target.value },
    }));
  };

  return (
    <Radio.Group value={customizationDetails.layout.firstLayerVariant} style={{ width: '100%' }}>
      <Flex vertical gap={8}>
        {options.map(({ banner, key, note, title, value }) => (
          <StyledRadio value={value} key={key} onChange={onChange}>
            {banner}
            <Flex vertical>
              <Text weight={500}>{title}</Text>
              <Text weight={400} size="sm" type="secondary">
                {note}
              </Text>
            </Flex>
          </StyledRadio>
        ))}
      </Flex>
    </Radio.Group>
  );
};

export default FirstLayer;
