import { Flex } from 'antd';
import { CSSProperties } from 'react';
import styled from 'styled-components';

import { PermissionTag } from 'components/Tag';

const Container = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.gray[4]};
  padding: 20px;
  border-radius: 4px;
`;

const Column = styled.div`
  width: 100%;

  & ul {
    margin: 0;
    margin-top: 8px;

    & li {
      color: ${({ theme }) => theme.colors.primaryText};
      font-size: 14px;
      list-style: none;

      &:before {
        content: '• ';
      }
    }
  }
`;

interface IPermissionDetailsProps {
  adminRights: string[];
  writeRights?: string[];
  readRights: string[];
  reviewRights?: string[];
  style?: CSSProperties;
}

const PermissionDetails = ({
  adminRights,
  writeRights,
  readRights,
  reviewRights,
  ...props
}: IPermissionDetailsProps) => {
  const rights = [
    {
      title: 'Admin',
      key: 'admin',
      values: adminRights,
    },
    {
      title: 'Review',
      key: 'review',
      values: reviewRights,
    },
    {
      title: 'Write',
      key: 'write',
      values: writeRights,
    },
    {
      title: 'Read',
      key: 'read',
      values: readRights,
    },
  ];

  return (
    <Container justify="space-between" gap={20} {...props}>
      {rights.map(
        (right) =>
          right.values && (
            <Column key={right.title}>
              <PermissionTag>{right.title}</PermissionTag>
              <ul>
                {right.values.map((value) => (
                  <li key={value}>{value}</li>
                ))}
              </ul>
            </Column>
          ),
      )}
    </Container>
  );
};

export default PermissionDetails;
