import { StylingDto } from 'api/requests/generated/generated.schemas';

export const layoutColors: {
  name: Partial<keyof Pick<StylingDto, 'layerBackground' | 'text' | 'linkFont' | 'secondLayerTab' | 'tabsBorderColor'>>;
  title: string;
}[] = [
  {
    name: 'layerBackground',
    title: 'Background',
  },
  {
    name: 'text',
    title: 'Text',
  },
  {
    name: 'linkFont',
    title: 'Links',
  },
  {
    name: 'secondLayerTab',
    title: 'Tabs',
  },
  {
    name: 'tabsBorderColor',
    title: 'Accent',
  },
];

export const toggleColors: {
  name: Partial<
    keyof Pick<
      StylingDto,
      | 'toggleActiveBackground'
      | 'toggleActiveIcon'
      | 'toggleInactiveBackground'
      | 'toggleInactiveIcon'
      | 'toggleDisabledBackground'
      | 'toggleDisabledIcon'
    >
  >;
  title: string;
}[] = [
  {
    name: 'toggleActiveBackground',
    title: 'Active (Background)',
  },
  {
    name: 'toggleActiveIcon',
    title: 'Active (Icon)',
  },
  {
    name: 'toggleInactiveBackground',
    title: 'Inactive (Background)',
  },
  {
    name: 'toggleInactiveIcon',
    title: 'Inactive (Icon)',
  },
  {
    name: 'toggleDisabledBackground',
    title: 'Disabled (Background)',
  },
  {
    name: 'toggleDisabledIcon',
    title: 'Disabled (Icon)',
  },
];

export const buttonColors: {
  name: Partial<keyof Pick<StylingDto, 'buttonBackgroundColor' | 'buttonTextColor'>>;
  title: string;
}[] = [
  {
    name: 'buttonBackgroundColor',
    title: 'Background',
  },
  {
    name: 'buttonTextColor',
    title: 'Text',
  },
];
