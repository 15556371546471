import { useAuth0 } from '@auth0/auth0-react';
import { Form, Flex, Typography, Radio, Row, Col } from 'antd';
import { FormInstance } from 'antd/lib';
import { memo, useEffect, useState } from 'react';

import { AppDtoPlatform, LegalFramework, SubscriptionType } from 'api/requests/generated/generated.schemas';
import Button from 'components/Button';
import { Input } from 'components/Form';
import Item from 'components/Form/Item';
import { Expand } from 'components/Icons';
import CountriesSelect from 'components/Select/CountriesSelect';
import { Title } from 'components/Typography';
import { frameworks } from 'lib/consts';
import { CURRENCY_SELECTION, getFeatureFlags } from 'lib/helpers/featureFlags';
import useOnboarding from 'lib/hooks/useOnboarding';
import { colors } from 'lib/theme/colors';
import { FrameworkItem } from 'pages/Companies/Configurations/Modals/CreateConfiguration/Steps/LegalFramework';

import PlatformSection from './Components/PlatformSection';
import { Box } from '../../styled';

const { Paragraph } = Typography;

interface IDetailsProps {
  form: FormInstance;
  setDisabled: (value: boolean) => void;
  subscriptionType: SubscriptionType;
}

const Details = memo(({ form, setDisabled, subscriptionType }: IDetailsProps) => {
  const { user } = useAuth0();
  const framework = Form.useWatch('framework', form);
  const [showMoreClicked, setShowMoreClicked] = useState(false);
  const { setSelectedFramework } = useOnboarding();
  const featureFlags = getFeatureFlags();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'auto',
    });
  }, []);

  const values = Form.useWatch([], { form, preserve: true });

  useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        if (framework) {
          setDisabled(false);
        } else {
          setDisabled(true);
        }
      },
      () => {
        setDisabled(true);
      },
    );
  }, [values, framework]);

  useEffect(() => {
    if (
      !form.getFieldValue('framework') ||
      frameworks.slice(0, 3).some((item) => item.value === form.getFieldValue('framework'))
    ) {
      setShowMoreClicked(false);
    } else {
      setShowMoreClicked(true);
    }
  }, []);

  return (
    <Form
      name="company-details"
      form={form}
      initialValues={{ companyName: '', framework: LegalFramework.GDPR, platform: AppDtoPlatform.IOS }}
      layout="vertical"
    >
      <Flex gap={24} vertical style={{ marginBottom: 70 }}>
        <Flex gap={40}>
          <Flex vertical flex="0 0 220px">
            <Title level={3} data-testid="empty-placeholder:title">
              Account Information
            </Title>
            <Paragraph type="secondary">Enter your company name.</Paragraph>
          </Flex>
          <Flex flex={1}>
            <Box style={{ width: '100%' }}>
              <Item
                label="Account Name"
                name="companyName"
                colon={false}
                rules={[{ required: true, message: 'Required field' }]}
                weight="medium"
                requiredMark={false}
              >
                <Input placeholder="Account Name" autoFocus />
              </Item>
              {(featureFlags.includes(CURRENCY_SELECTION) || !user?.one_pricing_plan) && (
                <Item
                  label="Country"
                  name="country"
                  colon={false}
                  rules={[{ required: true, message: 'Required field' }]}
                  weight="medium"
                  requiredMark={false}
                >
                  <CountriesSelect />
                </Item>
              )}
            </Box>
          </Flex>
        </Flex>
        <PlatformSection isSubscriptionTypeApp={subscriptionType === SubscriptionType.app} />
        <Flex gap={40}>
          <Flex vertical flex="0 0 220px">
            <Title level={3} data-testid="empty-placeholder:title">
              Legal Framework
            </Title>
            <Paragraph type="secondary">Select the legal framework for your consent banner.</Paragraph>
          </Flex>
          <Flex flex={1}>
            <Box style={{ width: '100%' }}>
              <Item name="framework" required>
                <Radio.Group onChange={(event) => setSelectedFramework(event.target.value)}>
                  <Row gutter={[0, 8]}>
                    {frameworks.map((item, index) => (
                      // Need to show only 3 frameworks by default
                      <Col key={item.value} span={showMoreClicked || index < 3 ? 24 : 0}>
                        <FrameworkItem
                          focused={item.value === framework}
                          framework={item}
                          premium={Boolean(item?.isPremium)}
                        />
                      </Col>
                    ))}
                  </Row>
                </Radio.Group>
              </Item>
              <Row justify="center">
                {!showMoreClicked && (
                  <Button type="link" onClick={() => setShowMoreClicked(true)}>
                    Show More <Expand style={{ margin: 0 }} color={colors.primary} />
                  </Button>
                )}
              </Row>
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </Form>
  );
});

export default Details;
