import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { Form, Checkbox, message, Typography, Switch, Collapse, Space, Flex } from 'antd';
import { useEffect, useState } from 'react';

import { disableReviewMode, enableReviewMode } from 'api/requests';
import {
  GetAllPermissionsOutDto,
  GetPermissionByCustomerIdOutDto,
  Permission,
  SetPermissionInDto,
  SettingOutDto,
} from 'api/requests/generated/generated.schemas';
import Button from 'components/Button';
import { Item, Input } from 'components/Form';
import { StyledSelect, Option } from 'components/Select/Select';
import { TrackingEvents, TrackingVariables } from 'interfaces/enums';
import { trackEventAll } from 'lib/helpers/trackEventAll';
import { emailRegexp } from 'lib/helpers/validation';

import PermissionDetails from './Details';
import PermissionRow from './Row';
import { FormContainer, NoUsers } from './styled';

const { Title, Paragraph } = Typography;

interface IPermissionsProps {
  isSettingPermissions?: boolean;
  readonly?: boolean;
  isCSA?: boolean;
  /**
   * Defines if the company have access to the review mode feature
   */
  hasCompanyReviewModeEnabled?: boolean;
  onAdd: (data: SetPermissionInDto) => Promise<void> | void;
  onRemove: (login: string) => Promise<void> | void;
  onUpdate: (login: string, newPermission: Permission) => Promise<void> | void;
  permissions?: (GetPermissionByCustomerIdOutDto | GetAllPermissionsOutDto | SetPermissionInDto)[];
  setting?: Pick<SettingOutDto, 'id' | 'permission'> | null;
}

const Permissions = ({
  permissions = [],
  onAdd,
  onRemove,
  onUpdate,
  isSettingPermissions,
  readonly,
  isCSA,
  setting = null,
  hasCompanyReviewModeEnabled = false,
}: IPermissionsProps) => {
  const [showDetails, setShowDetails] = useState(false);

  const hasReviewModeSetting = setting && 'reviewMode' in setting;
  const isReviewModeChecked = hasReviewModeSetting ? (setting.reviewMode as boolean) : false;
  const [isSettingReviewMode, setIsSettingReviewMode] = useState<boolean>(isReviewModeChecked);

  const [disabled, setDisabled] = useState(true);
  const [permissionsForm] = Form.useForm<SetPermissionInDto>();

  const inheritedPermissions = permissions.filter((permission) => 'inherited' in permission && permission.inherited);

  const directPermissions = permissions.filter((permission) => !inheritedPermissions.includes(permission));

  const description = isSettingPermissions
    ? 'Add and manage user access to for this configuration. You can extend or restrict permissions for users that have access via your company.'
    : 'Add and manage users to grant access to all configurations within your company.';
  const hasAccessToSeeReviewToggle = setting?.permission === Permission.ADMIN || isCSA;

  const permissionFormValues = Form.useWatch([], permissionsForm);

  const onAddClick = async () => {
    try {
      setDisabled(true);
      const data = await permissionsForm.validateFields();

      const user = permissions.find((p) => p.login === data?.login);

      if (user && user.permission === data.permission) {
        return message.error('User already exists');
      }

      await onAdd(data);
      permissionsForm.resetFields();

      if (!isSettingPermissions) {
        message.success(`User permissions ${user && user.permission !== data.permission ? 'updated' : 'added'}`);
      }
    } catch (e) {
      console.log('Validation error');
    } finally {
      setDisabled(false);
    }
  };

  const onUpdateClick = async (login: string, newPermission: Permission) => {
    await onUpdate(login, newPermission);
    message.success('User permissions updated');
  };

  const onReviewModeChange = async (status: boolean) => {
    if (setting) {
      if (status) {
        await enableReviewMode(setting.id);
      } else {
        await disableReviewMode(setting.id);
      }
      setIsSettingReviewMode(status);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        await permissionsForm.validateFields({ validateOnly: true });
        setDisabled(false);
      } catch (err) {
        setDisabled(true);
      }
    })();
  }, [permissionFormValues]);

  useEffect(() => {
    trackEventAll(TrackingEvents.ADD_COMPANY_MANAGE_ACCESS, {
      [TrackingVariables.FORM_STEP]: 2,
      [TrackingVariables.FORM_STEP_NAME]: 'Manage Company',
    });
  }, []);

  return (
    <>
      <Flex justify="space-between">
        <div>
          <Title level={3}>Manage {isSettingPermissions ? 'Configuration' : 'Company'} Access</Title>
          <Paragraph>{description}</Paragraph>
        </div>
        {isSettingPermissions && hasCompanyReviewModeEnabled && hasAccessToSeeReviewToggle && (
          <>
            <Paragraph>Review Mode</Paragraph>
            <Switch
              onChange={onReviewModeChange}
              defaultChecked={isReviewModeChecked}
              data-testid="toggle:review-setting"
            />
          </>
        )}
      </Flex>
      <Space direction="vertical" size="middle">
        <FormContainer>
          <Form<SetPermissionInDto>
            name="user-management"
            form={permissionsForm}
            initialValues={{ login: '', permission: Permission.READWRITE, notify: false }}
            layout="inline"
            onValuesChange={(_, data: { login: string; permission: string }) =>
              setDisabled(!data.login || !data.permission)
            }
            preserve={false}
            onFinish={onAddClick}
          >
            <Item
              colon={false}
              required
              label="Email"
              name="login"
              rules={[
                { required: true, message: 'Input required' },
                { pattern: emailRegexp, message: 'This should be a valid email address' },
                {
                  validator: (_, value) => {
                    if (directPermissions.some((permission) => permission.login === value?.trim())) {
                      // eslint-disable-next-line prefer-promise-reject-errors
                      return Promise.reject('This email is already in the list');
                    }
                    return Promise.resolve();
                  },
                },
              ]}
              style={{ width: '235px' }}
            >
              <Input placeholder="Email" disabled={readonly} data-testid="input:user-email" />
            </Item>
            <Item
              colon={false}
              required
              label="Permission"
              name="permission"
              rules={[{ required: true, message: 'Input required' }]}
              style={{ width: '235px' }}
            >
              <StyledSelect size="large" disabled={readonly} data-testid="select:user-permission">
                <Option value="READWRITE">Write</Option>
                {isSettingPermissions && isSettingReviewMode && <Option value="REVIEW">Review</Option>}
                <Option value="READ">Read</Option>
                <Option value="ADMIN">Admin</Option>
              </StyledSelect>
            </Item>
            <Item name="submit" style={{ marginTop: '40px' }}>
              <Button
                size="large"
                htmlType="submit"
                disabled={disabled || readonly}
                data-testid="button:add-permission"
                type="primary"
              >
                Add
              </Button>
            </Item>

            <Item name="notify" valuePropName="checked" style={{ width: '235px' }}>
              <Checkbox disabled={disabled} style={{ fontWeight: 400 }}>
                Notify user via email
              </Checkbox>
            </Item>
            <Button
              type="link"
              onClick={(e) => {
                e.preventDefault();
                setShowDetails(!showDetails);
              }}
            >
              See permission details
              {showDetails ? <CaretUpOutlined /> : <CaretDownOutlined />}
            </Button>

            {showDetails &&
              (isSettingPermissions ? (
                <PermissionDetails
                  adminRights={['Manage user permissions', 'Full access to this configuration']}
                  reviewRights={
                    isSettingReviewMode
                      ? ['Full access to this configuration', 'Review and publish this configuration']
                      : undefined
                  }
                  writeRights={['Full access to this configuration']}
                  readRights={['Read-only access to this configuration']}
                />
              ) : (
                <PermissionDetails
                  adminRights={[
                    'Manage user permissions',
                    'Full access to this configuration',
                    'Full access to all settings belonging to the company',
                  ]}
                  writeRights={['Full access to all settings belonging to the company']}
                  readRights={['Read-only access to all settings belonging to the company']}
                />
              ))}
          </Form>
        </FormContainer>
        {isSettingPermissions && directPermissions.length <= 0 && (
          <NoUsers data-testid="div:no-users-added">
            <Title level={4}>Configuration Access</Title>
            <div>No users added to this configuration</div>
          </NoUsers>
        )}
        {directPermissions.length > 0 && (
          <Collapse
            expandIconPosition="end"
            items={[
              {
                key: '1',
                label: (
                  <Title level={4} data-testid="collapse:direct-permissions">
                    {isSettingPermissions ? 'Configuration Access' : 'Company Access'}
                  </Title>
                ),
                children: directPermissions.map((permission) => (
                  <PermissionRow
                    key={`${permission.login}-${permission.permission}`}
                    isSettingPermissions={isSettingPermissions}
                    isCSA={isCSA}
                    isReviewMode={isSettingReviewMode}
                    disableMenu={readonly}
                    onUpdate={onUpdateClick}
                    onRemove={onRemove}
                    {...permission}
                  />
                )),
              },
            ]}
          />
        )}
        {isSettingPermissions && inheritedPermissions.length > 0 && (
          <Collapse
            expandIconPosition="end"
            items={[
              {
                key: '1',
                label: (
                  <Title level={4} data-testid="collapse:inherited-permissions">
                    Company Access
                  </Title>
                ),
                children: inheritedPermissions.map((permission) => (
                  <PermissionRow
                    key={permission.login}
                    isSettingPermissions={isSettingPermissions}
                    disableRow={directPermissions.some((dp) => dp.login === permission.login)}
                    isCompanyLevel
                    onRemove={onRemove}
                    onUpdate={onUpdateClick}
                    {...permission}
                  />
                )),
              },
            ]}
          />
        )}
      </Space>
    </>
  );
};

export default Permissions;
