import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Flex } from 'antd';

import { Text } from 'components/Typography';
import useOnboarding from 'lib/hooks/useOnboarding';

import { StyledCollapse, StyledTabs } from './styled';
import { StyledText, StyledSwitch } from '../styled';

const TcfContent = () => {
  const {
    customizationDetails: { styling },
  } = useOnboarding();

  return (
    <StyledTabs
      $textColor={styling.text}
      $accentColor={styling.tabsBorderColor}
      $backgroundColor={styling.layerBackground}
      $tabColor={styling.secondLayerTab}
      tabBarStyle={{ paddingLeft: 25, margin: 0, fontSize: 9 }}
      items={[
        {
          label: 'Vendors',
          key: 'vendors',
          children: (
            <Flex vertical gap={10}>
              <Text color={styling.text} size="sm" weight={700}>
                Vendors who are not part of the IAB TCF(1)
              </Text>
              <StyledCollapse
                $background={styling.layerBackground}
                $accentColor={styling.tabsBorderColor}
                collapsible="disabled"
                expandIconPosition="end"
                bordered={false}
                items={[
                  {
                    key: '1',
                    label: (
                      <Flex vertical>
                        <Text color={styling.text} size="sm" weight={700}>
                          Usercentrics Consent Management Platform
                        </Text>
                        <StyledText $opacity={0.7} color={styling.text}>
                          Essential
                        </StyledText>
                      </Flex>
                    ),
                    extra: (
                      <StyledSwitch
                        $background={styling.toggleActiveBackground}
                        $iconColor={styling.toggleActiveIcon}
                        checked
                        size="small"
                        onClick={(_, e) => e.stopPropagation()}
                        unCheckedChildren={<CloseOutlined />}
                        checkedChildren={<CheckOutlined />}
                      />
                    ),
                  },
                ]}
              />
              <StyledCollapse
                $background={styling.layerBackground}
                $accentColor={styling.tabsBorderColor}
                expandIconPosition="end"
                collapsible="disabled"
                bordered={false}
                items={[
                  {
                    key: '2',
                    label: (
                      <Flex vertical>
                        <Text color={styling.text} size="sm" weight={700}>
                          CMP Maximum Storage Duration
                        </Text>
                      </Flex>
                    ),
                  },
                ]}
              />
              <StyledCollapse
                $background={styling.layerBackground}
                $accentColor={styling.tabsBorderColor}
                expandIconPosition="end"
                collapsible="disabled"
                bordered={false}
                items={[
                  {
                    key: '3',
                    label: (
                      <Flex vertical>
                        <Text color={styling.text} size="sm" weight={700}>
                          ID to request consent data
                        </Text>
                      </Flex>
                    ),
                  },
                ]}
              />
            </Flex>
          ),
        },
      ]}
    />
  );
};

export default TcfContent;
