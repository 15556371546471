import { Flex, Typography } from 'antd';

import { Title } from 'components/Typography';

import { Box } from '../../../../styled';
import AppPlatform from '../AppPlatorm';
import WebPlatform from '../WebPlatform';

const { Paragraph } = Typography;

interface PlatformSection {
  isSubscriptionTypeApp: boolean;
}

export default function PlatformSection({ isSubscriptionTypeApp }: PlatformSection) {
  const title = isSubscriptionTypeApp ? 'App' : 'Website';
  const description = isSubscriptionTypeApp
    ? 'Enter the Bundle ID (iOS) or Package Name (Android) of the apps the CMP will be integrated on.'
    : "Enter the domain you want the consent banner displayed on. We'll scan your site and add all tracking technologies.";

  return (
    <Flex gap={40}>
      <Flex vertical flex="0 0 220px">
        <Title level={3}>{title}</Title>
        <Paragraph type="secondary">{description}</Paragraph>
      </Flex>
      <Flex flex={1}>
        <Box style={{ width: '100%' }}>{isSubscriptionTypeApp ? <AppPlatform /> : <WebPlatform />}</Box>
      </Flex>
    </Flex>
  );
}
