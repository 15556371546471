import { Radio, Flex, RadioChangeEvent } from 'antd';

import { SecondLayerDTOVariant, SecondLayerDTOSide, LegalFramework } from 'api/requests/generated/generated.schemas';
import { Text } from 'components/Typography';
import useOnboarding from 'lib/hooks/useOnboarding';

import { BannerWrapper, StyledRadio, Banner } from './styled';

const secondLayerOptions = [
  {
    title: 'Privacy Settings',
    note: 'Center',
    value: SecondLayerDTOVariant.CENTER,
    key: SecondLayerDTOVariant.CENTER,
    banner: (
      <BannerWrapper position="center">
        <Banner height="60%" width="40%" />
      </BannerWrapper>
    ),
  },
  {
    title: 'Privacy Settings',
    value: SecondLayerDTOSide.LEFT,
    key: SecondLayerDTOSide.LEFT,
    note: 'Left',
    banner: (
      <BannerWrapper position="left">
        <Banner height="90%" width="40%" />
      </BannerWrapper>
    ),
  },
  {
    title: 'Privacy Settings',
    value: SecondLayerDTOSide.RIGHT,
    key: SecondLayerDTOSide.RIGHT,
    note: 'Right',
    banner: (
      <BannerWrapper position="right">
        <Banner height="90%" width="40%" />
      </BannerWrapper>
    ),
  },
];

const SecondLayer = () => {
  const {
    selectedFramework,
    customizationDetails: { layout },
    setCustomizationDetails,
  } = useOnboarding();

  const isTcf = selectedFramework === LegalFramework.TCF2;

  const options = isTcf
    ? secondLayerOptions.filter((option) => option.value === SecondLayerDTOVariant.CENTER)
    : secondLayerOptions;

  const onChange = (e: RadioChangeEvent) => {
    const value = e.target.value;

    setCustomizationDetails((prev) => ({
      ...prev,
      layout: {
        ...prev.layout,
        secondLayerVariant:
          value === SecondLayerDTOVariant.CENTER
            ? { variant: value, side: undefined }
            : { variant: SecondLayerDTOVariant.SIDE, side: value },
      },
    }));
  };

  return (
    <Radio.Group
      value={
        layout.secondLayerVariant?.variant === SecondLayerDTOVariant.CENTER
          ? layout.secondLayerVariant?.variant
          : layout.secondLayerVariant?.side
      }
      style={{ width: '100%' }}
    >
      <Flex vertical gap={8}>
        {options.map(({ banner, key, note, title, value }) => (
          <StyledRadio value={value} key={key} onChange={onChange}>
            {banner}
            <Flex vertical>
              <Text weight={500}>{title}</Text>
              <Text weight={400} size="sm" type="secondary">
                {note}
              </Text>
            </Flex>
          </StyledRadio>
        ))}
      </Flex>
    </Radio.Group>
  );
};

export default SecondLayer;
