import { Flex } from 'antd';
import { useEffect } from 'react';

import { LayoutDtoFirstLayerVariant } from 'api/requests/generated/generated.schemas';
import { Dot } from 'components/DotStepper/styled';
import useOnboarding from 'lib/hooks/useOnboarding';
import { colors } from 'lib/theme/colors';

import { Wall, Banner } from './FirstLayer';
import SecondLayer from './SecondLayer';
import {
  RelativeContainer,
  BackgroundContainer,
  PrivacyTriggerWrapper,
  BrowserHeader,
  BrowserUrl,
  Container,
} from './styled';
import { privacyButtonOptions } from '../Tabs/PrivacyTrigger/PrivacyButton';

const Preview = () => {
  const {
    customizationDetails: {
      styling,
      layout,
      privacyTrigger,
      isFirstLayerOpen,
      isSecondLayerOpen,
      setIsFirstLayerOpen,
      setIsSecondLayerOpen,
    },
  } = useOnboarding();

  useEffect(() => {
    setIsFirstLayerOpen(false);
    setIsSecondLayerOpen(true);
  }, [
    styling.toggleActiveIcon,
    styling.toggleActiveBackground,
    styling.toggleDisabledBackground,
    styling.toggleDisabledIcon,
    styling.toggleInactiveBackground,
    styling.toggleInactiveIcon,
    styling.tabsBorderColor,
    styling.secondLayerTab,
    layout.secondLayerVariant,
  ]);

  useEffect(() => {
    setIsSecondLayerOpen(false);
    setIsFirstLayerOpen(true);
  }, [layout.firstLayerVariant]);

  return (
    <Container>
      <RelativeContainer>
        <BrowserHeader align="center" gap={20}>
          <Flex gap={4}>
            <Dot color={`${colors.black}1a`} />
            <Dot color={`${colors.black}1a`} />
            <Dot color={`${colors.black}1a`} />
          </Flex>
          <BrowserUrl />
        </BrowserHeader>
        <BackgroundContainer
          $background={styling.useOverlay ? styling.overlay : undefined}
          $opacity={styling.overlayOpacity}
        />

        {isFirstLayerOpen && (
          <>
            {layout.firstLayerVariant === LayoutDtoFirstLayerVariant.WALL && <Wall />}
            {layout.firstLayerVariant === LayoutDtoFirstLayerVariant.BANNER && <Banner />}
          </>
        )}

        {isSecondLayerOpen && <SecondLayer />}

        {!isFirstLayerOpen && !isSecondLayerOpen && (
          <PrivacyTriggerWrapper
            $background={privacyTrigger.privacyButtonBackgroundColor}
            onClick={() => setIsFirstLayerOpen(true)}
          >
            {privacyButtonOptions
              .find((privacyButton) => privacyButton.value === privacyTrigger.buttonPrivacyVariant)
              ?.icon(privacyTrigger.privacyButtonIconColor as string)}
          </PrivacyTriggerWrapper>
        )}
      </RelativeContainer>
    </Container>
  );
};

export default Preview;
