import { Radio } from 'antd';
import styled from 'styled-components';

export const StyledRadio = styled(Radio.Group)`
  & .ant-radio-button-wrapper {
    margin: 8px 8px 0 0;
    border-radius: 4px;
    padding: 10px 12px;
    height: fit-content;
    line-height: 20px;
  }

  & .ant-radio-button-wrapper-checked {
    border-width: 2px;
    background: ${(props) => props.theme.colors.blue[4]};
  }
`;
