import { Radio, Row, Col, RadioChangeEvent } from 'antd';

import { CreateSettingBodyButtonPrivacyVariant } from 'api/requests/generated/generated.schemas';
import { Fingerprint, Gear, Verified } from 'components/Icons';
import { Text } from 'components/Typography';
import useOnboarding from 'lib/hooks/useOnboarding';

import { IconWrapper, StyledRadio } from './styled';

export const privacyButtonOptions = [
  {
    title: 'Fingerprint',
    key: 'fingerprint',
    value: CreateSettingBodyButtonPrivacyVariant.fingerprint,
    icon: (color: string) => <Fingerprint color={color} />,
  },
  {
    title: 'Security',
    key: 'security',
    value: CreateSettingBodyButtonPrivacyVariant.security,
    icon: (color: string) => <Verified color={color} />,
  },
  {
    title: 'Settings',
    key: 'settings',
    value: CreateSettingBodyButtonPrivacyVariant.settings,
    icon: (color: string) => <Gear color={color} />,
  },
];

const PrivacyButton = () => {
  const { setCustomizationDetails, customizationDetails } = useOnboarding();

  const onPrivacyButtonVariantChange = (event: RadioChangeEvent) => {
    setCustomizationDetails((prev) => ({
      ...prev,
      privacyTrigger: { ...prev?.privacyTrigger, buttonPrivacyVariant: event.target.value },
    }));
  };

  return (
    <Radio.Group
      onChange={onPrivacyButtonVariantChange}
      value={customizationDetails.privacyTrigger.buttonPrivacyVariant}
    >
      <Row gutter={[8, 16]} wrap>
        {privacyButtonOptions.map(({ title, icon, key, value }) => (
          <Col key={key}>
            <StyledRadio value={value}>
              <IconWrapper background={customizationDetails.privacyTrigger.privacyButtonBackgroundColor as string}>
                {icon(customizationDetails.privacyTrigger.privacyButtonIconColor as string)}
              </IconWrapper>
              <Text weight={500}>{title}</Text>
            </StyledRadio>
          </Col>
        ))}
      </Row>
    </Radio.Group>
  );
};

export default PrivacyButton;
