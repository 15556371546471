import { Col, Row, Space, Divider, Skeleton, Flex } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { asc, desc, queue, map } from 'type-comparator';

import { useGetAccount, useAccountConsumption } from 'api/requests';
import {
  AccountOutDto,
  CompanyDto,
  SubscriptionDto,
  SubscriptionStatus,
  TrialSubscriptionOutDto,
} from 'api/requests/generated/generated.schemas';
import AccountAccess from 'components/AccountAccess';
import AccountDetails from 'components/AccountDetails';
import Card from 'components/Card';
import {
  Link as LinkIcon,
  NoCard as NoCardIcon,
  Open as OpenIcon,
  PlatformIndicator as PlatformIndicatorIcon,
  BankAccount as BankAccountIcon,
} from 'components/Icons';
import SubscriptionCard from 'components/SubscriptionCard';
import Usage from 'components/SubscriptionUsage/Usage';
import Tag from 'components/Tag';
import { Text } from 'components/Typography';
import { ISettingInfo } from 'interfaces/ISetting';
import { isBetween } from 'lib/helpers';
import { usePrivileges, useSelectedAccount } from 'lib/hooks';
import { Container } from 'shared-components';

import AssignConfigurations from './Modals/AssignConfigurations';
import PartnerAccess from './PartnerAccess';
import { IconBackground } from '../../BillingAccount/styled';
import { renderPaymentSource } from '../../BillingAccount/SubComponents';

const sortStatus = ({ status }: { status: SubscriptionStatus }) => {
  switch (status) {
    case 'active':
    case 'non_renewing':
      return 1;
    case 'in_trial':
      return 2;
    case 'future':
      return 3;
    case 'cancelled':
      return 4;
    default:
      return 100;
  }
};

const comparator = queue([map(sortStatus, asc), map((x) => x.type, desc)]);

const Account = () => {
  const { setSelectedAccount } = useSelectedAccount();
  const theme = useTheme();
  const navigate = useNavigate();
  const { isCSA } = usePrivileges();
  const { accountId } = useParams();
  const [isAssignConfigurationsOpen, setIsAssignConfigurationsOpen] = useState(false);
  const [configurationsToAssign, setConfigurationsToAssign] = useState<ISettingInfo[]>([]);
  const [subscriptionToEdit, setSubscriptionToEdit] = useState<TrialSubscriptionOutDto | SubscriptionDto | null>(null);
  const { data, isLoading, mutate } = useGetAccount(accountId || '');
  const account = data?.body || null;
  const { data: consumptionData, isLoading: isConsumptionLoading } = useAccountConsumption(accountId || '', {
    periodicity: 'month',
    periods: 12,
  });
  const consumption = consumptionData?.body?.consumption || {};

  useEffect(() => {
    if (!isCSA) {
      navigate('/');
    }
  }, [isCSA]);

  useEffect(() => {
    if (account) {
      setSelectedAccount({ id: account.id, name: account.name });
    }

    return () => {
      setSelectedAccount({ id: '', name: '' });
    };
  }, [account]);

  const onAssignClick = (subscription: TrialSubscriptionOutDto | SubscriptionDto, acc: AccountOutDto) => {
    if (!acc.companies) return;

    const companiesConfigurations = acc.companies.reduce(
      (prev: ISettingInfo[], currentCompany: CompanyDto) => [
        ...prev,
        ...currentCompany.settings.map((setting) => ({
          ...setting,
          company: currentCompany.name,
        })),
      ],
      [],
    );

    const isFuture = (startDate: string, finishDate: string) => {
      const now = new Date();
      const start = new Date(startDate);
      const end = new Date(finishDate);

      return start > now && (end > now || finishDate === null);
    };

    const isCurrent = isBetween();

    const configurationsWithSubscriptions = acc.subscriptions.reduce(
      (prev: string[], current) => [
        ...prev,
        ...current.settingSubscriptions
          .filter(({ startedAt, finishedAt }) => isFuture(startedAt, finishedAt) || isCurrent(startedAt, finishedAt))
          .map(({ settingId }) => settingId),
      ],
      [],
    );

    const configurations = companiesConfigurations
      .filter(({ id }) => !configurationsWithSubscriptions.includes(id))
      .map((configuration) => ({ ...configuration, key: configuration.id }));

    setIsAssignConfigurationsOpen(true);
    setConfigurationsToAssign(configurations);
    setSubscriptionToEdit(subscription);
  };

  const onAssignClose = async () => {
    mutate();
    setIsAssignConfigurationsOpen(false);
  };

  if (isLoading) {
    return (
      <Container>
        <Skeleton.Button active block style={{ height: '400px' }} />
      </Container>
    );
  }

  if (!account) return null;

  const subscriptions = account.subscriptions.sort(comparator);

  return (
    <>
      <Card
        key={account.chargebeeCustomerId}
        data-testid={`block:account-${account.chargebeeCustomerId}`}
        title={
          <Flex align="center" gap={12}>
            <IconBackground align="center" justify="center">
              <BankAccountIcon />
            </IconBackground>
            {account.name}
          </Flex>
        }
        style={{ background: theme?.colors.gray[8], maxWidth: '900px', paddingTop: 24 }}
        styles={{
          header: { borderBottom: 'none' },
        }}
        bordered={false}
        extra={
          <Space align="center">
            <LinkIcon />
            <Text size="sm">Connected with Chargebee [{account.chargebeeCustomerId}]</Text>
          </Space>
        }
      >
        <Space direction="vertical" size="middle">
          <AccountDetails initialValues={{ ...account, email: account.user?.email }} />

          {Boolean(account?.companies?.length) && (
            <Card title="Companies">
              {account.companies.map((company) => (
                <Tag $bold={false} size="middle" key={company.id}>
                  <Link to={`/company/${company.id}/configurations`}>{company.name}</Link>
                  <OpenIcon />
                </Tag>
              ))}
            </Card>
          )}

          <Card
            title="Subscription"
            noResults={{
              description:
                'No subscription available. Valid subscriptions will be shown automatically once available in Chargebee.',
              icon: <PlatformIndicatorIcon />,
              condition: !subscriptions.length,
            }}
          >
            <Row gutter={[0, 10]}>
              {subscriptions.map((subscription, index, arr) => (
                <Row key={subscription.id}>
                  <Col span={12} style={{ paddingRight: '12px' }}>
                    <SubscriptionCard
                      subscription={subscription}
                      account={account}
                      key={subscription.id}
                      onAssignConfigurations={() => onAssignClick(subscription, account)}
                      isBillingPage
                    />
                  </Col>
                  <Col span={12} style={{ paddingLeft: '12px' }}>
                    <Usage
                      subscription={subscription}
                      account={account}
                      consumption={consumption[subscription.id]}
                      isLoading={isConsumptionLoading}
                    />
                  </Col>
                  {index !== arr.length - 1 && <Divider style={{ width: 'calc(100% + 48px)', margin: '24px -24px' }} />}
                </Row>
              ))}
            </Row>
          </Card>

          <Card
            title="Payment"
            noResults={{
              description: 'No payment method added',
              icon: <NoCardIcon />,
              condition: !account.paymentSources.length,
            }}
          >
            <Row gutter={[0, 8]}>{account.paymentSources.map(renderPaymentSource)}</Row>
          </Card>

          <AccountAccess account={account} />

          {/* Partner Request Access - Feature Flag - SANDBOX ONLY */}
          {process.env.REACT_APP_ENV !== 'production' && <PartnerAccess />}
        </Space>
      </Card>
      {subscriptionToEdit && (
        <AssignConfigurations
          open={isAssignConfigurationsOpen}
          configurations={configurationsToAssign}
          subscription={subscriptionToEdit}
          onClose={onAssignClose}
        />
      )}
    </>
  );
};

export default Account;
