import { Flex, Layout } from 'antd';
import styled from 'styled-components';

export const StyledLayout = styled(Layout)`
  background-color: ${(props) => props.theme.colors.blue[8]};
  height: 100vh;
`;

export const Header = styled(Layout.Header)`
  height: 180px;
  padding: 15px;
`;

export const Content = styled(Layout.Content)`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
  min-height: 600px;
  padding: 40px;
`;

export const IconWrapper = styled(Flex)`
  width: 64px;
  height: 64px;
  background: white;
  border-radius: 6px;
`;
