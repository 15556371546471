import { useAuth0 } from '@auth0/auth0-react';
import { Form, Typography } from 'antd';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';

import {
  createCustomer,
  createCustomerByBillingUser,
  createOrUpdateCompanyPermissions,
  useGetMyAccounts,
} from 'api/requests';
import {
  AccountOutDto,
  Permission,
  SetPermissionInDto,
  SubscriptionStatus,
} from 'api/requests/generated/generated.schemas';
import { StepModal } from 'components/Modal';
import Permissions from 'components/Permissions';
import PremiumFeatureBar from 'components/PremiumFeatureBar';
import { TrackingEvents, TrackingVariables, SCENARIO } from 'interfaces/enums';
import { TRACK_COOKIES } from 'lib/consts';
import { getBaseSubscriptionInfo, BaseSubscription } from 'lib/contexts/Entitlement';
import { getPlanName } from 'lib/helpers';
import { trackEventAll } from 'lib/helpers/trackEventAll';
import { useMessage, useEntitlement } from 'lib/hooks';

import CompanyDetails, { ICompanyDetailsFields } from '../Details/Modals/CompanyDetails';

const { Title, Paragraph } = Typography;
const { useForm, useWatch } = Form;

interface IAddCompanyProps {
  open: boolean;
  hasAccounts?: boolean;
  isCSA?: boolean;
  onClose: () => void;
  mutate: () => void;
}

const checkDisabledAndGetTooltip = (account: AccountOutDto) => {
  if (account.permission === Permission.READ) return 'You have read permission for this billing account';
  if (account.subscriptions.every((subscription) => subscription.status === SubscriptionStatus.cancelled)) {
    return "The account doesn't have an active subscription";
  }
  return null;
};

const AddCompany = ({ open, onClose, mutate, isCSA }: IAddCompanyProps) => {
  const { user: currentUser } = useAuth0();
  const { clearSubscription, storeSubscription } = useEntitlement();
  const [companyDetailsForm] = useForm<ICompanyDetailsFields>();
  const companyValues = useWatch([], { form: companyDetailsForm });
  const [isDisabled, setIsDisabled] = useState(true);
  const [showPremiumFeature, setShowPremiumFeature] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState<BaseSubscription | null>(null);
  const message = useMessage();

  const { data: accounts } = useGetMyAccounts();

  const initialPermission = {
    login: currentUser?.email || '',
    permission: Permission.ADMIN,
    inherited: false,
    id: '',
    parentId: '',
    notify: false,
  };

  const [permissions, setPermissions] = useState<SetPermissionInDto[]>(() => [initialPermission]);

  const filteredAccounts =
    !isCSA && accounts?.body
      ? accounts.body.map((account) => ({
          ...account,
          disabled: checkDisabledAndGetTooltip(account),
        }))
      : [];

  const disableCreationBasedOnAccount = (accountId?: string) => {
    if (accountId) {
      const account = filteredAccounts.find((acc) => acc.id === accountId);
      if (!account) return;
      const sub = getBaseSubscriptionInfo(account);
      if (sub && sub.inTrial && account.companies.length >= 1) {
        storeSubscription(account.id);
        setSelectedSubscription(sub);
        setIsDisabled(true);
        setShowPremiumFeature(true);
      } else {
        setSelectedSubscription(null);
        setShowPremiumFeature(false);
      }
    }
  };

  useEffect(() => {
    companyDetailsForm
      .validateFields({ validateOnly: true })
      .then(
        () => {
          setIsDisabled(false);
        },
        () => {
          setIsDisabled(true);
        },
      )
      .then(() => {
        disableCreationBasedOnAccount(companyValues?.account);
      });
  }, [companyValues]);

  if (!accounts?.body) {
    return null;
  }

  const onRemovePermission = (login: string) => {
    setPermissions(permissions.filter((p) => p.login !== login));
  };

  const onUpdatePermission = (login: string, newPermission: Permission) => {
    setPermissions(permissions.map((p) => (p.login === login ? { ...p, permission: newPermission } : p)));
  };

  const onAddPermission = (data: SetPermissionInDto) => {
    setPermissions([...permissions.filter((p) => p.login !== data.login), data]);
  };

  const addCompany = async () => {
    try {
      const company = companyDetailsForm.getFieldsValue(true);

      if (company) {
        const { account, ...details } = company;
        const {
          body: { id },
        } = company?.account
          ? await createCustomerByBillingUser(account, { ...details })
          : await createCustomer({ customer: company });
        await Promise.all(
          permissions.map((p) =>
            createOrUpdateCompanyPermissions(id, {
              permission: p,
            }),
          ),
        );

        trackEventAll(TrackingEvents.COMPANY_CREATED, {
          [TrackingVariables.COMPANY_NAME]: company.name,
          [TrackingVariables.COMPANY_BILLING_ACCOUNT]: company.account,
          [TrackingVariables.COMPANY_COUNTRY]: company.country,
          [TrackingVariables.COMPANY_CITY]: company.city,
        });

        Cookies.set(TRACK_COOKIES.TRACK_COMPANY_CREATED, company.account, {
          expires: new Date(Date.now() + 3600000),
        });
        message.success('Company was created successfully');
        mutate();
      }
    } catch (error) {
      if (error?.message) {
        message.error(error.response?.data.error?.msg);
      }
    }
  };

  const onModalClose = () => {
    trackEventAll(TrackingEvents.ADD_COMPANY_MODAL_CLOSE);
    companyDetailsForm.resetFields();
    onClose();
    clearSubscription();
  };

  return (
    <StepModal
      open={open}
      title="Create Company"
      okText="Create Company"
      destroyOnClose
      width={740}
      bodyHeight={100}
      maskClosable={false}
      onClose={onModalClose}
      steps={[
        {
          content: (
            <>
              {selectedSubscription && showPremiumFeature && (
                <PremiumFeatureBar
                  title="Maximum number of companies reached"
                  description="You can only add 1 company during your trial. Select a plan for full access."
                  buttonText="full-access"
                  subscriptionId={selectedSubscription?.id}
                  style={{ marginBottom: '24px' }}
                  trackEvent={() => {
                    trackEventAll(TrackingEvents.UPGRADE_INITIATED, {
                      [TrackingVariables.PLAN_NAME]: getPlanName(selectedSubscription.plan, selectedSubscription.type),
                      [TrackingVariables.SCENARIO]: SCENARIO.TRIAL_UPGRADE,
                      [TrackingVariables.REFERRER_SECTION]: 'Create Company',
                      [TrackingVariables.REFERRER_URL]: window.location.origin + window.location.pathname,
                    });
                  }}
                />
              )}
              <Title level={3}>Company Details</Title>
              <Paragraph>Which company do you want to add?</Paragraph>
              <CompanyDetails
                form={companyDetailsForm}
                accounts={filteredAccounts}
                readonly={Boolean(selectedSubscription && showPremiumFeature)}
              />
            </>
          ),
          handleNext: () => {
            trackEventAll(TrackingEvents.ADD_COMPANY_MODAL_CLICK_NEXT);
          },
          primaryButton: {
            disabled: isDisabled,
          },
        },
        {
          content: (
            <Permissions
              permissions={permissions}
              onAdd={onAddPermission}
              onRemove={onRemovePermission}
              onUpdate={onUpdatePermission}
            />
          ),
          handleNext: addCompany,
        },
      ]}
    />
  );
};

export default AddCompany;
