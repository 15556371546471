import { Flex, Layout } from 'antd';
import styled from 'styled-components';

export const Footer = styled(Layout.Footer)`
  height: 96px;
  padding: 24px 32px;
  display: flex;
  gap: 16px;
  justify-content: end;
  margin-top: 30px;
  margin-bottom: 40px;
  border-radius: 8px;
  background: ${(props) => props.theme.colors.white};
  box-shadow: 0px 2px 8px 0px ${(props) => props.theme.colors.black}1a;
  position: relative;

  &::after {
    content: '';
    height: 100px;
    width: 100%;
    position: absolute;
    bottom: 100%;
    right: 16px;
    background: linear-gradient(182deg, rgba(255, 255, 255, 0) 1.59%, #fff 36.2%);
    pointer-events: none;
  }
`;

export const ButtonsWrapper = styled(Flex)`
  align-self: center;
  gap: 16px;
`;
