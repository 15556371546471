import { LegalFramework } from 'api/requests/generated/generated.schemas';

const gdprDescription =
  "This site uses third-party website tracking technologies to provide and continually improve our services, and to display advertisements according to users' interests. I agree and may revoke or change my consent at any time with effect for the future.";
const usaDescription =
  'We and our partners are using tracking technologies to process personal data in order to improve your experience. You may always exercise your consumer right to opt-out. For detailed information about personal information we collect and third parties having access to it, please select ‘More Information’ or refer to our privacy policy.';

const tcfDescription =
  "We and our 1 third-party vendors use technologies (e.g. cookies) to store and/or access information on user's devices in order to process personal data such as IP addresses or browsing data. You may consent to the processing of your personal data for the listed purposes below. Alternatively you can set your preferences before consenting or refuse to consent. Please note that some vendors may process your personal data based on their legitimate business interest and do not ask for your consent. To exercise your right to object to processing based on legitimate interest please view our vendorlist. You can change your privacy settings or withdraw your consent at any time by clicking on our Privacy Button.";

const gdprTitle = 'Privacy Settings';
const defaultTitle = 'Privacy Information';

const gdprLinks = [{ title: 'More Information', clickable: true }];
const tcfLinks = [{ title: 'Vendor list', clickable: true }, { title: 'Manage Settings' }];
const usaLinks = [{ title: 'More Information', clickable: true }];

const gdprButtons = ['Deny All', 'Accept All'];
const tcfButtons = ['Save Settings', 'Accept All'];
const usaButtons = ['OK'];

export const getInfoBySelectedFramework = (framework: LegalFramework) => {
  if (framework === LegalFramework.GDPR) {
    return { description: gdprDescription, title: gdprTitle, links: gdprLinks, buttons: gdprButtons };
  }

  if (framework === LegalFramework.TCF2) {
    return { description: tcfDescription, title: defaultTitle, links: tcfLinks, buttons: tcfButtons };
  }

  return { description: usaDescription, title: defaultTitle, links: usaLinks, buttons: usaButtons };
};
