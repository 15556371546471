import { AuthorizationParams } from '@auth0/auth0-react';
import Cookies from 'js-cookie';
import { compressToEncodedURIComponent } from 'lz-string';

import { channelPartnerControllerGetPartnerName } from 'api/requests/generated/channel-partner/channel-partner';
import { SubscriptionType } from 'api/requests/generated/generated.schemas';
import { TrackingEvents, TrackingVariables } from 'interfaces/enums';

import { getConnection } from './auth0';
import { trackAmplitudeEvent } from './trackAmplitudeEvent';
import {
  DMA_CONNECTIONS,
  TRIAL_COOKIES,
  ONBOARDING_TYPE,
  USER_AUTH0_PARAMETERS,
  QUERY_PARAMS,
  REFERRAL,
  GOOGLE_UTM_PARAMS,
} from '../consts';

export const getAuthorizationParams = async (): Promise<AuthorizationParams> => {
  const params: URLSearchParams = new URL(window.location.href).searchParams;
  let trial = params.get('trial');
  const onePricingPlan = params.get(QUERY_PARAMS.one_pricing_plan);

  if (onePricingPlan && !trial) {
    trial = ONBOARDING_TYPE.STANDARD;
  }
  // dma_signup remains as a fallback in case someone access an old link from email/documentation etc...
  const isDmaTrialSignup = trial === ONBOARDING_TYPE.DMA || params.get('dma_signup') === 'true';
  const isStandardTrialSignup = trial === ONBOARDING_TYPE.STANDARD;
  // -----------------------------------------------------------------------------------------------------------------

  //? ================= Channel Partner Flow ONLY! ====================
  const partnerReferralCode = params.get(USER_AUTH0_PARAMETERS.partner_ref) || '';
  const isValidReferralCode = Boolean(partnerReferralCode?.trim()) && partnerReferralCode?.trim()?.length <= 5;
  let partnerName!: string | null;

  //? Search for the name in Permission API
  //? if there's any value coming in the URL partner_ref (Avoiding: e.g: " ", "", null, etc)
  if (isValidReferralCode) {
    try {
      partnerName = (await channelPartnerControllerGetPartnerName({ partnerRef: partnerReferralCode })).body
        .companyName;
    } catch (error) {
      //? FIXME: Log this in a proper logger place
      console.error(error);
    }
  }
  //? =================================================================

  if (isDmaTrialSignup) {
    Cookies.set(TRIAL_COOKIES.TRIAL_USED, ONBOARDING_TYPE.DMA);
  } else if (isStandardTrialSignup) {
    Cookies.set(TRIAL_COOKIES.TRIAL_USED, ONBOARDING_TYPE.STANDARD);
  }

  const authorisationParams: Partial<Record<USER_AUTH0_PARAMETERS, string | null>> = {
    ...(isDmaTrialSignup
      ? { [USER_AUTH0_PARAMETERS.uc_onboarding]: ONBOARDING_TYPE.DMA }
      : isStandardTrialSignup
      ? { [USER_AUTH0_PARAMETERS.uc_onboarding]: ONBOARDING_TYPE.STANDARD }
      : {}),
  };

  Object.values(USER_AUTH0_PARAMETERS).forEach((parameterName) => {
    const urlParam = params.get(parameterName);

    // Coupons should only be applicable to DMA segment - Added condition to block usage as a failsafe
    if (parameterName === USER_AUTH0_PARAMETERS.uc_coupon && !isDmaTrialSignup) {
      return;
    }
    if (urlParam) {
      Cookies.set(parameterName, urlParam);
      authorisationParams[parameterName] = urlParam;
    } else if (
      parameterName === USER_AUTH0_PARAMETERS.uc_lead_source &&
      GOOGLE_UTM_PARAMS.some((param) => params.has(param))
    ) {
      const utmParams = {
        '1': {
          ad_id: '',
          adset_id: '',
          campaign_id: '',
          gclid: params.get('gclid') ?? '',
          geoloc: '',
          matchtype: '',
          msclkid: params.get('msclkid') ?? '',
          placement: '',
          utm_campaign: params.get('utm_campaign') ?? '',
          utm_content: params.get('utm_content') ?? '',
          utm_device: params.get('utm_device') ?? '',
          utm_medium: params.get('utm_medium') ?? '',
          utm_source: params.get('utm_source') ?? '',
          utm_term: params.get('utm_term') ?? '',
        },
      };
      const utmParamsEncoded = compressToEncodedURIComponent(JSON.stringify(utmParams));
      Cookies.set(parameterName, utmParamsEncoded);
      authorisationParams[parameterName] = utmParamsEncoded;
    }

    if (parameterName === USER_AUTH0_PARAMETERS.uc_subscription_type && !urlParam && isDmaTrialSignup) {
      Cookies.set(parameterName, 'web');
      authorisationParams[parameterName] = 'web';
    }
  });

  if (onePricingPlan) {
    authorisationParams[USER_AUTH0_PARAMETERS.one_pricing_plan] = params.get(QUERY_PARAMS.one_pricing_plan);
    authorisationParams[USER_AUTH0_PARAMETERS.uc_subscription_type] = SubscriptionType.web;
  }

  if (document.referrer && document.referrer.includes(REFERRAL.self_serve)) {
    authorisationParams[USER_AUTH0_PARAMETERS.uc_origin] = 'self_serve';
  }

  const irpid = params.get('irpid');
  if (irpid) {
    authorisationParams[USER_AUTH0_PARAMETERS.uc_irpid] = irpid;
  }

  //? ================= Channel Partner Flow ONLY! ====================
  //? NOTE: Send it ONLY if the "partner_ref" is Valid according to our requirements
  //! This goes to the User Metadata in Auth0
  if (isValidReferralCode) {
    authorisationParams[USER_AUTH0_PARAMETERS.partner_ref] = partnerReferralCode;
  }
  //? =================================================================

  /**
   * Tracking events
   */

  if (getConnection(window.location.search) !== DMA_CONNECTIONS.GOOGLE) {
    if (isDmaTrialSignup && authorisationParams[USER_AUTH0_PARAMETERS.uc_coupon]) {
      trackAmplitudeEvent(TrackingEvents.CTA_FREE_TRIAL, {
        [TrackingVariables.TRIAL_TYPE]: ONBOARDING_TYPE.DMA,
      });
    } else if (!isDmaTrialSignup && isStandardTrialSignup) {
      trackAmplitudeEvent(TrackingEvents.CTA_FREE_TRIAL, {
        [TrackingVariables.TRIAL_TYPE]: ONBOARDING_TYPE.STANDARD,
      });
    }
  }
  /**
   * =========
   */

  if (window.location.pathname.includes('login') && !isDmaTrialSignup && !isStandardTrialSignup) {
    Cookies.remove(TRIAL_COOKIES.TRIAL_USED);
  }

  return {
    ...(isDmaTrialSignup || isStandardTrialSignup ? authorisationParams : {}),
    audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`,
    redirect_uri: `${window.location.origin}/`,
    ['ext-onePricing']: Boolean(onePricingPlan),
    ['ext-isDma']: true, // always set to true to show new templates to all users
    //? INFO: when url contains trial property auth0 should redirect to signup screen instead
    ...(isDmaTrialSignup || isStandardTrialSignup
      ? {
          screen_hint: 'signup',
          //? NOTE: This value can be empty in the worst case scenario, however, if that's the case the Partner Name is not shown in the Auth0 template
          ['ext-partnerName']: partnerName,
        }
      : {}),
  };
};
