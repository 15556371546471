import { Radio } from 'antd';
import styled from 'styled-components';

export const StyledRadio = styled(Radio)`
  display: flex;
  flex-flow: row-reverse;
  border-radius: 4px;
  width: 100%;
  background: ${(props) => props.theme.colors.gray[8]};
  padding: 12px 16px;

  &:has(> .ant-radio-checked) {
    border: 2px solid ${({ theme }) => theme.colors.primary};
    background: ${(props) => props.theme.colors.blue[4]};
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.blue[4]};
  }

  & > span:not(:first-child) {
    display: flex;
    align-items: center;
    flex-basis: 100%;
    gap: 12px;
    padding-inline-start: 0;
  }
`;

export const BannerWrapper = styled.div<{ position: 'center' | 'bottom' | 'left' | 'right' }>`
  display: flex;
  justify-content: center;
  width: 45px;
  height: 35px;
  border-radius: 3px;
  background: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.gray[24]};
  ${(props) => {
    switch (props.position) {
      case 'center':
        return 'align-items: center';
      case 'bottom':
        return 'align-items: end';
      case 'left':
        return 'justify-content: left';
      case 'right':
        return 'justify-content: right';
      default:
        return 'align-items: center';
    }
  }}
`;

export const Banner = styled.div<{ width: string; height: string }>`
  background: ${(props) => props.theme.colors.primary};
  margin: 2px;
  border-radius: 1px;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
`;
