import { useAuth0 } from '@auth0/auth0-react';
import { Flex, Form, FormInstance, Space } from 'antd';
import Cookies from 'js-cookie';
import { decompressFromEncodedURIComponent } from 'lz-string';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { createSetting, getMyAccounts } from 'api/requests';
import {
  AppDtoPlatform,
  CreateSettingBodyButtonPrivacyVariant,
  CreateSettingBodyFirstLayerVariant,
  LegalFramework,
  SubscriptionType,
} from 'api/requests/generated/generated.schemas';
import { createCustomerAndSubscription } from 'api/requests/onboarding';
import { isSurveyAnswered, postSurveyAnswers } from 'api/requests/survey';
import DotStepper from 'components/DotStepper';
import { Layout } from 'components/Layout/styled';
import Tag from 'components/Tag';
import { Title } from 'components/Typography';
import withMobileView from 'hoc/withMobileView';
import { TrackingEvents, TrackingVariables } from 'interfaces/enums';
import { ISurveyRequestAnswer } from 'interfaces/responses';
import { OnboardingSteps } from 'interfaces/steps';
import { ONBOARDING_TYPE, SURVEY_ID, USER_AUTH0_PARAMETERS } from 'lib/consts';
import { getPlanName } from 'lib/helpers';
import { trackOnboardingSurveySubmitEvent } from 'lib/helpers/analytics';
import { clearTrialCookies } from 'lib/helpers/clearTrialCookies';
import { CURRENCY_SELECTION, getFeatureFlags } from 'lib/helpers/featureFlags';
import { trackAmplitudeEvent } from 'lib/helpers/trackAmplitudeEvent';
import { trackEventAll } from 'lib/helpers/trackEventAll';
import { useMessage } from 'lib/hooks';
import useOnboarding from 'lib/hooks/useOnboarding';
import { colors } from 'lib/theme/colors';

import { UserDropdown, Sidebar, Footer } from './Layout';
import { ErrorState, LoadingState } from './Status';
import CustomizationStep from './Steps/Customization';
import CustomizationModal from './Steps/CustomizationModal';
import Details from './Steps/Details';
import DetailsPlatform from './Steps/DetailsPlatform';
import Implementation from './Steps/Implementation';
import Invites from './Steps/Invites';
import Survey from './Steps/Survey';
import { Container } from './styled';
import { ITrialDetailsForm, ITrialCustomisationForm, Breadcrumbs } from './types';

const { Content } = Layout;

export type BLOCKING_TYPE = 'auto' | 'manual';

const Onboarding = () => {
  const featureFlags = getFeatureFlags();
  const { user } = useAuth0();
  const { selectedFramework, customizationDetails } = useOnboarding();
  const [currentStep, setCurrentStep] = useState<OnboardingSteps>(OnboardingSteps.Welcome);
  const [disabled, setDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isCreateConsentModalTracked, setCreateConsentModalTracked] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [customerData, setCustomerData] = useState<{ companyId: string; settingId: string } | null>(null);
  const [isError, setIsError] = useState(false);
  const message = useMessage();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [blockingType, setBlockingType] = useState<BLOCKING_TYPE>('auto');
  const [detailsForm] = Form.useForm<ITrialDetailsForm>();
  const [customisationForm] = Form.useForm<ITrialCustomisationForm>();
  const [surveyForm] = Form.useForm<FormInstance<{ [key: string]: string }>>();
  const [subscriptionType, setSubscriptionType] = useState<SubscriptionType>(SubscriptionType.web);

  const isDevEnvironment = process.env.REACT_APP_ENV === 'sandbox';
  const isSubscriptionTypeApp = subscriptionType === SubscriptionType.app;

  const couponId =
    (user?.[USER_AUTH0_PARAMETERS.uc_onboarding] !== ONBOARDING_TYPE.STANDARD && user?.uc_coupon) || undefined;

  useEffect(() => {
    if (user?.one_pricing_plan) {
      setSubscriptionType(SubscriptionType.web);
    } else if (user?.[USER_AUTH0_PARAMETERS.uc_subscription_type]) {
      setSubscriptionType(user[USER_AUTH0_PARAMETERS.uc_subscription_type]);
    } else {
      setSubscriptionType(SubscriptionType.web);
    }
  }, [user]);

  useEffect(() => {
    if (!state?.canBeCreated && !state?.hasAnySettings) {
      setCurrentStep(OnboardingSteps.Implementation);
      return;
    }

    trackAmplitudeEvent(TrackingEvents.ONBOARDING_STARTED, {
      [TrackingVariables.UC_COUPON_ID]: couponId,
      [TrackingVariables.PLATFORM]: subscriptionType,
      [TrackingVariables.TRIAL_TYPE]: user?.[USER_AUTH0_PARAMETERS.uc_onboarding] || ONBOARDING_TYPE.STANDARD,
      [TrackingVariables.PLAN_NAME]: getPlanName(user?.[USER_AUTH0_PARAMETERS.one_pricing_plan], subscriptionType),
      [TrackingVariables.LEAD_SOURCE]: decompressFromEncodedURIComponent(
        Cookies.get(USER_AUTH0_PARAMETERS.uc_lead_source) || '',
      ),
    });

    isSurveyAnswered(SURVEY_ID).then(({ isAnswered }) => {
      if (isAnswered) {
        setCurrentStep(OnboardingSteps.AccountDetails);
      }
    });
  }, []);

  useEffect(() => {
    if (OnboardingSteps.Welcome === currentStep && !isCreateConsentModalTracked) {
      setCreateConsentModalTracked(true);
      trackEventAll(TrackingEvents.CREATE_CONSENT_MODAL, {
        [TrackingVariables.FORM_STEP]: '1',
        [TrackingVariables.FORM_STEP_NAME]: 'Welcome',
      });
    }
    if ([OnboardingSteps.Implementation, OnboardingSteps.Welcome, OnboardingSteps.Invites].includes(currentStep)) {
      setDisabled(false);
      return;
    }
  }, [currentStep]);

  const handleNext = () => {
    const accountDetailsValues: ITrialDetailsForm = detailsForm.getFieldsValue(true);
    trackEventAll(TrackingEvents.CREATE_CONSENT_CLICK_NEXT, {
      [TrackingVariables.FORM_STEP]: '1',
      [TrackingVariables.FORM_STEP_NAME]: 'Welcome',
      [TrackingVariables.SELECT_TRIAL]: 'Web CMP',
      [TrackingVariables.DOMAINS]: accountDetailsValues.domain || '',
      [TrackingVariables.LEGAL_FRAMEWORK]: accountDetailsValues.framework as LegalFramework,
    });
    if (steps.length - 1 === currentStep) {
      trackEventAll(TrackingEvents.CREATE_CONSENT_SUCCESSFUL, {
        [TrackingVariables.FORM_STEP]: '4',
        [TrackingVariables.FORM_STEP_NAME]: 'Successful',
        [TrackingVariables.SELECT_TRIAL]: 'Web CMP',
        [TrackingVariables.DOMAINS]: accountDetailsValues.domain || '',
        [TrackingVariables.LEGAL_FRAMEWORK]: accountDetailsValues.framework as LegalFramework,
        [TrackingVariables.LAYOUT]: 'Privacy Wall',
        [TrackingVariables.PRIVACY_BUTTON]: customisationForm.getFieldValue('buttonPrivacyVariant'),
      });
      if (customerData?.companyId) {
        navigate(`/company/${customerData?.companyId}/configurations`);
      } else {
        navigate('/companies');
      }
    } else {
      switch (currentStep) {
        case 1:
          trackEventAll(TrackingEvents.CREATE_CONSENT_CUSTOMIZATION, {
            [TrackingVariables.FORM_STEP]: '2',
            [TrackingVariables.FORM_STEP_NAME]: 'Consent Customization',
          });
          break;
        case 2:
          trackEventAll(TrackingEvents.CREATE_CONSENT_CLICK_SAVE_AND_NEXT, {
            [TrackingVariables.FORM_STEP]: '2',
            [TrackingVariables.FORM_STEP_NAME]: 'Consent Customization',
            [TrackingVariables.SELECT_TRIAL]: 'Web CMP',
            [TrackingVariables.DOMAINS]: accountDetailsValues.domain || '',
            [TrackingVariables.LEGAL_FRAMEWORK]: accountDetailsValues.framework as LegalFramework,
            [TrackingVariables.LAYOUT]: 'Privacy Wall',
            [TrackingVariables.PRIVACY_BUTTON]: customisationForm.getFieldValue('buttonPrivacyVariant'),
          });
          break;
        case 3:
          trackEventAll(TrackingEvents.CREATE_CONSENT_IMPLEMENTATION, {
            [TrackingVariables.FORM_STEP]: '3',
            [TrackingVariables.FORM_STEP_NAME]: 'Implementation',
          });
          break;
      }
      setCurrentStep((prev) => prev + 1);
    }
  };

  const handleBack = () => {
    trackEventAll(TrackingEvents.CREATE_CONSENT_CLICK_BACK);

    setCurrentStep((prev) => prev - 1);
  };

  const prepareAccount = async () => {
    try {
      const accountDetailsValues: ITrialDetailsForm = detailsForm.getFieldsValue(true);

      const { companyId, customerId } = await createCustomerAndSubscription({
        domain: accountDetailsValues.domain || '',
        companyName: accountDetailsValues.companyName,
        firstName: user?.given_name || user?.name || user?.nickname || '',
        lastName: user?.family_name || user?.middle_name || '',
        onboardingType: user?.[USER_AUTH0_PARAMETERS.uc_onboarding] || ONBOARDING_TYPE.STANDARD,
        type: subscriptionType,
        couponId,
        leadSource: Cookies.get(USER_AUTH0_PARAMETERS.uc_lead_source) || undefined,
        ...(user?.[USER_AUTH0_PARAMETERS.one_pricing_plan]
          ? { pricePlan: user?.[USER_AUTH0_PARAMETERS.one_pricing_plan] }
          : {}),
        partnerRef: Cookies.get(USER_AUTH0_PARAMETERS.partner_ref) || undefined,
        irpId: Number(user?.[USER_AUTH0_PARAMETERS.uc_irpid]) || undefined,
        sourceWebsite: user?.[USER_AUTH0_PARAMETERS.source_website] || undefined,
        ...(featureFlags.includes(CURRENCY_SELECTION) || !user?.one_pricing_plan
          ? { country: accountDetailsValues.country }
          : {}),
      });

      /**
       * Tracking events
       */

      trackEventAll(TrackingEvents.ONBOARDING_TRIAL_STARTED, {
        [TrackingVariables.TRIAL_TYPE]: user?.[USER_AUTH0_PARAMETERS.uc_onboarding] || ONBOARDING_TYPE.STANDARD,
        [TrackingVariables.PLATFORM]: subscriptionType,
        [TrackingVariables.LEAD_SOURCE]: decompressFromEncodedURIComponent(
          Cookies.get(USER_AUTH0_PARAMETERS.uc_lead_source) || '',
        ),
        [TrackingVariables.COMPANY_ID]: companyId,
        [TrackingVariables.PLAN_NAME]: getPlanName(user?.[USER_AUTH0_PARAMETERS.one_pricing_plan], subscriptionType),
      });
      /**
       * =====
       */

      return { companyId, customerId };
    } catch (error) {
      let messageText = error;
      if (error.statusCode === 400) {
        messageText = error.response?.data.error?.msg;
        message.error(messageText);
      } else if (typeof error === 'string') {
        message.error(error);
      }

      trackEventAll(TrackingEvents.ONBOARDING_TRIAL_START_FAILED, {
        [TrackingVariables.ERROR]: messageText,
        [TrackingVariables.PLAN_NAME]: getPlanName(user?.[USER_AUTH0_PARAMETERS.one_pricing_plan], subscriptionType),
      });

      setCurrentStep(OnboardingSteps.AccountDetails);

      return { companyId: '', customerId: '' };
    }
  };

  const prepareSetting = async (companyId: string) => {
    try {
      const accountDetailsValues: ITrialDetailsForm = detailsForm.getFieldsValue(true);

      setIsLoading(true);

      return createSetting({
        companyId,
        ...(accountDetailsValues.bundleId
          ? {
              app: {
                bundleId: accountDetailsValues.bundleId,
                platform: accountDetailsValues.platform as AppDtoPlatform,
              },
            }
          : {}),
        domains: accountDetailsValues.domain ? [accountDetailsValues.domain] : [],
        dataController: '',
        legalFramework: accountDetailsValues.framework as LegalFramework,
        name: `${accountDetailsValues.framework} configuration #1`,
        type: subscriptionType,
        layout: {
          firstLayerVariant: customisationForm.getFieldValue('firstLayerVariant'),
        },
        privacyTrigger: {
          buttonPrivacyVariant: customisationForm.getFieldValue('buttonPrivacyVariant'),
        },
        styling: {
          buttonTextColor: customisationForm.getFieldValue('buttonTextColor'),
          buttonBackgroundColor: customisationForm.getFieldValue('buttonBackgroundColor'),
        },
      });
    } catch (error) {
      throw error;
    }
  };

  const prepareSettingV2 = async (companyId: string) => {
    try {
      const domain = detailsForm.getFieldValue('domain');

      setIsLoading(true);

      return createSetting({
        companyId,
        domains: [domain || ''],
        dataController: '',
        legalFramework: selectedFramework,
        name: `${selectedFramework} configuration #1`,
        type: subscriptionType,
        layout: customizationDetails.layout,
        styling: customizationDetails.styling,
        privacyTrigger: customizationDetails.privacyTrigger,
      });
    } catch (error) {
      throw error;
    }
  };

  const submitSurvey = async (surveyId: string, ucBillingAccount?: string) => {
    const answers = Object.entries(surveyForm.getFieldsValue(true)).map(([questionId, value]) => ({
      questionId,
      value,
    })) as ISurveyRequestAnswer[];

    try {
      setIsButtonLoading(true);

      await postSurveyAnswers(surveyId, { answers, references: { ucBillingAccount } });
      trackOnboardingSurveySubmitEvent(answers);
    } catch (err) {
      setIsError(true);
      console.error(err);
    } finally {
      setIsButtonLoading(false);
    }
  };

  const onCreateAccount = async (): Promise<void> => {
    /**
     * Track events
     */

    trackEventAll(TrackingEvents.ONBOARDING_SETUP_COMPLETED, {
      [TrackingVariables.TRIAL_TYPE]: user?.[USER_AUTH0_PARAMETERS.uc_onboarding] || ONBOARDING_TYPE.STANDARD,
      [TrackingVariables.PLATFORM]: subscriptionType,
      [TrackingVariables.LEAD_SOURCE]: decompressFromEncodedURIComponent(
        Cookies.get(USER_AUTH0_PARAMETERS.uc_lead_source) || '',
      ),
      [TrackingVariables.PLAN_NAME]: getPlanName(user?.[USER_AUTH0_PARAMETERS.one_pricing_plan], subscriptionType),
    });

    /**
     * ====
     */
    try {
      let companyId;
      let customerId: string;

      setIsLoading(true);

      if (state?.canBeCreated) {
        const result = await prepareAccount();
        companyId = result.companyId;
        customerId = result.customerId;

        if (!companyId || !customerId) {
          throw new Error("The account hasn't been prepared");
        }
      } else {
        const { body: accounts } = await getMyAccounts();
        companyId = accounts[0]?.companies[0].id;
        customerId = accounts[0]?.chargebeeCustomerId;
      }

      const { settingId } = isDevEnvironment
        ? (await prepareSettingV2(companyId)).body
        : (await prepareSetting(companyId)).body;

      if (!settingId) {
        throw new Error("The configuration hasn't been prepared. Please try again");
      }

      trackAmplitudeEvent(TrackingEvents.ONBOARDING_CUSTOMIZATION_COMPLETED, {
        [TrackingVariables.FIRST_LAYER_BANNER_LAYOUT_CUSTOM]:
          customisationForm.getFieldValue('firstLayerVariant') !== CreateSettingBodyFirstLayerVariant.WALL,
        [TrackingVariables.BUTTON_COLOR_CUSTOM]:
          customisationForm.getFieldValue('buttonBackgroundColor') !== colors.primary ||
          customisationForm.getFieldValue('buttonTextColor') !== colors.white,
        [TrackingVariables.PRIVACY_BUTTON_LAYOUT_CUSTOM]:
          customisationForm.getFieldValue('buttonPrivacyVariant') !== CreateSettingBodyButtonPrivacyVariant.fingerprint,
        [TrackingVariables.PLAN_NAME]: getPlanName(user?.[USER_AUTH0_PARAMETERS.one_pricing_plan], subscriptionType),
      });

      setCustomerData({ settingId, companyId });
      clearTrialCookies();
      handleNext();
    } catch (error) {
      message.error("Couldn't prepare account or configuration. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const stepsApp = useMemo(
    () => [
      {
        title: 'Tailor Your Experience',
        breadcrumb: Breadcrumbs.WELCOME,
        primaryButton: 'Next',
        sidebar: {
          title: 'Welcome to Usercentrics',
          description:
            'Personalize your account. Provide the information and complete your account to help us understand your context and needs better.',
        },
        optional: true,
        content: <Survey surveyId={SURVEY_ID} form={surveyForm} />,
        onClick: async () => {
          await submitSurvey(SURVEY_ID);

          handleNext();
        },
      },
      {
        title: 'Create Consent For Your Business',
        breadcrumb: Breadcrumbs.CREATE_CONSENT,
        primaryButton: 'Next',
        sidebar: {
          title: 'Welcome to Usercentrics',
          description:
            'Get started by adding your first consent banner configuration. Choose the domain and select which framework you want to support.',
        },
        content: (
          <Space direction="vertical" size={24}>
            <DetailsPlatform value={subscriptionType} onChange={setSubscriptionType} />
            <Details form={detailsForm} setDisabled={setDisabled} subscriptionType={subscriptionType} />
          </Space>
        ),
        onClick: onCreateAccount,
      },
      ...(isDevEnvironment
        ? [
            {
              title: 'Invite people to collaborate',
              optional: true,
              breadcrumb: Breadcrumbs.INVITES,
              primaryButton: 'Next',
              leftSideFooter: {
                buttonText: "I'll do it later",
                onClick: handleNext,
                label: 'If you don’t want to send invite now you can do it later in the application',
              },
              sidebar: {
                title: 'Collaborate with your team',
                description: 'Invite people from your team to get the most out of Usercentrics CMP.',
              },
              content: <Invites companyId={customerData?.companyId} />,
              onClick: handleNext,
            },
          ]
        : []),
    ],
    [subscriptionType, customerData, selectedFramework, currentStep],
  );

  const stepsWeb = useMemo(
    () => [
      {
        title: 'Tailor Your Experience',
        breadcrumb: Breadcrumbs.WELCOME,
        primaryButton: 'Next',
        sidebar: {
          title: 'Welcome to Usercentrics',
          description:
            'Personalize your account. Provide the information and complete your account to help us understand your context and needs better.',
        },
        optional: true,
        content: <Survey surveyId={SURVEY_ID} form={surveyForm} />,
        onClick: async () => {
          await submitSurvey(SURVEY_ID);

          handleNext();
        },
      },
      {
        title: 'Create Consent For Your Business',
        breadcrumb: Breadcrumbs.CREATE_CONSENT,
        primaryButton: 'Next',
        sidebar: {
          title: 'Welcome to Usercentrics',
          description:
            'Get started by adding your first consent banner configuration. Choose the domain and select which framework you want to support.',
        },
        content: (
          <Space direction="vertical" size={24}>
            <DetailsPlatform value={subscriptionType} onChange={setSubscriptionType} />
            <Details form={detailsForm} setDisabled={setDisabled} subscriptionType={subscriptionType} />
          </Space>
        ),
        onClick: () => {
          const { domain, framework } = detailsForm.getFieldsValue();

          if (domain) {
            trackAmplitudeEvent(TrackingEvents.ONBOARDING_DOMAIN_ENTERED, {
              [TrackingVariables.PLAN_NAME]: getPlanName(
                user?.[USER_AUTH0_PARAMETERS.one_pricing_plan],
                subscriptionType,
              ),
            });
          }

          if (framework) {
            trackEventAll(TrackingEvents.ONBOARDING_DETAILS_FILLED, {
              [TrackingVariables.TRIAL_TYPE]: user?.[USER_AUTH0_PARAMETERS.uc_onboarding] || ONBOARDING_TYPE.STANDARD,
              [TrackingVariables.PLATFORM]: subscriptionType,
              [TrackingVariables.CONFIGURATION_FRAMEWORK]: framework,
              [TrackingVariables.LEAD_SOURCE]: decompressFromEncodedURIComponent(
                Cookies.get(USER_AUTH0_PARAMETERS.uc_lead_source) || '',
              ),
              [TrackingVariables.PLAN_NAME]: getPlanName(
                user?.[USER_AUTH0_PARAMETERS.one_pricing_plan],
                subscriptionType,
              ),
            });
          }

          handleNext();
        },
      },
      {
        title: 'Customize Consent Banner',
        breadcrumb: Breadcrumbs.CONSENT_CUSTOMIZATION,
        primaryButton: 'Save and Next',
        sidebar: {
          label: '',
          title: 'Customize your consent banner',
          description: 'Change the design of your consent banner and tailor it to your brand identity.',
        },
        onClick: !isDevEnvironment ? onCreateAccount : undefined,
        content: isDevEnvironment ? (
          <CustomizationModal
            breadcrumbs={Object.values(Breadcrumbs)}
            handleBack={handleBack}
            handleNext={onCreateAccount}
          />
        ) : (
          <CustomizationStep form={customisationForm} />
        ),
      },
      ...(isDevEnvironment
        ? [
            {
              title: 'Invite people to collaborate',
              optional: true,
              breadcrumb: Breadcrumbs.INVITES,
              primaryButton: 'Next',
              leftSideFooter: {
                buttonText: "I'll do it later",
                onClick: handleNext,
                label: 'If you don’t want to send invite now you can do it later in the application',
              },
              sidebar: {
                title: 'Collaborate with your team',
                description: 'Invite people from your team to get the most out of Usercentrics CMP.',
              },
              content: <Invites companyId={customerData?.companyId} />,
              onClick: handleNext,
            },
          ]
        : []),
      {
        title: 'Implement Configuration',
        breadcrumb: Breadcrumbs.IMPLEMENTATION,
        primaryButton: 'Finish',
        sidebar: {
          title: 'Time to start collecting user consents',
          description: 'Your CMP is ready to be implemented.',
        },
        content: (
          <Implementation
            settingId={customerData?.settingId || ''}
            isTcf={detailsForm?.getFieldValue('framework') === LegalFramework.TCF2}
            blockingType={blockingType}
            setBlockingType={setBlockingType}
          />
        ),
        onClick: () => {
          const accountDetailsValues: ITrialDetailsForm = detailsForm.getFieldsValue(true);
          trackAmplitudeEvent(TrackingEvents.ONBOARDING_IMPLEMENTATION_COMPLETED, {
            [TrackingVariables.IS_AUTO_BLOCKING_METHOD_SELECTED]: blockingType === 'auto',
            [TrackingVariables.PLAN_NAME]: getPlanName(
              user?.[USER_AUTH0_PARAMETERS.one_pricing_plan],
              subscriptionType,
            ),
          });
          trackEventAll(TrackingEvents.CREATE_CONSENT_CLICK_FINISH, {
            [TrackingVariables.FORM_STEP]: '3',
            [TrackingVariables.FORM_STEP_NAME]: 'Implementation',
            [TrackingVariables.SELECT_TRIAL]: 'Web CMP',
            [TrackingVariables.DOMAINS]: accountDetailsValues.domain || '',
            [TrackingVariables.LEGAL_FRAMEWORK]: accountDetailsValues.framework as LegalFramework,
            [TrackingVariables.LAYOUT]: 'Privacy Wall',
            [TrackingVariables.PRIVACY_BUTTON]: customisationForm.getFieldValue('buttonPrivacyVariant'),
          });
          handleNext();
        },
      },
    ],
    [subscriptionType, customerData, selectedFramework, customizationDetails, currentStep],
  );

  const steps = isSubscriptionTypeApp ? stepsApp : stepsWeb;

  const stepData = steps[currentStep];
  const breadcrumbs = steps.map((step) => step.breadcrumb);

  return (
    <Container justify="center">
      <Layout style={{ maxWidth: '1440px', padding: 0 }}>
        <Sidebar {...stepData?.sidebar} />
        <Layout style={{ padding: 0 }}>
          <Content style={{ padding: '48px 48px 0 48px' }}>
            {isError ? (
              <ErrorState />
            ) : isLoading ? (
              <LoadingState />
            ) : (
              <>
                <Flex vertical gap={16}>
                  <Flex justify="space-between" align="center" style={{ overflow: 'scroll' }}>
                    <DotStepper name="main-breadcrumbs" steps={breadcrumbs} activeStep={currentStep} />
                    <UserDropdown />
                  </Flex>
                  <Title weight={700} fontSize={28}>
                    <Flex align="center" gap={16}>
                      {stepData?.title}
                      {stepData?.optional && (
                        <Tag size="medium" color="blue-inverse" $bolder bordered>
                          Optional
                        </Tag>
                      )}
                    </Flex>
                  </Title>
                </Flex>
                <Content
                  className="scroll"
                  style={{ height: 'calc(100vh - 332px)', paddingTop: 18, overflowX: 'scroll' }}
                >
                  {stepData?.content}
                </Content>
                <Footer
                  leftSide={stepData.leftSideFooter}
                  buttons={[
                    {
                      isShown:
                        currentStep !== 0 &&
                        currentStep !== OnboardingSteps.Implementation &&
                        currentStep !== OnboardingSteps.Invites &&
                        state?.canBeCreated,
                      title: 'Back',
                      onClick: handleBack,
                      className: 'onboarding',
                      key: 'back',
                      disabled: currentStep === OnboardingSteps.AccountDetails,
                    },
                    {
                      isShown: state?.hasAnySettings && currentStep !== OnboardingSteps.Implementation,
                      title: 'Cancel new trial',
                      onClick: () => {
                        clearTrialCookies();
                        navigate('/');
                      },
                      className: 'onboarding',
                      key: 'cancel_trial',
                    },
                    {
                      isShown: true,
                      type: 'primary',
                      onClick: stepData?.onClick,
                      disabled,
                      title: stepData?.primaryButton,
                      className: 'onboarding',
                      key: 'next',
                      loading: isButtonLoading,
                    },
                  ]}
                />
              </>
            )}
          </Content>
        </Layout>
      </Layout>
    </Container>
  );
};

export default withMobileView(Onboarding);
