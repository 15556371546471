import TagManager from 'react-gtm-module';

import { TrackingEvents } from 'interfaces/enums';
import { ISurveyRequestAnswer } from 'interfaces/responses';

import { trackAmplitudeEvent } from './trackAmplitudeEvent';
import { updateAmplitudeUserProperty } from './updateAmplitudeUserProperty';

export const trackOnboardingSurveySubmitEvent = (answers: ISurveyRequestAnswer[]) => {
  TagManager.dataLayer({
    dataLayer: {
      event: TrackingEvents.ONBOARDING_SURVEY_SUBMITTED,
    },
  });
  trackAmplitudeEvent(TrackingEvents.ONBOARDING_SURVEY_SUBMITTED);

  answers.forEach((item) => {
    updateAmplitudeUserProperty(item.questionId, item.value);
  });
};
